<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <div class="left">
        <div class="btn" @click="addNewDialog">+{{ $t("NewDialogue") }}</div>
        <div class="tit" v-if="sessionList.length > 0">
          {{ $t("RecentDialogue") }}
        </div>
        <simplebar
          data-simplebar-auto-hide="true"
          class="simplebarWrapLeft"
          :scrollbarMaxSize="50"
          :style="{
            maxHeight: maxLeftHeight + 'px',
            width: '242px',
          }"
        >
          <ul>
            <li
              v-for="(item, index) in sessionList"
              @click="showDialog(item)"
              :key="index"
            >
              <svg-icon iconClass="qibao" class="icon"></svg-icon
              ><span>{{ item.session_name }}</span>
            </li>
          </ul>
        </simplebar>
      </div>
      <div class="right">
        <div class="wrap-mail" v-if="startTalk">
          <span class="sendMail" @click="sendEmailHandler">
            <svg-icon iconClass="mail" class="icon"></svg-icon>
            {{ $t("sendMail") }}</span
          >
        </div>
        <div
          class="dialogWind"
          :style="{
            maxHeight: maxWindowHeight + 'px',
            minHeight: maxWindowHeight + 'px',
          }"
        >
          <simplebar
            data-simplebar-auto-hide="true"
            class="simplebarWrap-dialog"
            ref="scrollContainer"
            :scrollbarMaxSize="50"
            :style="{
              maxHeight: maxWindowHeight + 'px',
              width: width,
            }"
          >
            <div>
              <template v-if="!startTalk">
                <div class="guide">
                  <div class="tit">{{ $t("Hello") }}, {{ user.user_name }}</div>
                  <div class="small">{{ $t("helpYou") }}</div>
                  <div class="tit2">{{ $t("askMeQs") }}</div>
                  <div
                    class="hrask"
                    @click="selectHandler(item, 1)"
                    v-for="(item, index) in assisData.question"
                    :key="index"
                  >
                    {{ item.prompt }}
                  </div>
                  <img src="@/assets/img/talk.png" />
                </div>
                <div class="guide2">
                  <div class="tit">
                    {{ $t("askMeTask") }}
                  </div>
                  <div class="boxList">
                    <a-row :gutter="20" class="item">
                      <template v-for="(item, index) in assisData.follow_task">
                        <a-col
                          :span="8"
                          :key="index"
                          v-show="index < 5 || showMore"
                        >
                          <div class="unit" @click="selectHandler(item, 2)">
                            <svg-icon
                              iconClass="question"
                              class="icon"
                            ></svg-icon>
                            <p>{{ item.desc }}</p>
                          </div>
                        </a-col>
                      </template>

                      <a-col
                        :span="8"
                        v-if="assisData.follow_task.length > 5 && !showMore"
                      >
                        <div class="unit" @click="showMore = true">
                          <svg-icon iconClass="more" class="icon"></svg-icon>
                          <p>{{ $t("moreClick") }}</p>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </template>
              <div v-else class="talkWind">
                <div class="wrap-message">
                  <ul>
                    <template> </template>
                    <li
                      v-for="(item, index) in dialogList"
                      :key="index"
                      :class="{
                        rightTalk: item.role_type == 1 ? true : false,
                        leftTalk: item.role_type == 0 ? true : false,
                      }"
                    >
                      <div v-if="item.type == 'date'" class="date">
                        {{ item.date }}
                      </div>
                      <template v-if="item.role_type == 1">
                        <div class="box">
                          <div class="wrap-info">
                            <div class="message">
                              <div
                                v-html="item.content"
                                class="messageText"
                              ></div>
                              <div class="time">
                                {{
                                  formatTimeZoneTime(item.create_date, "HH:mm")
                                }}
                              </div>
                            </div>
                          </div>
                          <img
                            @error="imgError($event)"
                            :src="user.head_img"
                            :alt="user.user_name"
                          />
                        </div>
                        <div style="clear: both"></div>
                      </template>
                      <template
                        v-if="item.role_type == 0 && item.parse_cate == 'z'"
                      >
                        <div class="box">
                          <img src="@/assets/img/robot.png" alt="" />
                          <div>
                            <div class="wrap-info">
                              <div class="message">
                                <div v-html="item.content"></div>
                                <div class="time">
                                  {{
                                    formatTimeZoneTime(
                                      item.create_date,
                                      "HH:mm"
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="question-ext"
                              v-for="(ditem, dindex) in item.questionList"
                              :key="dindex"
                              @click="questionHandler(ditem.question)"
                            >
                              {{ ditem.question }}
                              <a-icon type="right" class="icon" />
                            </div>
                          </div>
                        </div>
                        <div style="clear: both"></div>
                      </template>
                      <template
                        v-if="item.role_type == 0 && item.parse_cate != 'z'"
                      >
                        <div class="box">
                          <img src="@/assets/img/robot.png" alt="" />
                          <div class="wrap-info">
                            <div class="message">
                              <div>
                                <div
                                  class="answerContent"
                                  v-html="item.answer_content"
                                ></div>
                                <div>
                                  <span @click.stop="reviewHandler(item)"
                                    >review</span
                                  >
                                  {{ item.file_name }}
                                </div>
                              </div>
                              <div class="time">
                                {{
                                  formatTimeZoneTime(item.create_date, "HH:mm")
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="clear: both"></div>
                      </template>
                    </li>

                    <li class="leftTalk" v-if="useWs">
                      <div class="box">
                        <img src="@/assets/img/robot.png" alt="" />
                        <div class="wrap-info">
                          <div class="message thinking">
                            <div
                              class="rotationtiao_left"
                              style="margin-bottom: 10px"
                            >
                              <div class="gpt-flex-row">
                                <a-icon type="check" class="rightIcon" />
                                <div>{{ $t("thinking") }}</div>
                              </div>
                              <div
                                class="gpt-flex-row"
                                style="margin-bottom: 10px"
                              >
                                <a-icon type="check" class="rightIcon" />
                                <div class="newLine" style="width: 400px">
                                  Start Answering: {{ content_chat }}
                                </div>
                              </div>
                              <div
                                id="messageContent"
                                class="messageContent"
                              ></div>
                              <!-- <div class="cursor-blink">&nbsp;</div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="leftTalk" v-if="sending">
                      <div class="box">
                        <img src="@/assets/img/robot.png" alt="" />
                        <div class="wrap-info">
                          <div class="message thinking">
                            <div class="text">thinking</div>
                            <div class="loading-chat">
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="clear: both"></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </simplebar>
        </div>
        <div class="bottom">
          <div style="padding-top: 26px" v-show="showFinish">
            <div
              class="finish"
              :class="{ disabled: sending }"
              @click="finishHandler"
            >
              {{ $t("FinishCeneration") }}
            </div>
          </div>
          <div style="padding-top: 26px" v-show="showFinish">
            <div class="process">
              <a-icon
                type="exclamation-circle"
                style="font-size: 12px; color: #ffa200; margin-right: 5px"
              />
              {{ $t("flowTsk") }}
            </div>
          </div>
          <div style="padding-top: 26px" v-show="!showFinish && startTalk">
            <div class="process">
              <a-icon
                type="exclamation-circle"
                style="font-size: 12px; color: #ffa200; margin-right: 5px"
              />
              {{ $t("QaProcess") }}
            </div>
          </div>

          <div class="wrap-list" v-if="!showTempList">
            <simplebar
              data-simplebar-auto-hide="true"
              class="simplebarWrap"
              :scrollbarMaxSize="50"
              :style="{
                maxHeight: 50 + 'px',
                width: width,
              }"
            >
              <ul class="list">
                <li
                  v-for="(item, index) in assisData.follow_task"
                  :key="index"
                  @click="selectHandler(item, 2)"
                >
                  <div class="item" :class="{ disabled: sending }">
                    <div class="title">{{ item.desc }}</div>
                  </div>
                </li>
              </ul>
            </simplebar>

            <!-- <div class="more">
              <span>+More</span>
            </div> -->
          </div>
          <div class="wrap-list" v-if="showTempList && tempList.length > 0">
            <simplebar
              data-simplebar-auto-hide="true"
              class="simplebarWrap"
              ref="scrollContainer"
              :scrollbarMaxSize="50"
              :style="{
                maxHeight: 50 + 'px',
                width: width,
              }"
            >
              <ul class="list">
                <li
                  v-for="(item, index) in tempList"
                  :key="index"
                  @click="selectHandler(item, 2)"
                >
                  <div class="item" :class="{ disabled: sending }">
                    <div class="title">{{ item.desc }}</div>
                  </div>
                </li>
              </ul>
            </simplebar>

            <!-- <div class="more">
              <span>+More</span>
            </div> -->
          </div>
          <div
            class="messageBox"
            :style="{
              width: width,
            }"
          >
            <svg-icon iconClass="message" class="icon"></svg-icon>
            <div class="text" :class="{ noclick }">
              <div
                v-if="!noclick"
                ref="content"
                class="textCont"
                v-html="inputData"
                @click="editSpan"
                @blur="blurHandler"
                style="max-height: 210px; overflow-y: auto"
              ></div>
              <a-input
                v-model="messageInfo"
                :placeholder="$t('enterQs')"
                v-else
                :autosize="{ minRows: 2, maxRows: 10 }"
                style="overflow-y: auto"
                type="textarea"
                @input="changeHandler"
                class="textCont"
              ></a-input>
            </div>
            <a-icon
              @click="clearHandler"
              type="close-circle"
              style="margin-left: 10px; cursor: pointer"
            />
            <!-- <a-icon type="message" class="send" /> -->
            <span @click="sendHandler">
              <svg-icon
                v-if="!sending && !btn_loading"
                iconClass="send"
                class="send"
              ></svg-icon>
            </span>

            <div
              class="sending"
              v-if="sending || btn_loading"
              :style="{ cursor: btn_loading ? 'pointer' : 'default' }"
              @click="stopAnswer"
            >
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="richEdit" @click.stop v-if="showSendEmail">
        <a-form :form="form" class="form" layout="horizontal">
          <Toolbar
            ref="toolbar"
            :editor="editor"
            :defaultConfig="toolbarConfig"
          />
          <!-- 编辑器 -->
          <Editor
            style="overflow-y: hidden; background: #f6f6f6"
            :style="{ height: user.email ? '550px' : '490px' }"
            :defaultConfig="editorConfig"
            v-model="html"
            @onChange="onChange"
            @onCreated="onCreated"
          />
          <a-form-item
            style="margin-bottom: 10px; margin-top: 16px"
            :class="[lang]"
          >
            <a-input
              v-if="!user.email"
              size="large"
              class="process-input"
              v-decorator="[
                'email',
                {
                  trim: true,
                  rules: [
                    {
                      required: true,
                      validator: function (rule, value, callback) {
                        let reg =
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        if (!value || !value.trim('') || !reg.test(value)) {
                          callback($t('inputMaile'));
                          return;
                        }
                        callback();
                      },
                    },
                  ],
                },
              ]"
              :placeholder="$t('inputMaile')"
            >
              <svg-icon iconClass="email" slot="prefix"></svg-icon>
            </a-input>
          </a-form-item>
          <div class="sendBtn" @click="sendEmileHandler">
            {{ $t("sendMail") }}
          </div>
        </a-form>
      </div>
      <div class="mailMask" v-if="showSendEmail"></div>
    </div>
    <a-modal v-model="visible" :title="$t('inputMaile')" on-ok="handleOk">
      <a-form :form="form2" class="form" layout="horizontal">
        <a-form-item
          style="margin-bottom: 10px; margin-top: 16px"
          :class="[lang]"
        >
          <a-input
            size="large"
            class="process-input"
            v-decorator="[
              'email',
              {
                trim: true,
                rules: [
                  {
                    required: true,
                    validator: function (rule, value, callback) {
                      let reg =
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      if (!value || !value.trim('') || !reg.test(value)) {
                        callback($t($t('inputMaile')));
                        return;
                      }
                      callback();
                    },
                  },
                ],
              },
            ]"
            :placeholder="$t('inputMaile')"
          >
            <svg-icon iconClass="email" slot="prefix"></svg-icon>
          </a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleOk" size="large">
          {{ $t("send") }}
        </a-button>
      </template>
    </a-modal>
  </a-spin>
</template>
<script>
import { debounce } from "@/utils/util";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { i18nChangeLanguage } from "@wangeditor/editor"; // 导入国际化方法

import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";
import { mapGetters } from "vuex";
import axios from "axios";
// import marked from "marked";

export default {
  data() {
    return {
      cancelSource: null,
      visible: false,
      // ws
      ws: null,
      // 打印机定时器
      PrintWords: null,
      btn_loading: false,
      //是否使用ws
      useWs: false,
      contentList: [],
      //要回答的内容
      content_chat: "",
      //左侧最高高度
      maxLeftHeight: 400,
      //是否显示补充模板
      showTempList: false,
      // 模板列表
      tempList: [],
      //模板Id
      template_id: null,
      //正在发送中
      sending: false,
      //任务Id
      task_id: null,
      // 当前会话id
      session_id: null,
      //显示finish按钮
      showFinish: false,
      //对话列表
      dialogList: [],
      //显示更多
      showMore: false,
      //email taskID
      email_taskId: null,
      loading: true,
      startTalk: false,
      bottomHeight: 0,
      width: "800px",
      showSendEmail: false,
      /* 选中的引导数据*/
      selectedGuideDate: null,
      defaultImg: require("@/assets/img/avat.png"),
      sessionList: [
        {
          id: 1,
          name: "session_name1",
          create_date: "2024-10-11T15:32:44",
        },
        {
          id: 2,
          name: "session_name2",
          create_date: "2024-10-11T15:32:44",
        },
      ],
      assisData: {
        question: [],
        follow_task: [],
        task_list: [],
      },
      editor: null,
      html: "<p>hello&nbsp;world</p>",
      toolbarConfig: {
        toolbarKeys: [
          "headerSelect",
          "bold",
          "underline",
          "italic",
          "color",
          "fontSize",
          "lineHeight",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          /* 显示哪些菜单，如何排序、分组 */
        ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
      inputData: "Enter your question here",
      /* 当为输入框的时候*/
      messageInfo: "",
      noclick: true,
      maxWindowHeight: 700,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      form2: this.$form.createForm(this, { name: "dynamic_rule" }),
    };
  },
  watch: {
    lang(val) {
      console.log("lange", val);
      this.changeLang(val);
      this.getAssisList();
    },
  },
  i18n: require("./lang/hri18n"),
  components: { Editor, Toolbar, simplebar },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);

    this.resizeWindow();
    window.addEventListener("resize", debounce(this.resizeWindow, 300), false);
    setTimeout(() => {
      this.setScroll();
      // debugger;
      // console.log(
      //   this.$refs.scrollContainer,
      //   this.$refs.scrollContainer.simplebar
      // );
      // this.$refs.scrollContainer.simplebar.scrollTo(0, 100);
    }, 200);

    Promise.all([this.getSessionList(), this.getAssisList()])
      .then((res) => {
        console.log(res);
        this.loading = false;
        setTimeout(() => {
          this.resizeWindow();
        }, 100);
      })
      .catch(() => {
        this.loading = false;
      });
    this.changeLang(this.lang);
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    makdownTohtml(html) {
      const cont = html.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

      return cont;
    },
    imgError(e) {
      console.log(e.target.src);
      e.target.src = this.defaultImg;
    },
    handleOk() {
      this.form2.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          this.loading = true;
          this.visible = false;
          this.$axios(
            "/iris/send_chat_message",
            { ...values, session_id: this.session_id },
            "post"
          ).then((res) => {
            this.loading = false;
            if (res.code == 200) {
              this.$message.success(res.msg);
            }
          });
        }
      });
    },
    sendEmailHandler() {
      if (this.user.email) {
        this.loading = true;
        this.$axios(
          "/iris/send_chat_message",
          { email: this.user.email, session_id: this.session_id },
          "post"
        ).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.$message.success(res.msg);
          }
        });
      } else {
        this.visible = true;
      }
    },
    changeHandler() {
      setTimeout(() => {
        this.resizeWindow();
      }, 100);
    },
    //停止问答
    stopAnswer() {
      this.closeWebSocket();
    },
    getDate() {
      return new Date().toLocaleString("zh", { hour12: false });
    },
    async dialogue(data) {
      this.useWs = data.useWs;
      this.chat_reply = data;
      setTimeout(() => {
        this.resizeWindow();
      }, 100);
      // this.chatInfo.sessionId = data.sessionId;
      if (data.useWs) {
        this.ws = new WebSocket(
          `${process.env.VUE_APP_API_WSS_URL}/chat/ws/${this.session_id}`
        );
        this.wsDate = this.getDate();

        this.ws.addEventListener("open", (event) => {
          console.log(event);
          this.ws_status = "open";
          // console.log(event.data)
          console.log("WebSocket 连接已建立");
          this.putContent();
          //写自己的逻辑
        });

        this.ws.addEventListener("message", (event) => {
          const returnData = JSON.parse(event.data);
          console.log(returnData);
          // console.log(returnData) //解析数据 返回格式 {"code": 200,"message": "工"}  code=200标识正常  非200代表异常 500是发生错误
          this.addMessageToList(returnData.is_finished, returnData.message);
        });

        this.ws.addEventListener("close", (event) => {
          console.log(event);
          this.ws_status = "close";
          // console.log(this.ws_status,'this.ws_status')
          console.log("WebSocket 连接已关闭"); //每次消息推流完成后，服务端都是主动断开websocket
          //this.btn_loading = false;
          this.noclick = true;
          //this.useWs = false;
          //写自己的逻辑
        });

        this.ws.addEventListener("error", (event) => {
          this.ws_status = "error";
          this.btn_loading = false;
          this.noclick = true;
          console.error("WebSocket 连接错误：", event);
          this.useWs = false;
          //写自己的逻辑
        });
      }
    },
    async addMessageToList(code, msg) {
      if (code == 1) {
        //当msg = [DONE]时代表流信息结束
        this.ws_status = "close";

        // console.log(this.ws_status,'DONE');
        return;
      }
      if (code == 0) {
        if (msg.includes("\n")) {
          //msg = '<br>';
          msg = msg.replace(/\n/g, "<br>");
        }
        this.contentList.push(msg);
      }
    },
    putContent() {
      const messageContent = document.getElementById("messageContent");
      let index = 0;
      this.PrintWords = setInterval(() => {
        if (index < this.contentList.length) {
          messageContent.innerHTML += this.contentList[index];
          // console.log(messageContent.innerHTML);
          ++index;
          // setTimeout(() => {
          //   this.setScroll();
          // }, 100);
        }
        // console.log(this.ws_status)
        // console.log(index,this.contentList.length,'1032')
        if (index == this.contentList.length && this.ws_status == "close") {
          // console.log(this.ws_status,'this.ws_status')
          console.log("--------------");

          clearInterval(this.PrintWords);
          this.sending = true;
          this.$axios(
            "/iris/other_question",
            { session_id: this.session_id },
            "post"
          )
            .then((res) => {
              this.sending = false;
              this.btn_loading = false;
              this.noclick = true;
              if (res.code == 200) {
                console.log(res);
                this.contentList = [];
                let cont = this.makdownTohtml(messageContent.innerHTML);
                console.log(cont);
                this.dialogList.push({
                  create_date: Date.now(),
                  role_type: 0,
                  content: cont,
                  parse_cate: "z",
                  questionList: res.data,
                });
                this.useWs = false;
                messageContent.innerHTML = "";
                setTimeout(() => {
                  this.setScroll();
                }, 200);
              }
            })
            .catch(() => {
              this.sending = false;
              this.useWs = false;
              this.btn_loading = false;
            });

          // this.updateWsData();
        }
      }, 100);
    },
    //关闭websocket
    closeWebSocket() {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
        this.useWs = false;
        this.btn_loading = false;
        this.sending = false;
      }

      if (this.sending && !this.template_id) {
        this.btn_loading = false;
        this.sending = false;
        if (this.cancelSource) {
          this.cancelSource.cancel("Operation canceled by the user.");
        }
      }
      if (this.PrintWords) {
        clearInterval(this.PrintWords);
        this.PrintWords = null;
      }
    },

    //发送邮件
    sendEmileHandler() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$axios(
            "/iris/send_email",
            {
              session_id: this.session_id,
              task_id: this.email_taskId,
              email: this.user.email ? this.user.email : values.email,
              content: this.html,
            },
            "post"
          )
            .then((res) => {
              this.loading = false;
              this.showSendEmail = false;
              this.html = "";
              if (res.code == 200) {
                this.$message.success(res.msg);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    // 切换添加新对话
    addNewDialog() {
      this.startTalk = false;
      this.task_id = null;
      this.session_id = null;
      this.template_id = null;
      this.showFinish = false;
      this.showTempList = false;
      this.dialogList = [];
      this.inputData = null;
      this.messageInfo = null;
      this.noclick = true;
      this.closeWebSocket();
      setTimeout(() => {
        this.resizeWindow();
      }, 100);
    },
    //获取子任务列表
    async getTempList() {
      return new Promise((resolve, reject) => {
        this.$axios(
          "/iris/get_supplementary_template_list",
          { task_id: this.task_id },
          "post"
        )
          .then((res) => {
            if (res.code == 200) {
              this.tempList = res.data;
              this.showTempList = true;

              setTimeout(() => {
                this.resizeWindow();
                this.setScroll();
                resolve();
              }, 200);
            }
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },

    questionHandler(question) {
      this.$nextTick(() => {
        this.inputData = question;
        this.noclick = false;
        setTimeout(() => {
          this.sendHandler();
        }, 200);
      });
    },
    async sendHandler() {
      let content = null;
      if (!this.noclick && this.inputData) {
        // 判断是否有未替换的内容
        let nodes = document.querySelectorAll(".textCont span");
        let total = 0;
        nodes.forEach((node) => {
          let origin = node.getAttribute("origin-data");
          if (origin == node.innerText) {
            total++;
          }
        });
        if (total > 0) {
          this.$message.warning(this.$t("checkContentTips"));
          return;
        }
        this.sending = true;
        content = document.querySelector(".textCont").innerText;
      }
      if (this.noclick && this.messageInfo) {
        content = this.messageInfo;
      }
      if (!content) {
        return;
      }
      this.dialogList.push({
        role_type: 1,
        content,
        create_date: Date.now(),
        parse_cate: "z",
      });
      this.startTalk = true;
      setTimeout(() => {
        this.resizeWindow();
        setTimeout(() => {
          this.setScroll();
        }, 100);
      }, 100);

      if (this.inputData || this.messageInfo) {
        this.inputData = null;
        this.messageInfo = null;
        this.sending = true;
        let CancelToken = axios.CancelToken;
        // 创建一个CancelToken
        this.cancelSource = CancelToken.source();
        axios
          .post(
            "/iris/intention_judgment",
            {
              content,
              session_id: this.session_id,
              task_id: this.task_id,
              template_id: this.template_id,
            },
            {
              cancelToken: this.cancelSource.token,
            }
          )
          .then((res) => {
            if (res.code == 200) {
              let { session_id, task_id, use_ws, write_prompt } = res.data;
              this.session_id = session_id;
              if (session_id) {
                this.getSessionList();
              }
              this.task_id = task_id;
              // this.noclick = true;
              this.showFinish = task_id ? true : false;
              if (!task_id && use_ws) {
                this.noclick = true;
                this.content_chat = content;
                this.sending = false;
                this.btn_loading = true;
                setTimeout(() => {
                  this.setScroll();
                }, 100);
                this.dialogue({ useWs: true, content_chat: content });
                return;
              }
              if (!task_id && write_prompt) {
                this.sending = false;
                this.noclick = false;
                this.inputData = write_prompt
                  .replaceAll("[", "<span @click='edit'>[")
                  .replaceAll("]", "]</span>");
                setTimeout(() => {
                  let nodes = document.querySelectorAll(".textCont span");
                  console.log(nodes);
                  nodes.forEach((node) => {
                    node.setAttribute("origin-data", node.innerText);
                  });
                }, 300);
                return;
              }
              setTimeout(
                () => {
                  this.resizeWindow();
                  setTimeout(() => {
                    this.setScroll();
                    if (!task_id) {
                      this.sending = false;
                    } else {
                      this.noclick = false;
                      // 有task_id返回
                      this.getTempList().then(() => {
                        this.$axios(
                          "/iris/get_task_result",
                          { task_id, session_id: this.session_id },
                          "post"
                        )
                          .then((res) => {
                            this.sending = false;
                            if (res.code == 200) {
                              let {
                                content,
                                file_name,
                                answer_content,
                                task_id,
                              } = res.data;
                              let obj = {
                                content,
                                create_date: Date.now(),
                                file_name,
                                answer_content,
                                role_type: 0,
                                parse_cate: "a",
                                task_id,
                              };
                              this.dialogList.push(obj);
                              this.reviewHandler(obj);

                              setTimeout(() => {
                                this.setScroll();
                              }, 200);
                            }
                          })
                          .catch(() => {
                            this.sending = false;
                          });
                      });
                    }
                  }, 100);
                },
                this.showFinish ? 200 : 0
              );
            } else {
              this.sending = false;
              if (this.task_id) {
                this.noclick = false;
              } else {
                this.noclick = true;
              }
            }
          })
          .catch((error) => {
            this.sending = false;
            if (axios.isCancel(error)) {
              console.log("Request canceled", error.message);
            } else {
              console.error("Error:", error);
            }
          });
      }
    },
    finishHandler() {
      this.loading = true;
      this.$axios(
        "/iris/finish_task",
        {
          session_id: this.session_id,
          task_id: this.task_id,
        },
        "post"
      )
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.task_id = null;
            this.template_id = null;
            this.showTempList = false;
            this.showFinish = false;
            this.noclick = true;
            setTimeout(() => {
              this.resizeWindow();
              setTimeout(() => {
                this.setScroll();
              }, 100);
            }, 200);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeLang(val) {
      console.log("lange", val);
      if (val == "CN") {
        //中文
        i18nChangeLanguage("zh-CN");
      } else if (val == "US") {
        //英语
        i18nChangeLanguage("en");
      } else if (val == "AR") {
        //阿拉伯语
        i18nChangeLanguage("en");
      }
    },
    handleOutsideClick() {
      this.showSendEmail = false;
    },
    removeEmptyTextNodes(element) {
      for (let node of element.childNodes) {
        if (node.nodeType === Node.TEXT_NODE && !node.textContent.trim()) {
          node.parentNode.removeChild(node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          this.removeEmptyTextNodes(node);
        }
      }
    },
    cleanUlElement(element) {
      // 获取所有子节点
      const children = Array.from(element.children);

      children.forEach((child) => {
        if (child.tagName.toLowerCase() !== "li") {
          // 如果子节点不是 <li>，则移除它
          element.removeChild(child);
        } else {
          // 如果子节点是 <li>，则递归清理其子节点
          this.cleanUlElement(child);
        }
      });
    },
    cleanUlString(ulString) {
      // 创建一个临时的 DOM 容器
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = ulString;
      // 获取所有的 <ul> 元素
      const ulElements = tempDiv.querySelectorAll("ul");
      ulElements.forEach((ulElement) => {
        // 获取所有子节点
        const children = Array.from(ulElement.children);
        children.forEach((child) => {
          console.log(child.tagName);
          if (child.tagName.toLowerCase() !== "li") {
            // 如果子节点不是 <li>，则移除它
            ulElement.removeChild(child);
          } else if (
            child.nodeType === Node.TEXT_NODE &&
            !child.textContent.trim()
          ) {
            ulElement.removeChild(child);
            //child.textContent = "";
          } else {
            // 如果子节点是 <li>，则递归清理其子节点
            this.cleanUlElement(child);
          }
        });
      });
      this.removeEmptyTextNodes(tempDiv);
      // 返回清理后的 HTML 字符串
      return tempDiv.innerHTML;
    },
    reviewHandler(item) {
      this.email_taskId = item.task_id;
      this.html = `${this.cleanUlString(item.content)}`;
      this.showSendEmail = true;
    },
    showDialog(item) {
      this.loading = true;
      this.session_id = item.session_id;
      this.inputData = null;
      this.messageInfo = null;
      this.sending = false;
      this.btn_loading = false;
      this.closeWebSocket();
      this.$axios(
        "/iris/get_session_content",
        { session_id: item.session_id },
        "post"
      )
        .then((res) => {
          let arr = [];
          this.loading = false;
          if (res.code == 200) {
            this.startTalk = true;
            let { list, open_task_id } = res.data;
            this.$nextTick(() => {
              for (let key in list) {
                let obj = { date: key, type: "date" };
                arr.push(obj);
                for (var index = 0; index < list[key].length; index++) {
                  let obj2 = list[key][index];
                  obj2.content = this.makdownTohtml(obj2.content);
                  obj2.content = obj2.content
                    ? obj2.content.replace(/\n/g, "<br>")
                    : "";
                  arr.push(obj2);
                }
              }
              this.dialogList = arr;
              if (open_task_id > 0) {
                this.showFinish = true;
                this.task_id = open_task_id;
                this.noclick = false;
              } else {
                this.showFinish = false;
                this.task_id = null;
                this.noclick = true;
              }
            });
            setTimeout(() => {
              this.resizeWindow();
              setTimeout(() => {
                this.setScroll();
              }, 100);
            }, 200);
            if (open_task_id) {
              this.task_id = open_task_id;
              this.getTempList().then(() => {
                setTimeout(() => {
                  this.resizeWindow();
                  setTimeout(() => {
                    this.setScroll();
                  }, 100);
                }, 600);
              });
            } else {
              this.showTempList = false;
            }

            this.getSessionList();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearHandler() {
      if (this.noclick) {
        this.messageInfo = "";
      } else {
        this.inputData = "";
        this.noclick = true;
        this.selectedGuideDate = null;
        if (this.task_id) {
          this.finishHandler();
        }
      }
    },
    onScroll(e) {
      // eslint-disable-next-line
      console.log("onscroll", e);
    },
    getSessionList() {
      return new Promise((resolve, reject) => {
        this.$axios("/iris/session_list", {}, "post").then((res) => {
          if (res.code == 200) {
            this.sessionList = res.data;
            resolve();
          } else {
            reject();
          }
        });
      });
    },

    getRandomTwoObjects(array) {
      // 创建一个数组的副本，以避免修改原始数组
      const copy = array.slice();

      // 使用 Fisher-Yates 洗牌算法打乱数组
      for (let i = copy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [copy[i], copy[j]] = [copy[j], copy[i]];
      }

      // 返回前两个元素
      return copy.slice(0, 2);
    },
    getAssisList() {
      return new Promise((resolve, reject) => {
        this.$axios("/iris/session_assist", {}, "post").then((res) => {
          if (res.code == 200) {
            this.assisData = res.data;
            this.assisData.question = this.getRandomTwoObjects(
              res.data.question
            );
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
    },
    resizeWindow() {
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.maxLeftHeight = h - 104 - 48 - 49 - 30 - 60 - 40;
      this.width = document.body.clientWidth - 614 - 30 + "px";
      var bottomHeight = document.querySelector(".bottom").clientHeight;
      this.bottomHeight = bottomHeight;
      console.log(bottomHeight);
      // var wraplist = document.querySelector(".wrap-list");
      // var wrapListHeight = 0;
      // if (wraplist) {
      //   wrapListHeight = wraplist.clientHeight;
      // }

      let dialogHeight = h - bottomHeight - 144;
      if (this.startTalk) {
        dialogHeight = dialogHeight - 48;
      }
      this.maxWindowHeight = dialogHeight;
      //this.setScroll();
    },
    setScroll() {
      var scrollHeight = document.querySelector(
        ".simplebarWrap-dialog .simplebar-content-wrapper"
      ).scrollHeight;
      document.querySelector(
        ".simplebarWrap-dialog .simplebar-content-wrapper"
      ).scrollTop = scrollHeight;
    },
    selectHandler(item, type) {
      this.$nextTick(() => {
        if (this.sending || this.btn_loading) {
          return;
        }
        this.noclick = false;
        let cont = type == 1 ? item.prompt : item.prompt;
        this.selectedGuideDate = item;
        this.template_id = item.template_id;
        this.inputData = cont
          .replaceAll("[", "<span @click='edit'>[")
          .replaceAll("]", "]</span>");
        console.log(item);
        setTimeout(() => {
          let nodes = document.querySelectorAll(".textCont span");
          console.log(nodes);
          nodes.forEach((node) => {
            node.setAttribute("origin-data", node.innerText);
          });
        }, 300);
        setTimeout(() => {
          this.resizeWindow();
        }, 300);

        if (type == 1) {
          setTimeout(() => {
            this.sendHandler();
          }, 200);
        }
      });
      // setTimeout(() => {
      //   const inputElement = this.$refs.content.querySelector("input");
      //   inputElement.addEventListener("blur", this.blurHandler);
      // }, 300);
    },
    edit(event) {
      console.log(event);
    },
    computeInputWidth(input, value) {
      let width = 8;
      for (let i = 0; i < value.length; i++) {
        let char = value.charAt(i);
        const regex = /[\u4e00-\u9fa5]/;

        if (regex.test(char)) {
          width += 14;
        } else {
          width += 8;
        }
      }
      input.style.width = width + "px";
    },
    editSpan(event) {
      if (event.target.nodeName == "SPAN") {
        let targetNode = event.target;
        var input = document.createElement("input");
        input.type = "text"; // 设置input类型，例如text
        input.name = "myInput"; // 设置input的name属性

        targetNode.style.display = "none";
        // input.style.width = (targetNode.innerText.length + 1) * 8 + "px";

        // input.value = targetNode.innerText
        //   .replaceAll("[", "")
        //   .replaceAll("]", "");
        var text = targetNode.innerText.replaceAll("[", "").replaceAll("]", "");

        var origin = targetNode
          .getAttribute("origin-data")
          .replaceAll("[", "")
          .replaceAll("]", "");
        input.setAttribute("origin-data", origin);
        if (text == origin) {
          input.value = "";
          input.style.width = "12px";
        } else {
          input.value = text;
          this.computeInputWidth(input, text);
        }
        input.addEventListener("blur", this.blurHandler);
        let that = this;
        input.addEventListener("input", function (event) {
          console.log(event.target.value);
          let value = event.target.value;
          that.computeInputWidth(this, value);
          // let width = 8;
          // for (let i = 0; i < value.length; i++) {
          //   let char = value.charAt(i);
          //   const regex = /[\u4e00-\u9fa5]/;

          //   if (regex.test(char)) {
          //     width += 14;
          //   } else {
          //     width += 8;
          //   }
          // }
          // this.style.width = width + "px";
        });
        targetNode.parentNode.insertBefore(input, targetNode.nextSibling);
        setTimeout(() => {
          input.focus();
        });
      }
      console.log(event.current);
    },
    blurHandler(event) {
      if (event.target.nodeName == "INPUT") {
        let targetNode = event.target;
        if (targetNode.value) {
          event.target.previousElementSibling.innerText =
            "[" + targetNode.value + "]";
        } else {
          event.target.previousElementSibling.innerText =
            "[" + targetNode.getAttribute("origin-data") + "]";
        }

        console.log(targetNode);
        event.target.previousElementSibling.style.display = "inline-block";
        event.target.style.display = "none";
        setTimeout(
          function (event1) {
            event1.target.parentNode.removeChild(event1.target);
          }.bind(this, event),
          300
        );
      }
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
    document.removeEventListener("click", this.handleOutsideClick);
    this.closeWebSocket();
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>

<style lang="less" scoped>
.home {
  padding: 20px 30px;
  display: flex;
  .left {
    width: 300px;
    border-radius: 4px;
    background: #fff;
    padding: 30px;
    .btn {
      width: 100%;
      height: 48px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 2px solid #0e756a;
      line-height: 48px;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #0e756a;
      cursor: pointer;
    }
    .tit {
      font-weight: 900;
      font-size: 18px;
      color: #000000;
      text-align: center;
      height: 26px;
      line-height: 26px;
      margin-top: 23px;
    }
    ul {
      margin-top: -6px;
      li {
        width: 242px;
        margin-top: 20px;
        height: 58px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d6d6d6;
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        display: flex;
        justify-content: left;
        align-items: center;
        cursor: pointer;
        padding: 0 16px;
        .icon {
          margin-right: 12px;
        }
        span {
          display: inline-block;
          width: 148px;
          flex: 1;
          height: 16px;
          line-height: 16px;
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .right {
    flex: 1;
    width: 100%;
    margin-left: 30px;
    .dialogWind {
      padding-bottom: 20px;
      width: 100%;
      .guide {
        background: linear-gradient(180deg, #f3fef3 0%, #ffffff 100%);
        border-radius: 4px 4px 4px 4px;
        padding: 50px 50px 30px 50px;
        position: relative;
        img {
          width: 205px;
          height: 188px;
          position: absolute;
          right: 25px;
          top: 18px;
        }
        .tit {
          font-weight: 900;
          font-size: 56px;
          color: #0e756a;
        }
        .small {
          font-weight: 400;
          font-size: 36px;
          color: #666666;
        }
        .tit2 {
          margin-top: 42px;
          font-weight: 900;
          font-size: 20px;
          color: #000000;
        }
        .hrask {
          background: #f9f9f9;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d9dfe9;
          line-height: 30px;
          font-size: 20px;
          font-weight: 400;
          padding: 30px;
          margin-top: 20px;
          cursor: pointer;
        }
        .hrask:hover {
          background: #f8fcf8;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #0e756a;
        }
        @media screen and (max-width: 1680px) {
          .tit {
            font-size: 30px;
          }
          .small {
            font-size: 26px;
          }
          .tit2 {
            margin-top: 18px;
          }
          .hrask {
            line-height: 20px;
            padding: 12px;
            font-size: 14px;
            box-sizing: content-box;
          }
        }
      }
      .guide2 {
        margin-top: 20px;
        padding: 30px 50px;
        background: linear-gradient(180deg, #f3fef3 0%, #ffffff 100%);
        border-radius: 4px 4px 4px 4px;
        .tit {
          height: 28px;
          line-height: 28px;
          font-weight: 900;
          font-size: 20px;
          color: #000000;
        }
        .boxList {
          padding-top: 6px;
          .item {
            // margin-top: 20px;
            .unit {
              padding: 20px;
              background: #f9f9f9;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #d9dfe9;
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-top: 20px;
              .icon {
                display: inline-block;
                width: 14px;
                margin-right: 10px;
                font-size: 14px;
                color: #0e756a;
              }
              p {
                width: 100%;
                flex: 1;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
                box-sizing: border-box;
                line-height: 17px;
                height: 17px;
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
              }
            }
            .unit:hover {
              background: #f8fcf8;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #0e756a;
            }
          }
        }
      }
    }
    .wrap-mail {
      height: 48px;

      text-align: right;
      .sendMail {
        display: inline-block;
        cursor: pointer;
        align-items: center;
        border: #0e756a solid 2px;
        border-radius: 4px;
        padding: 0 20px;
        font-weight: 400;
        font-size: 14px;
        color: #0e756a;
        background: #fff;
        height: 44px;
        line-height: 44px;
        z-index: 200;
        .icon {
          margin-right: 10px;
          font-size: 16px;
        }
      }
    }
    .talkWind {
      .wrap-message {
        margin-top: 30px;
        // padding: 30px 30px 30px 30px;

        li {
          // margin-bottom: 30px;
          .box {
            box-sizing: border-box;
            width: 668px;
            display: flex;
            align-items: top;
            border-radius: 4px;
            display: flex;
            align-items: top;
            img {
              width: 57px;
              height: 57px;
              border-radius: 50%;
            }
            .message {
              font-family: Arial, Arial;
              font-weight: 400;
              font-size: 16px;
              color: #36384d;
              line-height: 24px;
              margin-bottom: 30px;
              .messageText::selection {
                text-decoration: none;
              }
              .answerContent {
                margin-bottom: 10px;
              }
              .time {
                margin-top: 18px;
                color: #fff;
                font-weight: bold;
                font-size: 16px;
              }
            }
            .gpt-flex-row {
              display: flex;
              align-items: center;
            }
            .messageContent::after {
              content: "|";
              animation: blink 1s infinite;
            }
            @keyframes blink {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            .rightIcon {
              color: #0e756a;
              margin-right: 10px;
            }
            .newline {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .name {
              font-family: Arial, Arial;
              font-weight: bold;
              font-size: 18px;
            }
          }
          .date {
            color: #80829f;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 32px;
          }
        }

        .leftTalk {
          position: relative;
          .box {
            // background: #eeeff6;

            .wrap-info {
              margin-left: 18px;
              span {
                display: inline-block;
                color: #ffffff;
                background: #0e756a;
                border-radius: 4px 4px 4px 4px;
                padding: 6px 11px;
                font-size: 16px;
                cursor: pointer;
              }
            }
            .question-ext {
              font-weight: bold;
              font-size: 16px;
              color: #0e756a;
              font-weight: bold;
              font-size: 16px;
              color: #0e756a;
              padding: 20px 30px;
              background: #fff;
              max-width: 400px;
              border-radius: 0px 10px 10px 10px;
              cursor: pointer;
              margin-left: 18px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .icon {
                margin-left: 12px;
                color: #0e756a;
                font-size: 12px;
              }
            }
            .message {
              color: #000;
              background: #ffffff;
              border-radius: 0px 10px 10px 10px;
              padding: 20px 30px 14px 30px;
              margin-bottom: 30px;
              .time {
                text-align: left;
                color: #000;
              }
            }
            .name {
              color: #000000;
            }
          }
        }
        .rightTalk {
          position: relative;
          .box {
            // background: #0e756a;
            justify-content: right;
            float: right;
            .wrap-info {
              margin-right: 18px;
              // width: 542px;
            }
            .message {
              color: #bcdeda;
              background: linear-gradient(268deg, #1c746b 0%, #1b8176 100%);
              border-radius: 10px 0px 10px 10px;
              padding: 30px 30px 10px 30px;
            }
            .name {
              color: #fff;
              text-align: right;
            }
          }
          .box::after {
            content: "";
            clear: both;
          }
          .time {
            text-align: right;
            color: #ffffff;
          }
        }
      }
    }
    .bottom {
      .finish {
        width: 256px;
        height: 47px;
        padding: 0 16px;
        margin: 0 auto;
        background: #0e756a;
        border-radius: 4px 4px 4px 4px;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        line-height: 47px;
        text-align: center;
        cursor: pointer;
      }
      .disabled {
        cursor: not-allowed;
        background: #e6e6e6;
        color: #999999;
        border: #e6e6e6 solid 1px;
      }
      .process {
        .icon {
          color: #ffa200;
          margin-right: 8px;
        }
        color: #ffa200;
        font-size: 16px;
        background: #fff7df;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ffcc3e;
        padding: 11px 12px;
      }
      .wrap-list {
        padding-top: 20px;
        position: relative;
        overflow: hidden;
        height: 40px;
        box-sizing: content-box;
        .list {
          height: 40px;
          // overflow-y: hidden;
          display: flex;
          flex-wrap: nowrap;
          li {
            display: inline-block;
            height: 40px;
            margin-right: 10px;

            .item {
              padding: 0 12px;
              background: #ffffff;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #0e756a;
              font-weight: 400;
              font-size: 12px;
              color: #0e756a;
              height: 30px;
              line-height: 30px;
              cursor: pointer;
              white-space: nowrap;
            }
            .disabled {
              cursor: not-allowed;
              background: #e6e6e6;
              color: #999999;
              border: #e6e6e6 solid 1px;
            }
          }
        }

        .more {
          position: absolute;
          right: 0;
          height: 32px;
          width: 107px;
          background: url(../../assets/img/more_bg.png) no-repeat;
          top: 20px;
          text-align: center;
          span {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            cursor: pointer;
            color: #0e756a;
          }
        }
      }
      .messageBox {
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d9dfe9;
        display: flex;
        padding: 12px 29px 12px 12px;
        margin-top: 10px;
        // height: 70px;
        align-items: center;
        .icon {
          font-size: 24px;
          margin-right: 26px;
        }
        .text {
          width: 100%;
          flex: 1;
          // height: 70px;
          overflow-y: auto;
          // padding: 12px 0;
          .textCont {
            // height: 46px;
            overflow-y: auto;
            border: none;
            box-shadow: none;
            // min-height: 50px;
            overflow: auto;
            resize: none;
            /deep/span {
              display: inline-block;
              color: #0e756a;
              cursor: pointer;
            }
            /deep/input {
              border: none;
              border-bottom: 1px solid #000;
              outline: none;
            }
            /deep/input:focus,
            input:active {
              box-shadow: none;
            }
          }
          // display: flex;
          // align-items: center;
        }
        .noclick {
          color: #bec0d5;
          font-size: 16px;
        }
        .send {
          font-size: 32px;
          margin-left: 20px;
          cursor: pointer;
        }
        .sending {
          margin-left: 20px;
          width: 32px;
          border: #ff6a6a solid 4px;
          position: relative;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .circle {
            width: 12px;
            height: 12px;
            background: #ff6a6a;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .mailMask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.16);
    z-index: 999;
  }
  .richEdit {
    right: 0;
    position: fixed;
    width: 584px;
    height: 720px;
    top: 64px;
    background: #ffffff;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
    border-radius: 4px 4px 4px 4px;
    padding: 13px;
    z-index: 1000;
    .quill-editor {
      height: 560px;
    }
    .sendBtn {
      width: 100%;
      margin-top: 24px;
      height: 54px;
      line-height: 54px;
      background: #0e756a;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  /deep/.w-e-text-container {
    background: #f6f6f6;
    border-radius: 4px;
  }
}
.scroll-area {
  position: relative;
  margin: auto;
  height: 500px;
}
.simplebarWrapLeft {
  width: 242px;
  /deep/.simplebar-scrollbar:before {
    background: #0e756a;
  }
}
.simplebarWrap {
  /deep/.simplebar-scrollbar:before {
    background: #0e756a;
  }
  /* 定制滚动条轨道 */
  .simplebar-track.simplebar-vertical {
    width: 10px; /* 设置宽度 */
  }

  /* 定制滚动条滑块 */
  .simplebar-scrollbar.simplebar-vertical .simplebar-thumb {
    width: 10px; /* 设置滑块宽度 */
    background-color: red; /* 设置滑块颜色 */
    border-radius: 10px; /* 设置滑块圆角 */
  }

  /* 定制滚动条轨道和滑块的交界处 */
  .simplebar-track.simplebar-vertical .simplebar-scrollbar {
    border-radius: 10px; /* 设置圆角 */
  }
}

/**chatloading */
.loading_chat,
.loading-chat > div {
  position: relative;
  box-sizing: border-box;
}

.loading-chat {
  display: block;
  font-size: 0;
  color: #0e756a;
}

.loading-chat.la-dark {
  color: #333;
}

.loading-chat > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.loading-chat {
  width: 64px;
  height: 18px;
}

.loading-chat > div:nth-child(1) {
  animation-delay: -200ms;
}

.loading-chat > div:nth-child(2) {
  animation-delay: -100ms;
}

.loading-chat > div:nth-child(3) {
  animation-delay: 0ms;
}

.loading-chat > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  animation: ball-pulse 1s ease infinite;
}

.loading-chat.la-sm {
  width: 26px;
  height: 8px;
}

.loading-chat.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.loading-chat.la-2x {
  width: 108px;
  height: 36px;
}

.loading-chat.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.loading-chat.la-3x {
  width: 162px;
  height: 54px;
}

.loading-chat.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

@keyframes ball-pulse {
  0%,
  60%,
  100% {
    opacity: 1;
    transform: scale(1);
  }

  30% {
    opacity: 0.1;
    transform: scale(0.01);
  }
}
.loading-link {
  position: relative;
  width: 15px;
  height: 15px;
  border: 2px solid #666;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.thinking {
  display: flex;
  align-items: center;
  .text {
    margin-right: 10px;
  }
}
</style>
