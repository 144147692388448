// import TabsView from "@/layouts/tabs/TabsView";
// import  /* webpackChunkName: "blanview" */ "@/layouts/BlankView" from "@/layouts/ /* webpackChunkName: "blanview" */ "@/layouts/BlankView"";
// import PageView from "@/layouts/PageView";

// 路由配置
const options = {
  routes: [
    {
      path: "/login",
      name: "登录页",
      component: () => import(/* webpackChunkName: "login" */ "@/pages/login"),
    },
    {
      path: "*",
      name: "404",
      component: () =>
        import(/* webpackChunkName: "exception" */ "@/pages/exception/404"),
    },
    {
      path: "/403",
      name: "403",
      component: () =>
        import(/* webpackChunkName: "exception" */ "@/pages/exception/403"),
    },
    {
      path: "/loginskip",
      name: "loginskip",
      component: () =>
        import(/* webpackChunkName: "exception" */ "@/pages/login/loginskip"),
    },
    {
      path: "/",
      name: "首页",
      component: () =>
        import(/* webpackChunkName: "TabsView" */ "@/layouts/tabs/TabsView"),
      redirect: "/login",
      children: [
        {
          path: "sales",
          name: "Sales",
          meta: {
            icon: "menu-sale1",
            iconSelected: "menu-sale2",
            invisible: false,
          },
          component: () =>
            import(/* webpackChunkName: "blanview" */ "@/layouts/BlankView"),
          children: [
            {
              path: "salesReps",
              name: "Sales Reps",
              component: () =>
                import(
                  /* webpackChunkName: "salesBankView" */ "@/pages/sales/salesReps/salesBankView"
                ),
              redirect: "/sales/salesReps/home",
              children: [
                {
                  path: "home",
                  name: "Home",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "salesReps" */ "@/pages/sales/salesReps/home"
                    ),
                },
                {
                  path: "campaign",
                  name: "Campaign",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "salesReps" */ "@/pages/sales/salesReps/campaign"
                    ),
                },
                {
                  path: "campaignDetail",
                  name: "Campaign Detail",
                  meta: {
                    invisible: true,
                    keepAlive: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "sales-salesReps" */ "@/pages/sales/salesReps/campaignDetail"
                    ),
                },
                {
                  path: "createCampaign",
                  name: "create campaign",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "sales-createCampaign" */ "@/pages/sales/salesReps/createCampaign"
                    ),
                },
                {
                  path: "dashboard",
                  name: "Dashboard",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "sales-dashboard" */ "@/pages/sales/salesReps/dashboard"
                    ),
                },
                {
                  path: "linkin",
                  name: "LinkedIn Account Setting",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "sales-linkin" */ "@/pages/sales/salesReps/linkin"
                    ),
                },
                {
                  path: "dialogue",
                  name: "dialogue",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "sales-dialogue" */ "@/pages/sales/salesReps/dialogue"
                    ),
                },
              ],
            },
            {
              name: "Marketing experts",
              path: "marketingExperts",
              component: () =>
                /* webpackChunkName: "sales-dialogue" */ import(
                  "@/pages/sales/salesReps/salesBankView"
                ),
              meta: {
                disabled: true,
              },
            },
          ],
        },
        {
          path: "hr",
          name: "HR",
          meta: {
            icon: "menu-hr",
            iconSelected: "menu-hr2",
          },
          component: () =>
            import(/* webpackChunkName: "blanview" */ "@/layouts/BlankView"),
          children: [
            {
              path: "hrExpert",
              name: "Hr Experts",
              meta: {
                page: {
                  cacheAble: false,
                },
              },
              component: () =>
                import(
                  /* webpackChunkName: "hrExpert" */ "@/pages/hr/hrExperts"
                ),
            },

            {
              path: "recruiter",
              name: "recruiter",
              component: () =>
                import(
                  /* webpackChunkName: "recruiter" */ "@/pages/hr/recruiter/salesBankView"
                ),
              redirect: "/hr/recruiter/home",
              meta: {
                invisible: false,
              },
              children: [
                {
                  path: "home",
                  name: "Home",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruiter-home" */ "@/pages/hr/recruiter/home"
                    ),
                },
                {
                  path: "campaign",
                  name: "Campaign",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruitercampaign" */ "@/pages/hr/recruiter/campaign"
                    ),
                },
                {
                  path: "campaignDetail",
                  name: "Campaign Detail",
                  meta: {
                    invisible: true,
                    keepAlive: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruitercampaign" */ "@/pages/hr/recruiter/campaignDetail"
                    ),
                },
                {
                  path: "createCampaign",
                  name: "create campaign",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruitercampaign" */ "@/pages/hr/recruiter/createCampaign"
                    ),
                },
                {
                  path: "dashboard",
                  name: "Dashboard",
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruiterDashboard" */ "@/pages/hr/recruiter/dashboard"
                    ),
                },
                {
                  path: "linkin",
                  name: "LinkedIn Account Setting",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruiterlinkin" */ "@/pages/hr/recruiter/linkin"
                    ),
                },
                {
                  path: "dialogue",
                  name: "dialogue",
                  meta: {
                    invisible: true,
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "recruiterdialogue" */ "@/pages/hr/recruiter/dialogue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "setting",
          name: "Setting",
          meta: {
            icon: "menu-setting",
            iconSelected: "menu-setting2",
          },
          component: () =>
            import(/* webpackChunkName: "blanview" */ "@/layouts/BlankView"),
          children: [
            {
              path: "userSetting",
              component: () => import("@/pages/setting/userSetting"),
              redirect: "/setting/userSetting/plan",
              meta: {
                invisible: true,
              },
              children: [
                {
                  path: "userProfile",
                  name: "User Profile",
                  component: () =>
                    import(
                      /* webpackChunkName: "userSetting" */ "@/pages/setting/userProfile/index"
                    ),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
                {
                  path: "planStep2",
                  name: "Plan step2",
                  component: () =>
                    import(
                      /* webpackChunkName: "planStep" */ "@/pages/setting/pricing/planstep2"
                    ),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
                {
                  path: "planStep3",
                  name: "Plan step3",
                  component: () =>
                    import(
                      /* webpackChunkName: "planStep" */ "@/pages/setting/pricing/planstep3"
                    ),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
                {
                  path: "plan",
                  name: "Plan",
                  component: () =>
                    import(
                      /* webpackChunkName: "planStep" */ "@/pages/setting/pricing/plan"
                    ),
                  meta: {
                    invisible: true,
                    page: {
                      cacheAble: false,
                    },
                  },
                },
              ],
            },
            {
              path: "order",
              name: "order",
              component: () =>
                import(
                  /* webpackChunkName: "order" */ "@/pages/setting/order/index"
                ),
              meta: {
                page: {
                  cacheAble: false,
                },
              },
            },
            {
              path: "discount",
              name: "discount",
              component: () =>
                import(
                  /* webpackChunkName: "discount" */ "@/pages/setting/discount/index"
                ),
              meta: {
                invisible: true,
              },
            },
            {
              path: "bindAccount",
              name: "bindAccount",
              component: () =>
                import(
                  /* webpackChunkName: "bindAccount" */ "@/pages/setting/pricing/bindAccount.vue"
                ),
              meta: {
                invisible: true,
                page: {
                  cacheAble: false,
                },
              },
            },
          ],
        },
        {
          path: "commissionList",
          name: "CommissionList",
          meta: {
            icon: "commission",
            iconSelected: "commission-1",
            showLevel1: true,
            // invisible: true,
            permission: "is_seed",
          },
          component: () =>
            import(
              /* webpackChunkName: "commissionList" */ "@/pages/commission/list"
            ),
          // children: [
          //   {
          //     path: "list",
          //     name: "List",
          //     component: () => import("@/pages/commission/list"),
          //     meta: {
          //       invisible: true,
          //     },
          //   },
          // ],
        },

        {
          path: "exception",
          name: "异常页",
          meta: {
            icon: "warning",
            invisible: true,
          },
          component: import(
            /* webpackChunkName: "blanview" */ "@/layouts/BlankView"
          ),
          children: [
            {
              path: "404",
              name: "Exp404",
              component: () =>
                import(
                  /* webpackChunkName: "exception" */ "@/pages/exception/404"
                ),
            },
            {
              path: "403",
              name: "Exp403",
              component: () =>
                import(
                  /* webpackChunkName: "exception" */ "@/pages/exception/403"
                ),
            },
            {
              path: "500",
              name: "Exp500",
              component: () =>
                import(
                  /* webpackChunkName: "exception" */ "@/pages/exception/500"
                ),
            },
            {
              path: "502",
              name: "Exp502",
              meta: {
                invisible: true,
              },
              component: () =>
                import(
                  /* webpackChunkName: "exception" */ "@/pages/exception/502"
                ),
            },
          ],
        },
      ],
    },
  ],
};

export default options;
