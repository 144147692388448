<template>
  <div class="box">
    <div class="tit" :class="[lang]" style="width: 500px">
      <svg-icon iconClass="search" class="tit-icon"></svg-icon
      ><span>{{ $t("capaginName") }}</span>
    </div>
    <div class="wrap-form" v-if="loaded">
      <a-form :form="form" class="form">
        <a-form-item :class="[lang]">
          <a-input
            size="large"
            ref="campaign_name"
            :maxLength="30"
            v-decorator="[
              'campaign_name',
              {
                initialValue: campName,
                rules: [
                  {
                    trim: true,
                    required: true,
                    validator: async function (rule, value, callback) {
                      if (!value || !value.trim('')) {
                        callback($t('Pleaseenterthecampaignname'));
                        return;
                      }
                      // 添加逻辑校验是否存在调接口
                      let res = await isCampaignNameLegal(value);
                      if (!res.reslut) {
                        callback(res.msg);
                        return;
                      }
                      callback();
                    },
                    trigger: 'blur',
                  },
                ],
                trigger: 'blur',
              },
            ]"
            :placeholder="$t('Pleaseenterthecampaignname')"
          />
        </a-form-item>
        <div class="tit m50" :class="[lang]" style="width: 500px">
          <svg-icon iconClass="bind" class="tit-icon"></svg-icon
          ><span :class="[lang]">{{ $t("bindLinkAcc") }}</span>
          <a-popover
            :placement="lang == 'AR' ? 'bottomRight' : 'bottomLeft'"
            style="z-index: 98"
            trigger="click"
            v-model="visiable"
          >
            <template slot="content">
              <div class="popbox">
                <p
                  class="main-tit"
                  :class="[lang]"
                  :style="{
                    'text-align': lang == 'AR' ? 'right' : 'left',
                  }"
                >
                  {{ $t("bindLinkAccTipTitle1") }}
                </p>
                <div
                  class="ptit"
                  :class="[lang]"
                  :style="{
                    'text-align': lang == 'AR' ? 'right' : 'left',
                  }"
                >
                  <span :class="[lang]">{{ $t("bindLinkAccTipcon1") }}</span>
                </div>

                <p
                  class="main-tit main-tit2"
                  :class="[lang]"
                  :style="{
                    'text-align': lang == 'AR' ? 'right' : 'left',
                  }"
                >
                  {{ $t("bindLinkAccTipTitle2") }}
                </p>
                <div
                  class="ptit"
                  :class="[lang]"
                  :style="{
                    'text-align': lang == 'AR' ? 'right' : 'left',
                  }"
                >
                  <div
                    :class="[lang]"
                    class="txt-red"
                    :style="{
                      'text-align': lang == 'AR' ? 'right' : 'left',
                    }"
                  >
                    {{ $t("bindLinkAccTipcon2") }}
                  </div>
                  <div
                    :class="[lang]"
                    class="txt-red"
                    :style="{
                      'text-align': lang == 'AR' ? 'right' : 'left',
                    }"
                  >
                    {{ $t("bindLinkAccTipcon3") }}
                  </div>
                </div>
                <div
                  class="btn-box"
                  :style="{
                    'text-align': lang == 'AR' ? 'right' : 'left',
                  }"
                >
                  <span :class="[lang]" class="btn-txt" @click="goLink">{{
                    $t("bindLinkAccTipBtn")
                  }}</span
                  ><span :class="[lang]">{{ $t("bindLinkAccTipBtnTxt") }}</span>
                </div>
              </div>
            </template>

            <a-icon
              type="question-circle"
              style="
                font-size: 14px;
                color: #0e756a;
                cursor: pointer;
                margin-left: 6px;
              "
            />
          </a-popover>
        </div>

        <div
          class="showInfo"
          v-if="detailObj.linkedin_id || (this.firstReg && new_linkedin_id)"
        >
          <ul>
            <li>
              <div class="lc" :class="[lang]">
                <svg-icon iconClass="email" class="l-icon"></svg-icon>
                <span class="t1" :class="[lang]"> {{ $t("Account") }}： </span>
                <span class="t2" :class="[lang]">{{
                  detailObj.linkedin_email
                    ? detailObj.linkedin_email
                    : formData.linkedin_email
                }}</span>
              </div>
            </li>
            <li>
              <div class="lc" :class="[lang]">
                <svg-icon iconClass="timezone" class="l-icon"></svg-icon>
                <span class="t1" :class="[lang]"> {{ $t("TimeZone") }}： </span>
                <span class="t2" :class="[lang]">{{
                  detailObj.time_zone
                    ? getTimez(detailObj.time_zone)
                    : getTimez(formData.timezone_config_id)
                }}</span>
              </div>
              <div class="rc">
                <svg-icon iconClass="right" class="r-icon"></svg-icon>
                {{ $t("Connected") }}
              </div>
            </li>
            <li style="margin-bottom: 0">
              <div class="lc" :class="[lang]">
                <svg-icon iconClass="global" class="l-icon"></svg-icon>
                <span class="t1" :class="[lang]"> {{ $t("Proxy") }}： </span>
                <span class="t2" :class="[lang]">{{
                  detailObj.proxy_manually
                    ? getcountry(detailObj.proxy_manually)
                    : getcountry(formData.country_config_id)
                }}</span>
              </div>
            </li>
          </ul>
        </div>
        <!--编辑-->
        <div class="wrap-link-list" v-if="!firstReg && !$route.query.edit">
          <a-form-item :class="[lang]">
            <a-radio-group
              v-decorator="[
                'link_id',
                {
                  initialValue: default_link_id,
                  rules: [
                    {
                      required: true,
                      message: $t('inputLinkAccount'),
                    },
                  ],
                },
              ]"
              @change="selectedRadio"
            >
              <ul>
                <li v-for="(item, index) in linkList" :key="index">
                  <label style="width: 100%">
                    <div class="text">
                      <svg-icon iconClass="email" class="icon"></svg-icon>
                      {{ item.linkedin_email }}
                    </div>
                    <a-radio :value="item.linkedin_id"> </a-radio>
                  </label>
                </li>
              </ul>
            </a-radio-group>
          </a-form-item>
        </div>
        <!--第一次创建link账户绑定-->
        <template
          v-if="$route.query.linkedin_id == 'new' && firstReg && !isClickNext"
        >
          <!-- <div class="subTitle">{{ $t("bindLinkAcc") }}</div> -->
          <a-form-item :class="[lang]">
            <div>
              <a-input
                style="height: 40px"
                size="large"
                class="input"
                @change="changeHandler1"
                v-decorator="[
                  'linkedin_email',
                  {
                    trim: true,
                    rules: [
                      {
                        required: true,
                        validator: function (rule, value, callback) {
                          let reg =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (!value || !value.trim('') || !reg.test(value)) {
                            callback($t('rightAccount'));
                            return;
                          }
                          callback();
                        },
                      },
                    ],
                  },
                ]"
                :placeholder="$t('linkEmal')"
                ><svg-icon iconClass="email" slot="prefix"></svg-icon
              ></a-input>
            </div>
          </a-form-item>
          <a-form-item :class="[lang]">
            <a-input-password
              style="height: 40px"
              size="large"
              class="input"
              type="linkedin_password"
              oninput="value=value.trim()"
              onchange="value=value.trim()"
              @change="changePassword"
              v-decorator="[
                'linkedin_password',
                {
                  trim: true,
                  rules: [
                    {
                      required: true,
                      message: $t('inputPwd'),
                    },
                  ],
                },
              ]"
              :placeholder="$t('linkPwd')"
            >
              <svg-icon iconClass="lock" slot="prefix"></svg-icon>
            </a-input-password>
          </a-form-item>
          <a-form-item :class="[lang]">
            <div class="wrap-select">
              <svg-icon iconClass="timezone" class="prefix"></svg-icon>
              <a-select
                size="large"
                class="input process-select"
                v-decorator="[
                  'timezone_config_id',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('selectTimezone'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('timeZone')"
              >
                <a-select-option
                  :class="[lang]"
                  v-for="(item, index) in TimeZoneList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.show_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-item>

          <a-form-item :class="[lang]">
            <div class="wrap-select">
              <svg-icon iconClass="global" class="prefix"></svg-icon>
              <a-select
                size="large"
                class="input process-select"
                :filter-option="filterOption"
                showSearch
                v-decorator="[
                  'country_config_id',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('selectRegion'),
                      },
                    ],
                  },
                ]"
                :placeholder="$t('SelectProxyRegion')"
              >
                <a-select-option
                  :class="[lang]"
                  v-for="(item, index) in locationList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.show_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item
            :style="{ 'text-align': lang == 'AR' ? 'right' : 'left' }"
          >
            <a-button
              :class="[lang]"
              size="large"
              type="primary"
              @click="formNextHandler"
              >{{ $t("NextStep") }}</a-button
            >
          </a-form-item>
        </template>
        <!-- 第一次绑定的过程中-->
        <template
          v-if="$route.query.linkedin_id == 'new' && processing && firstReg"
        >
          <div class="processing">
            <div class="cstep">
              <div class="number" v-if="processStep.step1 == 0">1</div>
              <svg-icon
                v-if="processStep.step1 == 1"
                iconClass="wrong"
                class="cicon"
              ></svg-icon>
              <svg-icon
                v-if="processStep.step1 == 2"
                iconClass="load"
                class="cicon rotating"
              ></svg-icon>
              <svg-icon
                v-if="processStep.step1 == 3"
                iconClass="right"
                class="cicon"
              ></svg-icon>
              <div
                class="text"
                :class="{
                  wrong: processStep.step1 == 1,
                  right: processStep.step1 == 2 || processStep.step1 == 3,
                }"
              >
                <span :class="[lang]">{{ $t("ObtainProxyIP") }}</span>
              </div>
            </div>
            <div class="splitbox getProxy">
              <div class="asset" v-if="processStep.step1 == 1">
                <a-form-item
                  style="width: 316px; margin-bottom: 0"
                  :class="[lang]"
                >
                  <div class="wrap-select">
                    <svg-icon iconClass="global" class="prefix"></svg-icon>
                    <a-select
                      size="large"
                      class="process-input process-select"
                      :filter-option="filterOption"
                      showSearch
                      v-decorator="[
                        'country_config_id',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('selectRegion'),
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('SelectProxyRegion')"
                    >
                      <a-select-option
                        :class="[lang]"
                        v-for="(item, index) in locationList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.show_name }}
                      </a-select-option>
                    </a-select>
                  </div>
                </a-form-item>
              </div>
            </div>
            <div class="cstep">
              <div class="number" v-if="processStep.step2 == 0">2</div>
              <svg-icon
                v-if="processStep.step2 == 1"
                iconClass="wrong"
                class="cicon"
              ></svg-icon>
              <svg-icon
                v-if="processStep.step2 == 2"
                iconClass="load"
                class="cicon rotating"
              ></svg-icon>
              <svg-icon
                v-if="processStep.step2 == 3"
                iconClass="right"
                class="cicon"
              ></svg-icon>
              <div
                class="text"
                :class="{
                  wrong: processStep.step2 == 1,
                  right: processStep.step2 == 2 || processStep.step2 == 3,
                }"
              >
                {{ $t("VerifyLink") }}
              </div>
            </div>
            <div class="splitbox getProxy">
              <div class="asset" v-if="processStep.step2 == 1">
                <a-form-item style="margin-bottom: 20px" :class="[lang]">
                  <a-input
                    size="large"
                    class="process-input"
                    @change="changeHandler1"
                    autocomplate="off"
                    v-decorator="[
                      'linkedin_email',
                      {
                        trim: true,
                        rules: [
                          {
                            required: true,
                            validator: function (rule, value, callback) {
                              let reg =
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                              if (
                                !value ||
                                !value.trim('') ||
                                !reg.test(value)
                              ) {
                                callback($t('rightAccount'));
                                return;
                              }
                              callback();
                            },
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('linkEmal')"
                  >
                    <svg-icon iconClass="email" slot="prefix"></svg-icon>
                  </a-input>
                </a-form-item>
                <a-form-item :class="[lang]">
                  <a-input-password
                    size="large"
                    class="process-input"
                    type="linkedin_password"
                    oninput="value=value.trim()"
                    onchange="value=value.trim()"
                    @change="changePassword"
                    v-decorator="[
                      'linkedin_password',
                      {
                        trim: true,
                        rules: [
                          {
                            required: true,
                            message: $t('inputPwd'),
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('linkPwd')"
                  >
                    <svg-icon iconClass="lock" slot="prefix"></svg-icon>
                  </a-input-password>
                </a-form-item>
              </div>
            </div>
            <div class="cstep">
              <div class="number" v-if="processStep.step3 == 0">3</div>
              <svg-icon
                v-if="processStep.step3 == 1"
                iconClass="wrong"
                class="cicon"
              ></svg-icon>
              <svg-icon
                v-if="processStep.step3 == 2"
                iconClass="load"
                class="cicon rotating"
              ></svg-icon>
              <svg-icon
                v-if="processStep.step3 == 3"
                iconClass="right"
                class="cicon"
              ></svg-icon>
              <div
                class="text"
                :class="{
                  wrong: processStep.step3 == 1,
                  right: processStep.step3 == 2 || processStep.step3 == 3,
                }"
              >
                <span :class="[lang]">{{ $t("LinkedInAccountLink") }}</span>
              </div>
            </div>
            <div
              class="splitbox getProxy"
              style="border-left: #fff solid 1px"
              v-if="processStep.step3 == 1"
            >
              <div class="asset">
                <div class="tips">
                  <a-icon
                    type="exclamation-circle"
                    style="margin-right: 12px; color: #ffa200"
                  />
                  <div>
                    <span :class="[lang]">{{ $t("bindTips") }}</span>
                  </div>
                </div>
                <a-form-item :class="[lang]">
                  <a-input
                    size="large"
                    class="process-input"
                    autocomplete="off"
                    v-decorator="[
                      'fa_code',
                      {
                        trim: true,
                        rules: [
                          {
                            required: true,
                            validator: function (rule, value, callback) {
                              if (!value || !value.trim('')) {
                                callback($t('inputCode'));
                                return;
                              }
                              callback();
                            },
                          },
                        ],
                      },
                    ]"
                    :placeholder="$t('inputCode')"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
          <div></div>
        </template>
        <a-form-item
          style="margin-top: 30px"
          :style="{ 'text-align': lang == 'AR' ? 'right' : 'left' }"
        >
          <a-button
            v-if="detailObj.linkedin_id || new_linkedin_id || !firstReg"
            size="large"
            type="primary"
            :class="[lang]"
            @click="nextHandler"
            >{{ $t("NextStep") }}</a-button
          >
          <a-button
            v-if="
              processing &&
              (processStep.step1 == 1 ||
                processStep.step2 == 1 ||
                processStep.step3 == 1) &&
              firstReg &&
              !$route.query.edit
            "
            size="large"
            type="primary"
            :class="[lang]"
            @click="saveHandler"
            >{{ $t("ContinueConnect") }}</a-button
          >
        </a-form-item>
      </a-form>
      <img src="@/assets/img/person.png" alt="" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    let that = this;
    return {
      isClickNext: false,
      processing: false,
      //保存缓存数据
      saveLocalObj: {
        processing: false,
        formData: {},
        processStep: {},
      },
      // 通过获取linkList 判断是不是第一次
      firstReg: false,
      switchVal: true,
      // 已链接的
      // linkList: [],
      new_linkedin_id: null,
      showStep3: false,
      campName: "",
      formData: {},
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      step: 1,
      tempLinkId: null,
      Pleaseenterthecampaignname: that.$t("Pleaseenterthecampaignname"),
      processStep: {
        step1: 0,
        step2: 0,
        step3: 0,
      },
      visiable: false,
      toPath: null,
    };
  },
  props: ["detailObj", "linkList", "loaded"],
  computed: {
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),
    default_link_id() {
      if (this.detailObj.link_id) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.new_linkedin_id = this.detailObj.link_id;
        return this.detailObj.link_id;
      } else if (this.linkList && this.linkList.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.new_linkedin_id = this.linkList[0].linkedin_id;
        return this.linkList[0].linkedin_id;
      } else {
        return null;
      }
    },
    fromType() {
      if (this.$route.path.indexOf("/hr/recruiter") > -1) {
        return "hr";
      }
      return "sales";
    },
    locaStep1TemDataName() {
      if (this.$route.path.indexOf("/hr/recruiter") > -1) {
        return "step1TemDataHr";
      }
      return "step1TemData";
    },
  },
  created() {
    this.getLinkList();
    this.getLocationList();
    this.getTimeZone();
    this.showStep1Popover();
  },
  i18n: require("../capaingni18n"),
  watch: {
    lang(val) {
      console.log("lange", val);
      this.getLocationList();
      this.getTimeZone();
      //   this.Pleaseenterthecampaignname = this.$t("Pleaseenterthecampaignname");
      this.form.validateFields(
        {
          force: true,
        },
        (err, values) => {
          console.log(err, values);
        }
      );
      this.$refs["campaign_name"].blur();
    },
    linkList() {
      this.getLinkList();
    },
  },
  methods: {
    ...mapMutations("setting", ["setDevice"]),
    getLinkList() {
      if (this.linkList.length > 0) {
        this.firstReg = false;
        return;
      } else {
        this.firstReg = true;
        let temData = localStorage.getItem(this.locaStep1TemDataName);
        if (temData) {
          this.isClickNext = true;
          let { step, processing, processStep, formData, tempLinkId } =
            JSON.parse(temData);
          let { step1, step2, step3 } = processStep;
          console.log(temData, step1, step2, step3);

          if (step1 == 2) {
            processStep.step1 = 1;
          }
          if (step2 == 2) {
            processStep.step2 = 1;
          }
          if (step3 == 2) {
            processStep.step3 = 1;
          }

          this.step = step;
          this.processing = processing;
          this.processStep = processStep;
          this.formData = formData;
          this.tempLinkId = tempLinkId;
          this.campName = formData.campaign_name;
        }
      }
      // this.$axios(
      //   "/linkedin/list",
      //   { campaign_type: 1, status: 1 },
      //   "post"
      // ).then((res) => {
      //   if (res.code == 200) {
      //     if (res.data && res.data.length > 0) {
      //       this.linkList = res.data;
      //       this.firstReg = false;
      //     } else {
      //       this.firstReg = true;
      //       let temData = localStorage.getItem("step1TemData");
      //       if (temData) {
      //         this.isClickNext = true;
      //         let { step, processing, processStep, formData, tempLinkId } =
      //           JSON.parse(temData);
      //         this.step = step;
      //         this.processing = processing;
      //         this.processStep = processStep;
      //         this.formData = formData;
      //         this.tempLinkId = tempLinkId;
      //         this.campName = formData.campaign_name;
      //       }
      //     }
      //   }
      // });
    },
    getcountry(id) {
      let name = "";
      this.locationList.some((item) => {
        if (item.id == id) {
          name = item.show_name;
          return true;
        } else {
          return false;
        }
      });
      return name;
    },
    getTimez(id) {
      let name = "";
      this.TimeZoneList.some((item) => {
        if (item.id == id) {
          name = item.show_name;
          return true;
        } else {
          return false;
        }
      });
      return name;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    selectedRadio(event) {
      this.new_linkedin_id = event.target.value;
    },
    changeHandler1() {
      this.new_linkedin_id = null;
    },
    setLocalData() {
      let formData = { ...this.formData };
      delete formData.campaign_name
      this.saveLocalObj = {
        processing: this.processing,
        formData: formData,
        processStep: this.processStep,
        tempLinkId: this.tempLinkId,
        step: this.step,
      };
      localStorage.setItem(
        this.locaStep1TemDataName,
        JSON.stringify(this.saveLocalObj)
      );
    },
    // 新用户昨时保存
    formNextHandler() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // this.isClickNext = true;
          // this.processing = true;
          console.log("setp---", this.step);
          this.formData = values;
          this.saveHandler();
        }
      });
    },
    step1(values) {
      return new Promise((resolve) => {
        this.processStep.step1 = 2;
        // let { linkedin_email } = values;
        this.$axios(
          "/linkedin/save_new_linkedin",
          {
            ...this.formData,
            ...values,
            // email: linkedin_email,
            proxy_manually: 1,
            campaign_type: this.fromType === "hr" ? 2 : 1,
          },
          "post"
        )
          .then((res) => {
            if (res.code == 200) {
              this.isClickNext = true;
              this.processing = true;
              this.processStep.step1 = 3;
              this.tempLinkId = res.data.new_linkedin_id;
              this.processStep.step2 = 1;
              this.getUserInfo();
              this.setLocalData();

              resolve(1);
            } else {
              this.processStep.step1 = 1;
              this.loading = false;
              // this.$message.error(res.msg);
              this.step = 1;
              resolve(2);
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      });
    },
    step2(values) {
      return new Promise((resolve) => {
        this.step = 2;
        this.processStep.step2 = 2;
        // if (this.tempLinkId && this.processStep.step2 == 2) {
        //   this.$axios(
        //     "/linkedin/update_linkedin",
        //     {
        //       ...values,
        //       linkedin_id: parseInt(this.tempLinkId),
        //       proxy_manually: 1,
        //     },
        //     "post"
        //   )
        //     .then((res) => {
        //       if (res.code == 200) {
        //         //
        //       } else {
        //         this.loading = false;
        //       }
        //     })
        //     .catch((e) => {
        //       console.log(e);
        //       this.loading = false;
        //     });
        // }
        this.$axios(
          "/linkedin/linkedin_login",
          {
            linkedin_id: parseInt(this.tempLinkId),
            ...this.formData,
            ...values,
          },
          "post"
        )
          .then((res2) => {
            if (res2.code == 200) {
              this.processStep.step2 = 3;
              this.new_linkedin_id = this.tempLinkId;
              this.$message.success(res2.msg);
              this.processStep.step3 = 2;
              this.loading = false;
              localStorage.removeItem(this.locaStep1TemDataName);
              this.getUserInfo();
              // 模拟第三步成功
              setTimeout(() => {
                this.processStep.step3 = 3;
                this.processing = false;

                setTimeout(() => {
                  this.firstReg = true;
                }, 200);
              }, 2000);
            } else if (res2.code == 201) {
              this.step = 3;
              this.processStep.step2 = 3;
              // 模拟第三步失败
              // this.$message.error(res2.msg);
              this.processStep.step3 = 1;
              this.loading = false;
              this.setLocalData();
            } else {
              this.step = 2;
              // 登录失败
              this.processStep.step2 = 1;
              this.loading = false;
              this.setLocalData();
            }
            resolve();
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
            this.processStep.step2 = 1;
            this.setLocalData();
          });
      });
    },
    step3(values) {
      return new Promise((resolve) => {
        let { fa_code } = values;
        this.processStep.step3 = 2;
        this.$axios(
          "/linkedin/linkedin_input",
          {
            fa_code: parseInt(fa_code),
            linkedin_id: this.tempLinkId,
          },
          "post"
        )
          .then((res) => {
            this.loading = false;

            if (res.code == 200) {
              this.$message.success(res.msg);
              this.processStep.step3 = 3;
              this.new_linkedin_id = this.tempLinkId;
              this.processing = false;
              localStorage.removeItem(this.locaStep1TemDataName);
              this.getUserInfo();
              setTimeout(() => {
                this.firstReg = true;
              }, 200);
            } else {
              // this.$message.error(res.msg);
              this.processStep.step3 = 1;
              this.setLocalData();
            }
            resolve();
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
            this.processStep.step3 = 1;
            this.setLocalData();
          });
      });
    },
    saveHandler() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.loading) {
            return;
          }
          this.loading = true;
          this.formData = {
            ...this.formData,
            ...values,
          }
          console.log('---saveHandler this-formdatq------', values, this.formData)
          if (this.step == 1) {
            this.step1(values).then((res) => {
              if (res == 1) {
                this.step = 2;
                this.setLocalData();
                this.step2(values);
              }
            });
          }
          if (this.step == 2) {
            this.isClickNext = true;
            this.processing = true;
            this.step2(values).then((res) => {
              console.log(res);
            });
          }
          if (this.step == 3) {
            this.isClickNext = true;
            this.processing = true;
            this.step3(values);
          }
        }
      });
    },
    nextHandler() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$emit("callback", {
            ...values,
            linkedin_id:
              this.$route.query.edit == "1"
                ? this.detailObj.linkedin_id
                : this.new_linkedin_id,
          });
        }
      });
    },
    // 获取当前是否打开第二步的下拉框
    showStep1Popover() {
      // 添加校验是否是第一次打开，如果是第一次打开，则弹出
      let iteMName =
        this.fromType == "hr"
          ? "HRBindLinkedInAccPopover"
          : "BindLinkedInAccPopover";
      const BindLinkedInAccPopover = localStorage.getItem(iteMName);
      console.log(iteMName, BindLinkedInAccPopover);
      if (BindLinkedInAccPopover) {
        this.visiable = false;
      } else {
        this.visiable = true;
        localStorage.setItem(iteMName, true);
      }
    },
    // hover弹框点击按钮进入文档详情页
    goLink() {
      window.open(
        "https://leadgeneration.tawk.help/article/linkedin-usage-restrictions-and-settings"
      );
    },
    // 校验任务名称是否合法
    async isCampaignNameLegal(val) {
      let resInfo = {};
      let url =
        this.fromType == "hr" ? "/recruiter/check_name" : "/sales/check_name";
      await this.$axios(
        url,
        {
          campaign_name: val,
          // linkedin_id: this.$route.query.edit == "1"
          //       ? this.detailObj.linkedin_id
          //       : this.new_linkedin_id
        },
        "post"
      ).then((res) => {
        resInfo = res && res.data;
      });
      return resInfo;
    },
    changePassword(e) {
      this.changeHandler1();
      let value = e.target.value.trim();
      this.$nextTick(() => {
        this.form.setFieldsValue({
          linkedin_password: value,
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url(../style/process.less);

.box {
  background: #fff;
  padding: 38px 30px;
  min-height: 700px;
  .tit {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin-bottom: 30px;
    .tit-icon {
      margin-right: 10px;
    }
  }
  .AR {
    .tit-icon {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .subTitle {
    font-family: Arial, Arial;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 21px;
  }
  .m50 {
    margin-top: 50px;
  }

  .searchSelected {
    display: flex;
    li {
      cursor: pointer;
      width: 414px;
      height: 170px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dfdfdf;
      margin-right: 30px;
      color: #666666;
      font-weight: bold;
      text-align: center;
      @media screen and (max-width: 1680px) {
        width: 314px;
      }
      .stit {
        font-size: 20px;
        margin-top: 33px;
      }
      .cont {
        margin-top: 35px;
        font-size: 16px;
        line-height: 24px;
      }
      position: relative;
      .checkbox {
        position: absolute;
        right: 8px;
        top: 8px;
        display: none;
      }
    }
    .selected {
      background: transparent;
      box-shadow: 0px 0px 14px 1px rgba(14, 117, 106, 0.14);
      border-radius: 4px 4px 4px 4px;
      border: 2px solid #0e756a;
      .checkbox {
        display: block;
      }
    }
  }
  .wrap-form {
    .form {
      width: 500px;
      position: relative;
      z-index: 1;
      /deep/.ant-input {
        background: transparent;
      }
    }
    position: relative;
    img {
      position: absolute;

      width: 295px;
      height: 228px;
      top: 30px;
      right: 155px;
    }
    @media screen and (max-width: 1680px) {
      img {
        width: 295px;
        height: 228px;
        top: 30px;
        right: 40px;
      }
    }
  }
  .switchbox {
    margin-bottom: 30px;
    .switch {
      margin-right: 11px;
    }
  }
  .connect {
    border: #0e756a solid 1px;
    color: #0e756a;
  }
  .m120 {
    margin-right: 120px;
  }

  /deep/.ant-form-item {
    margin-bottom: 20px;
  }
  // /deep/.ant-input::placeholder {
  //   color: #999999 !important;
  //   background: #dfdfdf;
  // }
  // /deep/.ant-input:placeholder-shown {
  //   text-overflow: ellipsis;
  //   background: #dfdfdf;
  // }
  .input {
    width: 500px;
  }
  .wrap-link-list {
    li {
      background: transparent;
    }
  }
  .showInfo {
    background: transparent;
  }
}
.popbox {
  width: 480px;
  .main-tit {
    font-weight: bold;
    font-size: 16px;
    color: #0e756a;
    margin-bottom: 0;
    margin-top: 17px;
  }
  .ptit {
    margin-top: 17px;
    font-size: 14px;
    color: #000000;
    user-select: none;
    .rtl {
      margin-right: 0px;
      margin-left: 7px;
    }
    .txt-red {
      color: #ff4d4f;
    }
  }
  .btn-box {
    margin-top: 34px;
    color: #000;
    .btn-txt {
      color: #0e756a;
      cursor: pointer;
    }
  }
}
</style>
