var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { size: "large", spinning: _vm.loading } },
    [
      _c("div", { staticClass: "home" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("div", { staticClass: "btn", on: { click: _vm.addNewDialog } }, [
              _vm._v("+" + _vm._s(_vm.$t("NewDialogue"))),
            ]),
            _vm.sessionList.length > 0
              ? _c("div", { staticClass: "tit" }, [
                  _vm._v(" " + _vm._s(_vm.$t("RecentDialogue")) + " "),
                ])
              : _vm._e(),
            _c(
              "simplebar",
              {
                staticClass: "simplebarWrapLeft",
                style: {
                  maxHeight: _vm.maxLeftHeight + "px",
                  width: "242px",
                },
                attrs: {
                  "data-simplebar-auto-hide": "true",
                  scrollbarMaxSize: 50,
                },
              },
              [
                _c(
                  "ul",
                  _vm._l(_vm.sessionList, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.showDialog(item)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon",
                          attrs: { iconClass: "qibao" },
                        }),
                        _c("span", [_vm._v(_vm._s(item.session_name))]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "right" }, [
          _vm.startTalk
            ? _c("div", { staticClass: "wrap-mail" }, [
                _c(
                  "span",
                  {
                    staticClass: "sendMail",
                    on: { click: _vm.sendEmailHandler },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "icon",
                      attrs: { iconClass: "mail" },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("sendMail"))),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialogWind",
              style: {
                maxHeight: _vm.maxWindowHeight + "px",
                minHeight: _vm.maxWindowHeight + "px",
              },
            },
            [
              _c(
                "simplebar",
                {
                  ref: "scrollContainer",
                  staticClass: "simplebarWrap-dialog",
                  style: {
                    maxHeight: _vm.maxWindowHeight + "px",
                    width: _vm.width,
                  },
                  attrs: {
                    "data-simplebar-auto-hide": "true",
                    scrollbarMaxSize: 50,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      !_vm.startTalk
                        ? [
                            _c(
                              "div",
                              { staticClass: "guide" },
                              [
                                _c("div", { staticClass: "tit" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Hello")) +
                                      ", " +
                                      _vm._s(_vm.user.user_name)
                                  ),
                                ]),
                                _c("div", { staticClass: "small" }, [
                                  _vm._v(_vm._s(_vm.$t("helpYou"))),
                                ]),
                                _c("div", { staticClass: "tit2" }, [
                                  _vm._v(_vm._s(_vm.$t("askMeQs"))),
                                ]),
                                _vm._l(
                                  _vm.assisData.question,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "hrask",
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectHandler(item, 1)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.prompt) + " ")]
                                    )
                                  }
                                ),
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/talk.png"),
                                  },
                                }),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "guide2" }, [
                              _c("div", { staticClass: "tit" }, [
                                _vm._v(" " + _vm._s(_vm.$t("askMeTask")) + " "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "boxList" },
                                [
                                  _c(
                                    "a-row",
                                    {
                                      staticClass: "item",
                                      attrs: { gutter: 20 },
                                    },
                                    [
                                      _vm._l(
                                        _vm.assisData.follow_task,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "a-col",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      index < 5 || _vm.showMore,
                                                    expression:
                                                      "index < 5 || showMore",
                                                  },
                                                ],
                                                key: index,
                                                attrs: { span: 8 },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "unit",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectHandler(
                                                          item,
                                                          2
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "icon",
                                                      attrs: {
                                                        iconClass: "question",
                                                      },
                                                    }),
                                                    _c("p", [
                                                      _vm._v(_vm._s(item.desc)),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        }
                                      ),
                                      _vm.assisData.follow_task.length > 5 &&
                                      !_vm.showMore
                                        ? _c("a-col", { attrs: { span: 8 } }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "unit",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showMore = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticClass: "icon",
                                                  attrs: { iconClass: "more" },
                                                }),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("moreClick"))
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _c("div", { staticClass: "talkWind" }, [
                            _c("div", { staticClass: "wrap-message" }, [
                              _c(
                                "ul",
                                [
                                  void 0,
                                  _vm._l(
                                    _vm.dialogList,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          class: {
                                            rightTalk:
                                              item.role_type == 1
                                                ? true
                                                : false,
                                            leftTalk:
                                              item.role_type == 0
                                                ? true
                                                : false,
                                          },
                                        },
                                        [
                                          item.type == "date"
                                            ? _c(
                                                "div",
                                                { staticClass: "date" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.date) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.role_type == 1
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "box" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "wrap-info",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "messageText",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    item.content
                                                                  ),
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "time",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatTimeZoneTime(
                                                                        item.create_date,
                                                                        "HH:mm"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.user.head_img,
                                                        alt: _vm.user.user_name,
                                                      },
                                                      on: {
                                                        error: function (
                                                          $event
                                                        ) {
                                                          return _vm.imgError(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("div", {
                                                  staticStyle: {
                                                    clear: "both",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          item.role_type == 0 &&
                                          item.parse_cate == "z"
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "box" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/robot.png"),
                                                        alt: "",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wrap-info",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "message",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        item.content
                                                                      ),
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "time",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.formatTimeZoneTime(
                                                                            item.create_date,
                                                                            "HH:mm"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          item.questionList,
                                                          function (
                                                            ditem,
                                                            dindex
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: dindex,
                                                                staticClass:
                                                                  "question-ext",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.questionHandler(
                                                                        ditem.question
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      ditem.question
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "icon",
                                                                  attrs: {
                                                                    type: "right",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                                _c("div", {
                                                  staticStyle: {
                                                    clear: "both",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          item.role_type == 0 &&
                                          item.parse_cate != "z"
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "box" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/robot.png"),
                                                        alt: "",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "wrap-info",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "message",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c("div", {
                                                                staticClass:
                                                                  "answerContent",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      item.answer_content
                                                                    ),
                                                                },
                                                              }),
                                                              _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.reviewHandler(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "review"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.file_name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "time",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatTimeZoneTime(
                                                                        item.create_date,
                                                                        "HH:mm"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("div", {
                                                  staticStyle: {
                                                    clear: "both",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  _vm.useWs
                                    ? _c("li", { staticClass: "leftTalk" }, [
                                        _c("div", { staticClass: "box" }, [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/robot.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "wrap-info" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "message thinking",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "rotationtiao_left",
                                                      staticStyle: {
                                                        "margin-bottom": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gpt-flex-row",
                                                        },
                                                        [
                                                          _c("a-icon", {
                                                            staticClass:
                                                              "rightIcon",
                                                            attrs: {
                                                              type: "check",
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "thinking"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gpt-flex-row",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _c("a-icon", {
                                                            staticClass:
                                                              "rightIcon",
                                                            attrs: {
                                                              type: "check",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "newLine",
                                                              staticStyle: {
                                                                width: "400px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Start Answering: " +
                                                                  _vm._s(
                                                                    _vm.content_chat
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("div", {
                                                        staticClass:
                                                          "messageContent",
                                                        attrs: {
                                                          id: "messageContent",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.sending
                                    ? _c("li", { staticClass: "leftTalk" }, [
                                        _c("div", { staticClass: "box" }, [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/robot.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "wrap-info" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "message thinking",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "text" },
                                                    [_vm._v("thinking")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "loading-chat",
                                                    },
                                                    [
                                                      _c("div"),
                                                      _c("div"),
                                                      _c("div"),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", {
                                          staticStyle: { clear: "both" },
                                        }),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "bottom" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFinish,
                    expression: "showFinish",
                  },
                ],
                staticStyle: { "padding-top": "26px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "finish",
                    class: { disabled: _vm.sending },
                    on: { click: _vm.finishHandler },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("FinishCeneration")) + " ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFinish,
                    expression: "showFinish",
                  },
                ],
                staticStyle: { "padding-top": "26px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "process" },
                  [
                    _c("a-icon", {
                      staticStyle: {
                        "font-size": "12px",
                        color: "#ffa200",
                        "margin-right": "5px",
                      },
                      attrs: { type: "exclamation-circle" },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("flowTsk")) + " "),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showFinish && _vm.startTalk,
                    expression: "!showFinish && startTalk",
                  },
                ],
                staticStyle: { "padding-top": "26px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "process" },
                  [
                    _c("a-icon", {
                      staticStyle: {
                        "font-size": "12px",
                        color: "#ffa200",
                        "margin-right": "5px",
                      },
                      attrs: { type: "exclamation-circle" },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("QaProcess")) + " "),
                  ],
                  1
                ),
              ]
            ),
            !_vm.showTempList
              ? _c(
                  "div",
                  { staticClass: "wrap-list" },
                  [
                    _c(
                      "simplebar",
                      {
                        staticClass: "simplebarWrap",
                        style: {
                          maxHeight: 50 + "px",
                          width: _vm.width,
                        },
                        attrs: {
                          "data-simplebar-auto-hide": "true",
                          scrollbarMaxSize: 50,
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "list" },
                          _vm._l(
                            _vm.assisData.follow_task,
                            function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectHandler(item, 2)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item",
                                      class: { disabled: _vm.sending },
                                    },
                                    [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(_vm._s(item.desc)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showTempList && _vm.tempList.length > 0
              ? _c(
                  "div",
                  { staticClass: "wrap-list" },
                  [
                    _c(
                      "simplebar",
                      {
                        ref: "scrollContainer",
                        staticClass: "simplebarWrap",
                        style: {
                          maxHeight: 50 + "px",
                          width: _vm.width,
                        },
                        attrs: {
                          "data-simplebar-auto-hide": "true",
                          scrollbarMaxSize: 50,
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "list" },
                          _vm._l(_vm.tempList, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.selectHandler(item, 2)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "item",
                                    class: { disabled: _vm.sending },
                                  },
                                  [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.desc)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "messageBox",
                style: {
                  width: _vm.width,
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { iconClass: "message" },
                }),
                _c(
                  "div",
                  { staticClass: "text", class: { noclick: _vm.noclick } },
                  [
                    !_vm.noclick
                      ? _c("div", {
                          ref: "content",
                          staticClass: "textCont",
                          staticStyle: {
                            "max-height": "210px",
                            "overflow-y": "auto",
                          },
                          domProps: { innerHTML: _vm._s(_vm.inputData) },
                          on: { click: _vm.editSpan, blur: _vm.blurHandler },
                        })
                      : _c("a-input", {
                          staticClass: "textCont",
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            placeholder: _vm.$t("enterQs"),
                            autosize: { minRows: 2, maxRows: 10 },
                            type: "textarea",
                          },
                          on: { input: _vm.changeHandler },
                          model: {
                            value: _vm.messageInfo,
                            callback: function ($$v) {
                              _vm.messageInfo = $$v
                            },
                            expression: "messageInfo",
                          },
                        }),
                  ],
                  1
                ),
                _c("a-icon", {
                  staticStyle: { "margin-left": "10px", cursor: "pointer" },
                  attrs: { type: "close-circle" },
                  on: { click: _vm.clearHandler },
                }),
                _c(
                  "span",
                  { on: { click: _vm.sendHandler } },
                  [
                    !_vm.sending && !_vm.btn_loading
                      ? _c("svg-icon", {
                          staticClass: "send",
                          attrs: { iconClass: "send" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.sending || _vm.btn_loading
                  ? _c(
                      "div",
                      {
                        staticClass: "sending",
                        style: {
                          cursor: _vm.btn_loading ? "pointer" : "default",
                        },
                        on: { click: _vm.stopAnswer },
                      },
                      [_c("div", { staticClass: "circle" })]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm.showSendEmail
          ? _c(
              "div",
              {
                staticClass: "richEdit",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "a-form",
                  {
                    staticClass: "form",
                    attrs: { form: _vm.form, layout: "horizontal" },
                  },
                  [
                    _c("Toolbar", {
                      ref: "toolbar",
                      attrs: {
                        editor: _vm.editor,
                        defaultConfig: _vm.toolbarConfig,
                      },
                    }),
                    _c("Editor", {
                      staticStyle: {
                        "overflow-y": "hidden",
                        background: "#f6f6f6",
                      },
                      style: { height: _vm.user.email ? "550px" : "490px" },
                      attrs: { defaultConfig: _vm.editorConfig },
                      on: { onChange: _vm.onChange, onCreated: _vm.onCreated },
                      model: {
                        value: _vm.html,
                        callback: function ($$v) {
                          _vm.html = $$v
                        },
                        expression: "html",
                      },
                    }),
                    _c(
                      "a-form-item",
                      {
                        class: [_vm.lang],
                        staticStyle: {
                          "margin-bottom": "10px",
                          "margin-top": "16px",
                        },
                      },
                      [
                        !_vm.user.email
                          ? _c(
                              "a-input",
                              {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "email",
                                      {
                                        trim: true,
                                        rules: [
                                          {
                                            required: true,
                                            validator: function (
                                              rule,
                                              value,
                                              callback
                                            ) {
                                              let reg =
                                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                              if (
                                                !value ||
                                                !value.trim("") ||
                                                !reg.test(value)
                                              ) {
                                                callback(_vm.$t("inputMaile"))
                                                return
                                              }
                                              callback()
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n              'email',\n              {\n                trim: true,\n                rules: [\n                  {\n                    required: true,\n                    validator: function (rule, value, callback) {\n                      let reg =\n                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/;\n                      if (!value || !value.trim('') || !reg.test(value)) {\n                        callback($t('inputMaile'));\n                        return;\n                      }\n                      callback();\n                    },\n                  },\n                ],\n              },\n            ]",
                                  },
                                ],
                                staticClass: "process-input",
                                attrs: {
                                  size: "large",
                                  placeholder: _vm.$t("inputMaile"),
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { slot: "prefix", iconClass: "email" },
                                  slot: "prefix",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "sendBtn",
                        on: { click: _vm.sendEmileHandler },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("sendMail")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.showSendEmail ? _c("div", { staticClass: "mailMask" }) : _vm._e(),
      ]),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.$t("inputMaile"), "on-ok": "handleOk" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-form",
            {
              staticClass: "form",
              attrs: { form: _vm.form2, layout: "horizontal" },
            },
            [
              _c(
                "a-form-item",
                {
                  class: [_vm.lang],
                  staticStyle: {
                    "margin-bottom": "10px",
                    "margin-top": "16px",
                  },
                },
                [
                  _c(
                    "a-input",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "email",
                            {
                              trim: true,
                              rules: [
                                {
                                  required: true,
                                  validator: function (rule, value, callback) {
                                    let reg =
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                    if (
                                      !value ||
                                      !value.trim("") ||
                                      !reg.test(value)
                                    ) {
                                      callback(_vm.$t(_vm.$t("inputMaile")))
                                      return
                                    }
                                    callback()
                                  },
                                },
                              ],
                            },
                          ],
                          expression:
                            "[\n            'email',\n            {\n              trim: true,\n              rules: [\n                {\n                  required: true,\n                  validator: function (rule, value, callback) {\n                    let reg =\n                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/;\n                    if (!value || !value.trim('') || !reg.test(value)) {\n                      callback($t($t('inputMaile')));\n                      return;\n                    }\n                    callback();\n                  },\n                },\n              ],\n            },\n          ]",
                        },
                      ],
                      staticClass: "process-input",
                      attrs: {
                        size: "large",
                        placeholder: _vm.$t("inputMaile"),
                      },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { slot: "prefix", iconClass: "email" },
                        slot: "prefix",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary", size: "large" },
                  on: { click: _vm.handleOk },
                },
                [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }