var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "exception-page" }, [
    _c("div", { staticClass: "img" }, [
      _c("img", { attrs: { src: _vm.config[_vm.type].img } }),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("h1", [_vm._v(_vm._s(_vm.config[_vm.type].title))]),
      _c("div", { staticClass: "desc" }, [
        _vm._v(_vm._s(_vm.config[_vm.type].desc)),
      ]),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.backHome } },
            [_vm._v(_vm._s(_vm.$t("backhome")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }