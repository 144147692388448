var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { size: "large", spinning: _vm.loading } },
    [
      _c("common-layout", [
        _c("div", { staticClass: "main-login" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "left-wrap" }, [
              _c(
                "div",
                { staticClass: "header" },
                [
                  _c("svg-icon", {
                    staticClass: "logo",
                    staticStyle: {
                      width: "309px",
                      height: "44px",
                      background: "none",
                    },
                    attrs: { iconClass: "login" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "left-img" },
                [
                  _c(
                    "a-carousel",
                    { attrs: { autoplay: "", dotsClass: "dots" } },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: { src: require("@/assets/img/login1.png") },
                        }),
                      ]),
                      _c("div", [
                        _c("img", {
                          attrs: { src: require("@/assets/img/login2.png") },
                        }),
                      ]),
                      _c("div", [
                        _c("img", {
                          attrs: { src: require("@/assets/img/login3.png") },
                        }),
                      ]),
                      _c("div", [
                        _c("img", {
                          attrs: { src: require("@/assets/img/login4.png") },
                        }),
                      ]),
                      _c("div", [
                        _c("img", {
                          attrs: { src: require("@/assets/img/login5.png") },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "right" }, [
            !_vm.loinFail
              ? _c("div", { staticClass: "login" }, [
                  _c("div", { staticClass: "title", class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("StartTrial"))),
                  ]),
                  _c("div", { staticClass: "subTitle", class: [_vm.lang] }, [
                    _vm._v(" " + _vm._s(_vm.$t("try10DaysFree")) + " "),
                  ]),
                  _c("div", { staticClass: "auth" }, [
                    _c("ul", [
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.author("google-oauth2")
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { iconClass: "google" } }),
                          _c("span", { class: [_vm.lang] }, [
                            _vm._v(_vm._s(_vm.$t("SigninwithGoogle"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.author("linkedin")
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { iconClass: "linkin" } }),
                          _c("span", { class: [_vm.lang] }, [
                            _vm._v(_vm._s(_vm.$t("SigninwithLinkedIn"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.author("facebook")
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { iconClass: "facebook" } }),
                          _c("span", { class: [_vm.lang] }, [
                            _vm._v("facebook"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.loinFail
              ? _c("div", { staticClass: "fail" }, [
                  _c(
                    "div",
                    { staticClass: "error" },
                    [
                      _c("svg-icon", {
                        staticClass: "loginError",
                        attrs: { iconClass: "loginError" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "login-text", class: [_vm.lang] }, [
                    _vm._v(" " + _vm._s(_vm.$t("Loginfailed")) + " "),
                  ]),
                  _vm.loginInfo.msg
                    ? _c(
                        "div",
                        { staticClass: "eror-code", class: [_vm.lang] },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("Errorcode")) +
                              "：" +
                              _vm._s(_vm.loginInfo.msg) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showError
                    ? _c(
                        "div",
                        { staticClass: "eror-code", class: [_vm.lang] },
                        [_vm._v(" " + _vm._s(_vm.$t("authorization")) + " ")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "retry", class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("Youcantry"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn relogin",
                      class: [_vm.lang],
                      on: { click: _vm.reLogin },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "icon",
                        attrs: { iconClass: "restart-line" },
                      }),
                      _vm._v(_vm._s(_vm.$t("Logagain")) + " "),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "btn concatus" }, [
                    _c(
                      "a",
                      {
                        class: [_vm.lang],
                        attrs: {
                          href: "https://tawk.to/chat/67626c9aaf5bfec1dbddbfc4/1ifc7gf7c",
                          target: "_blank",
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon",
                          attrs: { iconClass: "concat" },
                        }),
                        _vm._v(_vm._s(_vm.$t("Emailus"))),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "wrap-code" }, [
                    _c("div", { staticClass: "qrcode" }, [
                      _c("img", {
                        attrs: {
                          src: "https://u-img.strategybrain.ca/images/static/linkedIn.jpg",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _c("span", [_vm._v("Linkedln")]),
                      ]),
                    ]),
                    _c("div", { staticClass: "qrcode" }, [
                      _c("img", {
                        attrs: {
                          src: "https://u-img.strategybrain.ca/images/static/whatsapp.jpg",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _c("span", [_vm._v("Whats App")]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "qus", class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("problem"))),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visiable,
                expression: "visiable",
              },
            ],
            staticClass: "mask",
          },
          [
            _c("div", { staticClass: "dialog" }, [
              _c("div", { staticClass: "loading" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/login_loading.gif") },
                }),
                _vm._v('" '),
              ]),
              _c("div", { staticClass: "text", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("loadingSpace")) + ".."),
              ]),
              _c(
                "div",
                { staticClass: "slide-wrap" },
                [
                  _c(
                    "a-carousel",
                    { attrs: { autoplay: "", dotsClass: "dots" } },
                    [
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("Afterlogging")) + " "),
                      ]),
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("Whencreating")) + " "),
                      ]),
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("Youcancreate")) + " "),
                      ]),
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("Settingsearch")) + " "),
                      ]),
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("Themore")) + " "),
                      ]),
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("Enteras")) + " "),
                      ]),
                      _c("div", { staticClass: "dtext", class: [_vm.lang] }, [
                        _vm._v(" " + _vm._s(_vm.$t("ForLinkedIn")) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }