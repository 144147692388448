<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <div class="left">
        <div class="tit">
          <span :class="[lang]">{{ $t("LinkedInAccountLinked") }}</span>
        </div>
        <ul class="list">
          <li
            v-for="(item, index) in linkList"
            :key="index"
            @click="selectedHandler(index, item)"
            :class="{ selected: selectedIndex == index }"
          >
            <img v-if="item.head_img" :src="item.head_img" />
            <img v-else src="@/assets/img/avat.png" />
            <div class="info">
              <div class="email ellipsis" :title="item.linkedin_email">{{ item.linkedin_email }}</div>
              <div class="infotxt">
                <span class="label" :class="[lang]"
                  >{{ $t("SubscriptionPlan") }}:</span
                >
                <span class="cont" :class="[lang]">{{
                  item.version_name
                }}</span>
              </div>
            </div>
          </li>
        </ul>
        <!-- <div @click="addItem" class="btn" :class="[lang]">
          {{ $t("AddLinkedInAccount") }}
        </div> -->
      </div>
      <div class="right">
        <ul class="nav">
          <li
            class="leftBtn"
            @click="tabHandler('left')"
            :class="{ active: leftActive }"
          >
            <span :class="[lang]">{{ $t("LinkedInAccounts") }}</span>
          </li>
          <li
            class="rightBtn"
            @click="tabHandler('right')"
            :class="{ active: rightActive }"
          >
            <span :class="[lang]">{{ $t("SubscriptionDetails") }}</span>
          </li>
        </ul>
        <div class="u-box">
          <div class="haveNoData" v-if="!haveClicked && haveNoData">
            <NoData :lang="lang" :text="$t('NoLinkedInAccountsAdded')" />
          </div>
          <template v-else>
            <div class="wrap-form" v-show="leftActive">
              <a-form :form="form" class="form">
                <template v-if="!showStep3">
                  <div class="subTitle" :class="[lang]">
                    {{ $t("bindLinkAcc") }}
                  </div>
                  <a-form-item :class="[lang]">
                    <a-input
                      size="large"
                      class="input"
                      :disabled="edit"
                      autocomplete="off"
                      v-decorator="[
                        'linkedin_email',
                        {
                          initialValue: selectedObj.linkedin_email,
                          rules: [
                            {
                              required: true,
                              validator: function (rule, value, callback) {
                                let reg =
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                if (
                                  !value ||
                                  !value.trim('') ||
                                  !reg.test(value)
                                ) {
                                  callback($t('rightAccount'));
                                  return;
                                }
                                callback();
                              },
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('linkEmal')"
                    />
                  </a-form-item>
                  <a-form-item
                    :class="[lang]"
                    v-if="!edit || selectedObj.status == 0"
                  >
                    <a-input-password
                      size="large"
                      class="input"
                      type="linkedin_password"
                      autocomplete="off"
                      @change="changePassword"
                      v-decorator="[
                        'linkedin_password',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('inputPwd'),
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('linkPwd')"
                    />
                  </a-form-item>
                  <a-form-item :class="[lang]">
                    <a-select
                      size="large"
                      class="input"
                      v-decorator="[
                        'timezone_config_id',
                        {
                          initialValue: selectedObj.timezone_config_id,
                          rules: [
                            {
                              required: true,
                              message: $t('selectTimezone'),
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('timeZone')"
                    >
                      <a-select-option
                        :class="[lang]"
                        v-for="(item, index) in TimeZoneList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.show_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <div class="subTitle" :class="[lang]">
                    {{ $t("ConfigureLinkedInProx") }}
                  </div>
                  <div class="switchbox" style="display: none">
                    <span>
                      <a-switch
                        class="switch"
                        size="small"
                        v-model="switchVal"
                      />{{ $t("ManualConfiguration") }}
                    </span>
                  </div>
                  <a-form-item
                    :class="[lang]"
                    v-if="switchVal || selectedObj.country_config_id"
                  >
                    <a-select
                      size="large"
                      class="input"
                      :filter-option="filterOption"
                      showSearch
                      v-decorator="[
                        'country_config_id',
                        {
                          initialValue: selectedObj.country_config_id,
                          rules: [
                            {
                              required: true,
                              message: $t('selectRegion'),
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('SelectProxyRegion')"
                    >
                      <a-select-option
                        :class="[lang]"
                        v-for="(item, index) in locationList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.show_name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </template>
                <template v-else>
                  <div class="tips">
                    <a-icon
                      type="exclamation-circle"
                      style="margin-right: 12px; color: #ffa200"
                    /><span :class="[lang]">{{ $t("bindTips") }}</span>
                  </div>
                  <a-form-item :class="[lang]">
                    <a-input
                      size="large"
                      class="input"
                      autocomplete="off"
                      v-decorator="[
                        'fa_code',
                        {
                          // initialValue: selectedObj.linkedin_email,
                          rules: [
                            {
                              required: true,
                              validator: function (rule, value, callback) {
                                if (!value || !value.trim('')) {
                                  callback($t('inputCode'));
                                  return;
                                }
                                callback();
                              },
                            },
                          ],
                        },
                      ]"
                      :placeholder="$t('inputCode')"
                    />
                  </a-form-item>
                </template>

                <a-form-item
                  :style="{ 'text-align': lang == 'AR' ? 'right' : 'left' }"
                >
                  <a-button
                    @click="saveHandler"
                    :class="[lang]"
                    size="large"
                    type="primary"
                    >{{ $t("Save") }}</a-button
                  >

                  <!-- <a-button
                    class="m120"
                    @click="test"
                    size="large"
                    type="primary"
                    >test</a-button
                  > -->
                </a-form-item>
              </a-form>
            </div>
            <div class="subsribe" v-show="rightActive">
              <ul>
                <li>
                  <div class="label">
                    <svg-icon iconClass="plan" class="licon"></svg-icon>
                    <span :class="[lang]">{{ $t("SubscriptionPlan") }}</span
                    >:
                  </div>
                  <div
                    class="cont bold"
                    v-if="
                      selectedObj.version_name !== undefined &&
                      selectedObj.version_name !== null
                    "
                    :class="[lang]"
                  >
                    {{ selectedObj.version_name }}
                  </div>
                  <div v-else class="cont bold">--</div>
                </li>
                <li>
                  <div class="label">
                    <svg-icon iconClass="link" class="licon"></svg-icon>
                    <span :class="[lang]">{{
                      $t("SubscriptionActivationTime")
                    }}</span
                    >：
                  </div>
                  <div class="cont bold" :class="[lang]">
                    {{
                      selectedObj.connect_time
                        ? format(selectedObj.connect_time, $t("bindTime"))
                        : "--"
                    }}
                  </div>
                </li>
                <li>
                  <div class="label">
                    <svg-icon iconClass="ready" class="licon"></svg-icon>
                    <span :class="[lang]">{{ $t("Status") }}</span
                    >：
                  </div>
                  <div
                    class="cont"
                    v-if="
                      selectedObj.expiration_time && selectedObj.status == 1
                    "
                  >
                    <!-- <span class="status active">{{
                      selectedObj.is_expired == 0
                        ? "Active"
                        : selectedObj.is_expired == 1
                        ? "End"
                        : ""
                    }}</span> -->
                    <span class="time" :class="[lang]">
                      <!-- (Expires on {{ selectedObj.expiration_time }}) -->
                      {{
                        selectedObj.connect_time
                          ? format(
                              selectedObj.expiration_time,
                              $t("activeTime")
                            )
                          : "--"
                      }}
                    </span>
                  </div>
                  <div class="cont" v-else>--</div>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      leftActive: true,
      rightActive: false,
      switchVal: true,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      haveNoData: true,
      linkList: [],
      selectedObj: {},
      edit: false,
      loading: false,
      haveClicked: false,
      tmpLinkid: null,
      showStep3: false,
    };
  },
  watch: {
    lang(val) {
      console.log("lange", val);
      this.getLocationList();
      this.getTimeZone();
    },
  },
  computed: {
    numLg() {
      return this.num.toString().length;
    },
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),
  },
  i18n: require("./i18n"),
  mounted() {},
  created() {},
  activated() {
    this.initData();
  },
  methods: {
    initData() {
      this.getLocationList();
      this.getTimeZone();
      this.getLinkList();
    },
    ...mapMutations("setting", ["setDevice"]),
    test() {
      this.$axios(
        "/linkedin/linkedin_input",
        {
          fa_code: 248988,
          linkedin_id: 287,
        },
        "post"
      )
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.getLinkList();
            this.showStep3 = false;
            //
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    format(date, value) {
      let formatDate = dayjs(date).format("YYYY-MM-DD");
      let arr = formatDate.split("-");
      arr.forEach((item) => {
        value = value.replace("[]", item);
      });
      return value;
    },
    tabHandler(type) {
      if (type == "left") {
        this.leftActive = true;
        this.rightActive = false;
      } else {
        this.leftActive = false;
        this.rightActive = true;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    selectedHandler(index, item) {
      this.edit = true;
      this.selectedIndex = index;
      this.selectedObj = item;
      let {
        proxy_manually,
        country_config_id,
        timezone_config_id,
        linkedin_email,
      } = item;
      this.showStep3 = false;
      this.switchVal = proxy_manually == 1 ? true : false;
      this.form.setFieldsValue({
        country_config_id,
        timezone_config_id,
        linkedin_email,
      });
    },
    addItem() {
      this.edit = false;
      this.selectedIndex = -1;
      this.selectedObj = {};
      this.haveClicked = true;
      this.showStep3 = false;
      this.switchVal = true;
      this.form.setFieldsValue({
        linkedin_email: null,
        linkedin_password: null,
        country_config_id: undefined,
        timezone_config_id: undefined,
      });
    },
    saveHandler() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          if (!this.showStep3) {
            if (this.edit) {
              let { linkedin_id, status } = this.selectedObj;
              console.log(status);
              this.$axios(
                "/linkedin/update_linkedin",
                {
                  ...values,
                  linkedin_id,
                  proxy_manually: this.switchVal ? 1 : 0,
                },
                "post"
              )
                .then((res) => {
                  this.loading = false;
                  if (res.code == 200) {
                    this.getLinkList();
                    // if (status == 1) {
                    //   this.getLinkList();
                    // } else {
                    //   this.$axios(
                    //     "/linkedin/linkedin_login",
                    //     {
                    //       linkedin_id,
                    //     },
                    //     "post"
                    //   )
                    //     .then((res2) => {
                    //       this.tmpLinkid = linkedin_id;
                    //       this.loading = false;
                    //       if (res2.code == 200) {
                    //         this.getLinkList();
                    //         this.$message.success(res2.msg);
                    //       } else if (res2.code == 201) {
                    //         this.$message.error(res2.msg);
                    //         this.showStep3 = true;
                    //       }
                    //     })
                    //     .catch((e) => {
                    //       console.log(e);
                    //       this.loading = false;
                    //     });
                    // }

                    //
                  }
                })
                .catch((e) => {
                  console.log(e);
                  this.loading = false;
                });
            } else {
              // let { linkedin_email } = values;

              this.$axios(
                "/linkedin/save_new_linkedin",
                {
                  ...values,
                  // email: linkedin_email,
                  proxy_manually: this.switchVal ? 1 : 0,
                  campaign_type: 1,
                },
                "post"
              )
                .then((res) => {
                  if (res.code == 200) {
                    this.tmpLinkid = res.data.new_linkedin_id;
                    this.$axios(
                      "/linkedin/linkedin_login",
                      {
                        linkedin_id: res.data.new_linkedin_id,
                      },
                      "post"
                    )
                      .then((res2) => {
                        this.loading = false;
                        if (res2.code == 200) {
                          this.getLinkList();
                          this.$message.success(res2.msg);
                          // this.tmpLinkid = res2.data.new_linkedin_id;
                        } else if (res2.code == 201) {
                          this.showStep3 = true;
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        this.loading = false;
                      });
                  } else {
                    this.loading = false;
                  }
                })
                .catch((e) => {
                  console.log(e);
                  this.loading = false;
                });
            }
          } else {
            let { fa_code } = values;
            this.$axios(
              "/linkedin/linkedin_input",
              {
                fa_code: parseInt(fa_code),
                linkedin_id: this.tmpLinkid,
              },
              "post"
            )
              .then((res) => {
                this.loading = false;
                if (res.code == 200) {
                  this.getLinkList();
                  this.showStep3 = false;
                  //
                }
              })
              .catch((e) => {
                console.log(e);
                this.loading = false;
              });
          }
        }
      });
    },
    getLinkList(isFirst) {
      this.loading = true;
      this.$axios("/linkedin/list", { campaign_type: 1 }, "post").then(
        (res) => {
          this.loading = false;
          if (res.code == 200) {
            this.linkList = res.data;
            this.haveNoData = !res.data || res.data.length == 0 ? true : false;
            if (res.data.length > 0) {
              this.selectedIndex = 0;
              this.selectedObj = { isFirst, ...res.data[0] };
              let {
                proxy_manually,
                country_config_id,
                timezone_config_id,
                linkedin_email,
              } = this.selectedObj;
              this.switchVal = proxy_manually == 1 ? true : false;
              this.form.setFieldsValue({
                country_config_id,
                timezone_config_id,
                linkedin_email,
              });
              this.edit = true;
              this.switchVal = res.data[0].proxy_manually == 1 ? true : false;
            }
          }
        }
      );
    },
    changePassword(e) {
      let value = e.target.value.trim();
      this.$nextTick(() => {
        this.form.setFieldsValue({
          linkedin_password: value,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 20px 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Arial, Arial;
  .left {
    background: #fff;
    border-radius: 4px;
    padding: 30px 12px;
    width: 300px;
    .tit {
      font-weight: bold;
      font-size: 14px;
      color: #000000;
      text-align: center;
    }
    .btn {
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 2px solid #0e756a;
      height: 48px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      line-height: 48px;
      text-align: center;
      margin-top: 40px;
      cursor: pointer;
    }
    .list {
      padding-top: 22px;
      max-height: 500px;
      overflow-y: auto;
      li {
        display: flex;
        align-items: center;
        padding: 19px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d6d6d6;
        margin-bottom: 10px;
        cursor: pointer;
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: #d8d8d8;
        }
        .info {
          margin-left: 13px;
          flex: 1;
          width: 100px;
          .email {
            margin-top: 8px;
            text-align: left;
          }
          .infotxt {
            margin-top: 10px;
            font-size: 12px;

            .label {
              font-weight: 400;
              color: #666666;
            }
            .cont {
              font-weight: bold;
              color: #000000;
            }
          }
        }
      }
      .selected {
        background: #ffffff;
        box-shadow: 0px 2px 6px 1px rgba(14, 117, 106, 0.3);
        border-radius: 4px 4px 4px 4px;
        border: 2px solid #0e756a;
      }
    }
  }
  .right {
    flex: 1;
    width: 100%;
    margin-left: 30px;
    padding: 30px;
    background: #fff;
    border-radius: 4px;
    .nav {
      border-radius: 2px;
      li {
        // width: 86px;
        height: 28px;
        padding: 0 20px;
        display: inline-block;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
      .leftBtn {
        border-radius: 2px 0px 0px 2px;
        border: 1px solid #d6d6d6;
        border-right: none;
      }

      .rightBtn {
        border-radius: 0px 2px 2px 0px;
        border: 1px solid #d6d6d6;
        border-left: none;
      }
      .active {
        color: #fff;
        border: 1px solid #0e756a;
        background: #0e756a;
      }
    }
    .haveNoData {
      height: 830px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1600px) {
        height: 660px;
      }
    }
    .wrap-form {
      padding-top: 46px;
      min-height: 660px;
      .form {
        width: 400px;
      }
      position: relative;

      .switchbox {
        margin-bottom: 30px;
        .switch {
          margin-right: 11px;
        }
      }
      .connect {
        border: #0e756a solid 1px;
        color: #0e756a;
      }
      .m120 {
        margin-right: 120px;
      }
      .subTitle {
        font-family: Arial, Arial;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        margin-bottom: 30px;
      }
      /deep/.ant-form-item {
        margin-bottom: 20px;
      }
      // /deep/.ant-input::placeholder {
      //   color: #999999 !important;
      //   background: #dfdfdf;
      // }
      // /deep/.ant-input:placeholder-shown {
      //   text-overflow: ellipsis;
      //   background: #dfdfdf;
      // }
      .input {
        width: 400px;
      }
    }
    .subsribe {
      padding-top: 30px;
      min-height: 660px;
      li {
        height: 48px;
        display: flex;
        display: flex;
        align-items: center;
        padding: 0 30px;
        background: #f2f3f8;
        border-radius: 4px;
        justify-content: space-between;
        margin-bottom: 30px;
        .label {
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          .licon {
            margin-right: 5px;
          }
        }
        .bold {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
        }
        .status {
          color: #505554;
        }
        .active {
          font-weight: bold;
          font-size: 16px;
          color: #08a57b;
        }
        .time {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  .tips {
    background: #fcf1df;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #ffbb00;
    color: #302525;
    padding: 12px;
    margin-bottom: 20px;
  }
}
</style>
