import Vue from "vue";
import App from "./App.vue";
import { initRouter } from "./router";
import "./theme/index.less";
import Antd from "ant-design-vue";
import Viser from "viser-vue";
import "@/mock";
import store from "./store";
import "animate.css/source/animate.css";
import Plugins from "@/plugins";
import { initI18n } from "@/utils/i18n";
import bootstrap from "@/bootstrap";
import "moment/locale/zh-cn";
import auth0 from "auth0-js";
import "./icons";
import NoData from "@/components/notData";
import MixinsCommon from "@/mixins/commonMixins.js";
import { get, post, put, $axios } from "@/utils/request";
import AppFooter from "@/components/footer-component";
import Router from "vue-router";

import VueGtag from "vue-gtag";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
Vue.component("app-footer", AppFooter);
const router = initRouter(store.state.setting.asyncRoutes);
const i18n = initI18n("CN", "US");
import Message from "@/components/message/index.js";

Vue.prototype.webAuth = new auth0.WebAuth({
  // domain: "dev-76n47wurv4yub3b1.us.auth0.com",
  // clientID: "8ni9tMnCSrSdMqnJgROZn09Uhe919nu0",
  domain: "gonex.us.auth0.com",
  clientID: "GnskaA7mv2RDuMr4NAfdoO2TuUq4hOQ0",
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
});
Vue.mixin(MixinsCommon);
Vue.component("NoData", NoData);
Vue.use(Antd);
Vue.config.productionTip = false;
Vue.use(Viser);
Vue.use(Plugins);
Vue.prototype.$message = Message;
bootstrap({ router, store, i18n, message: Vue.prototype.$message });
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$post = post;
Vue.prototype.$axios = $axios;

if (process.env.VUE_APP_PAY_MOD == "prod") {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-0Y4DPJGWLK" },
      includes: [{ id: "G-DS5DVQK2WE" }],
    },
    router
  );
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
