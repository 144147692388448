module.exports = {
  messages: {
    CN: {
      statusChangeTip:
        "当前LinkedIn账号下已有进行中的campaign，如想将该campaign调整为active状态，需要关闭另一个",
      goSet: "去设置",
      home: "首页",
      campaign: "任务",
      campaigns: "任务",
      dashboard: "看板	",
      linkedinAccountSetting: "领英账号设置	",
      ViewAllCampaigns: "查看所有任务",
      noHotData: "暂无对话",
      back: "返回",

      TotalNumber: "总人数",
      ConnectionsRequest: "好友请求数",
      ConnectionsMade: "添加好友数",
      Acceptedrate: "通过率",
      NumberOfGreeters: "打招呼人数",
      Replies: "回复人数",
      Repliesrate: "回复率",
      Message: "消息数",
      LeadsGenerated: "线索数",
      ConversionRate: "转化率",
      ExistingFriends: "已存在好友",

      Hotconversataion: "热门对话",
      Company: "公司",
      Conversation: "对话内容",
      LinkedIn: "领英账号",
      RecentCampaign: "最近任务",
      Name: "任务名称",
      Status: "状态",
      LinkedinlD: "领英账号",
      LinkedinAccount: "领英账号",
      filterLinkedinAccount: "领英账号",
      Days: "天数",
      Action: "操作",
      Details: "详情",
      Copy: "复制",
      Delete: "删除",
      Active: "开启",
      Paused: "暂停",
      noCamp: "暂无任务",
      Create: "创建",
      //任务详情
      PendingConnection: "等待添加",
      ConnectionRequestSent: "已发送好友申请",
      ConnectionApproved: "已通过好友申请",
      ResponseReceived: "已回复",
      LeadContactInformationObtained: "已拿到联系方式",
      fullName: "姓名",
      Position: "职位",
      Location: "位置",
      NumberofInteractions: "对话数",
      ContactInformation: "联系方式",
      AutomaticConnections: "升级后自动添加",
      viewComplteList: "升级查看完整列表",
      CustomersAwaiting: "更多客户在等待联系",
      SaveNow: "年付享受83折",
      day: "天",
      Hrs: "时",
      Mins: "分",
      Secs: "秒",
      updateBtn: "升级",

      /*link*/
      LinkedInAccountLinked: "已绑定LinkedIn账号",
      AddLinkedInAccount: "添加LinkedIn账号",
      LinkedInAccounts: "LinkedIn账号",
      SubscriptionDetails: "订阅信息",
      NoLinkedInAccountsAdded: "暂未添加任何LinkedIn账号",
      SubscriptionPlan: "订阅版本",
      FreePro: "免费/pro",
      SubscriptionActivationTime: "绑定时间",
      bindLinkAcc: "绑定领英账号",
      linkEmal: "邮箱",
      linkPwd: "密码",
      timeZone: "时区",
      ConfigureLinkedInProx: "代理设置",
      ManualConfiguration: "人工配置",
      SelectProxyRegion: "代理区域",
      bindTime: "[]年[]月[]日",
      activeTime: "活跃（[]年[]月[]日过期）",

      startTime: "开始时间",
      endTime: "结束时间",
      date: "日期",
      inputLinkAccount: "请选择LinkedIn账号",
      inputContent: "请输入内容",
      rightAccount: "请输入正确的邮箱领英账号",
      inputPwd: "请输入密码",
      selectTimezone: "请选择时区",
      selectRegion: "请选择代理区域",
      selectUrl: "请输入正确的LinkedIn URL",
      editTitle: "请编辑自定义标题",
      shortDes: "请输入简短总结",
      dialogLimit: "请输入对话限制",
      All: "全部",
      bindTips:
        "请准备好确认您LinkedIn账户的PIN码。该PIN码将由LinkedIn发送到LinkedIn账户的邮箱，具体取决于安全设置，也可能通过短信发送。",
      inputCode: "请输入验证码",
      confirmDel: "确认要删除此任务",
      NextStep: "下一步",

      Account: "账号",
      TimeZone: "时区",
      Proxy: "代理",
      Connected: "已连接",
      ObtainProxyIP: "获取代理IP",
      VerifyLink: "校验LinkedIn账号密码",
      LinkedInAccountLink: "LinkedIn账号链接",
      ContinueConnect: "继续链接",
      monthsago: "[]月前",
      weeksago: "[]周前",
      daysago: "[]天前",
      hoursago: "[]小时前",
      minutesago: "[]分钟前",
      Justnow: "刚刚",
      Save: "保存",

      //dashborder弹窗
      WhyUpgrade: "为什么要升级？",
      upgradeTitle: "升级后可查看完整数据看板，指导后续销售策略迭代",
      UpgradeNow: "立即升级",
    },
    US: {
      statusChangeTip:
        "Only one campaign can be active at a time. To activate a new campaign, please deactivate the existing active campaign.",
      goSet: "Adjust Campaign Settings",
      home: "Home",
      campaign: "Campaign",
      campaigns: "Campaigns",
      dashboard: "Dashboard",
      linkedinAccountSetting: "LinkedIn Account Settings",
      ViewAllCampaigns: "View All Campaigns",
      noHotData: "No Conversations Yet",
      back: "Back",

      TotalNumber: "Prospective Clients",
      ConnectionsRequest: "Connections request",
      ConnectionsMade: "Connections Made",
      Acceptedrate: "Acceptance Rate",
      NumberOfGreeters: "Number of greeters",
      Replies: "Respondents",
      Repliesrate: "Engagement Rate",
      Message: "Messages Sent",
      LeadsGenerated: "Leads Generated",
      ConversionRate: "Conversion Rate",
      ExistingFriends: "Existing Friends",

      Hotconversataion: "Popular Conversations",
      Company: "Company",
      Conversation: "Conversation Details",
      LinkedIn: "LinkedIn Account",
      RecentCampaign: "Recent Campaigns",
      Name: "Campaign Name",
      Status: "Status",
      LinkedinlD: "LinkedIn Account",
      LinkedinAccount: "LinkedIn account",
      filterLinkedinAccount: "Filter by LinkedIn Account",
      Days: "Days Active",
      Action: "Actions",
      Details: "Details",
      Copy: "Copy",
      Delete: "Delete",
      Active: "Activate",
      Paused: "Paused",
      noCamp: "No Recent Campaigns",
      Create: "Create",
      //任务详情
      PendingConnection: "Pending Connection",
      ConnectionRequestSent: "Connection Request Sent",
      ConnectionApproved: "Connection Approved",
      ResponseReceived: "Response Received",
      LeadContactInformationObtained: "Lead Contact Information Obtained",
      fullName: "Name",
      Position: "Position",
      Location: "Location",
      NumberofInteractions: "Number of Interactions",
      ContactInformation: "Contact Information",
      AutomaticConnections: "Enable Automatic Connections with Upgrade",
      viewComplteList: "View Complete Client List with Upgrade",
      CustomersAwaiting: "more Customers Awaiting Contact",
      SaveNow: "Save 17% Now",
      day: "Days",
      Hrs: "Hrs",
      Mins: "Mins",
      Secs: "Secs",
      updateBtn: "Upgrade",
      /*link*/
      LinkedInAccountLinked: "LinkedIn Account Linked",
      AddLinkedInAccount: "Add LinkedIn Account",
      LinkedInAccounts: "LinkedIn Accounts",
      SubscriptionDetails: "Subscription Details",
      NoLinkedInAccountsAdded: "No LinkedIn Accounts Added",
      SubscriptionPlan: "Subscription Plan",
      FreePro: "Free/Pro",
      SubscriptionActivationTime: "Subscription Activation Time",
      bindLinkAcc: "Connect Your LinkedIn Account",
      linkEmal: "LinkedIn Email Address",
      linkPwd: "LinkedIn Password",
      timeZone: "Select Your Time Zone",
      ConfigureLinkedInProx: "Configure LinkedIn Proxy",
      ManualConfiguration: "Manual Configuration",
      SelectProxyRegion: "Select Proxy Region",
      bindTime: "LinkedIn Account Added On: []-[]-[]",
      activeTime: "Active (Expires on []-[]-[])",

      startTime: "startTime",
      endTime: "endTime",
      date: "Filter by Date",
      inputLinkAccount: "Please Select a LinkedIn Account",

      inputContent: "Please enter the content",
      rightAccount: "Please enter a valid LinkedIn email account",
      inputPwd: "Please enter the password",
      selectTimezone: "Please select a time zone",
      selectRegion: "Please select a proxy region",
      selectUrl: "Please enter a valid LinkedIn URL",
      editTitle: "Please edit the custom title",
      shortDes: "Please input your short description",
      dialogLimit: "Please input conversation limit",
      All: "All",
      bindTips: `Be ready to confirm the PIN for your LinkedIn account due
                    to 2-factor authorization. It will be sent by LinkedIn to
                    the email address of the LinkedIn account. Depending on the
                    security settings it may also be sent via SMS.`,
      inputCode: "Please input code",
      confirmDel: "Are you sure you want to delete this task",
      NextStep: "Next",

      Account: "Account",
      TimeZone: "Time Zone",
      Proxy: "Proxy",
      Connected: "Connected",
      ObtainProxyIP: "Obtain Proxy IP",
      VerifyLink: "Verify LinkedIn Account Credentials",
      LinkedInAccountLink: "LinkedIn Account Link",
      ContinueConnect: "Continue Connect",
      monthsago: " [] months ago",
      weeksago: " [] weeks ago",
      daysago: "[] days ago",
      hoursago: "[] hours ago",
      minutesago: " [] minutes ago",
      Justnow: " Just now",
      Save: "Save",
      //dashborder弹窗
      WhyUpgrade: "Why Upgrade?",
      upgradeTitle:
        "Upgrade to access the complete data dashboard, guiding future sales strategy iterations.",
      UpgradeNow: "Upgrade Now",
    },
    AR: {
      statusChangeTip:
        "يمكن تفعيل حملة واحدة فقط في كل مرة. لتفعيل حملة جديدة، يرجى إلغاء تفعيل الحملة النشطة الحالية.",
      goSet: "ضبط إعدادات الحملة",
      home: "الصفحة الرئيسية",
      campaign: "الحملة",
      campaigns: "الحملة",
      dashboard: "لوحة التحكم	",
      linkedinAccountSetting: "تهيئة لينكد إن للأتمتة بالذكاء الاصطناعي	",
      ViewAllCampaigns: "مشاهدة جميع الحملات",
      noHotData: "لا توجد محادثات بعد",
      back: "العودة",

      TotalNumber: "إجمالي عدد جهات الاتصال المحتملة",
      ConnectionsRequest: "عدد طلبات الصداقة",
      ConnectionsMade: "عدد الاتصالات التي تم إنشاؤها",
      Acceptedrate: "نسبة القبول",
      NumberOfGreeters: "عدد الأشخاص الذين ألقوا التحية",
      Replies: "عدد المستجيبين",
      Repliesrate: "نسبة الاستجابة",
      Message: "عدد الرسائل المرسلة",
      LeadsGenerated: "عدد العملاء المحتملين المولدة",
      ConversionRate: "نسبة التحويل",
      ExistingFriends: "الأصدقاء الحاليون",

      Hotconversataion: "المحادثات الشعبية",
      Company: "الشركة",
      Conversation: "تفاصيل المحادثة",
      LinkedIn: "حساب لينكد إن",
      RecentCampaign: "الحملات الأخيرة",
      Name: "اسم الحملة",
      Status: "الحالة",
      LinkedinlD: "حساب لينكد إن",
      LinkedinAccount: "حساب لينكد إن",
      filterLinkedinAccount: "تصفية حسب حساب LinkedIn",
      Days: "أيام النشاط",
      Action: "الإجراءات",
      Details: "التفاصيل",
      Copy: "نسخ",
      Delete: "حذف",
      Active: "تفعيل",
      Paused: "إيقاف مؤقت",
      noCamp: "لا توجد حملات أخيرة",
      Create: "إنشاء",
      //任务详情
      PendingConnection: "الاتصال قيد الانتظار",
      ConnectionRequestSent: "تم إرسال طلب الاتصال",
      ConnectionApproved: "تم الموافقة على الاتصال",
      ResponseReceived: "تم استلام الرد",
      LeadContactInformationObtained:
        "تم الحصول على معلومات الاتصال للعميل المحتمل",
      fullName: "الاسم",
      Position: "المنصب",
      Location: "الموقع",
      NumberofInteractions: "عدد التفاعلات",
      ContactInformation: "معلومات الاتصال",
      AutomaticConnections: "تمكين الاتصالات الأوتوماتيكية بعد الترقية",
      viewComplteList: "عرض قائمة العملاء الكاملة بعد الترقية",
      CustomersAwaiting: "عملاء xxx في انتظار الاتصال",
      SaveNow: "وفر الآن 67%",
      day: "أيام",
      Hrs: "ساعات",
      Mins: "دقائق",
      Secs: "ثواني متبقية",
      updateBtn: "الترقية إلى النسخة الاحترافية",
      /*link*/
      LinkedInAccountLinked: "تم ربط حساب LinkedIn",
      AddLinkedInAccount: "LinkedIn إضافة حساب ",
      LinkedInAccounts: "LinkedIn حسابات ",
      SubscriptionDetails: "تفاصيل الاشتراك",
      NoLinkedInAccountsAdded: "لم يتم إضافة أي حسابات LinkedIn",
      SubscriptionPlan: "خطة الاشتراك",
      FreePro: "مجاني/محترف",
      SubscriptionActivationTime: "وقت تفعيل الاشتراك",
      bindLinkAcc: "LinkedIn أدخل بيانات اعتماد حسابك على ",
      linkEmal: " LinkedIn عنوان البريد الإلكتروني على ",
      linkPwd: "LinkedIn كلمة المرور على ",
      timeZone: "اختر منطقتك الزمنية",
      ConfigureLinkedInProx: "تكوين الوكيل لينكد إن",
      ManualConfiguration: "التكوين اليدوي",
      SelectProxyRegion: "اختر منطقة البروكسي",
      bindTime: "تم إضافة حساب LinkedIn في: []-[]-[]",
      activeTime: "نشط (ينتهي في []-[]-[])",

      startTime: "开始时间",
      endTime: "结束时间",
      date: "تصفية حسب التاريخ",
      inputLinkAccount: "يرجى اختيار حساب LinkedIn",

      inputContent: "يرجى إدخال المحتوى",
      rightAccount: "يرجى إدخال بريد إلكتروني صالح لحساب LinkedIn",
      inputPwd: "يرجى إدخال كلمة المرور",
      selectTimezone: "يرجى اختيار المنطقة الزمنية",
      selectRegion: "يرجى اختيار منطقة الوكيل",
      selectUrl: "يرجى إدخال رابط LinkedIn صالح",
      editTitle: "يرجى تعديل العنوان المخصص",
      shortDes: "يرجى إدخال الوصف القصير",
      dialogLimit: "يرجى إدخال حد المحادثة",
      All: "الجميع",
      bindTips:
        "كن مستعدًا لتأكيد رمز PIN الخاص بحسابك على LinkedIn بسبب التحقق بخطوتين. سيتم إرساله بواسطة LinkedIn إلى عنوان البريد الإلكتروني لحسابك على LinkedIn. بناءً على إعدادات الأمان، قد يتم إرساله أيضًا عبر الرسائل النصية القصيرة (SMS).",
      inputCode: "يرجى إدخال الرمز",
      confirmDel: "هل أنت متأكد أنك تريد حذف هذه المهمة؟",
      NextStep: "التالي",

      Account: "الحساب",
      TimeZone: "المنطقة الزمنية",
      Proxy: "الوكيل",
      Connected: "متصل",
      ObtainProxyIP: "الحصول على عنوان IP للوكيل",
      VerifyLink: "التحقق من كلمة مرور حساب LinkedIn",
      LinkedInAccountLink: "رابط حساب LinkedIn",
      ContinueConnect: "متابعة الربط",
      monthsago: "منذ [] شهر",
      weeksago: "منذ [] أسبوع",
      daysago: "منذ [] يوم",
      hoursago: "منذ [] ساعة",
      minutesago: "منذ [] دقيقة",
      Justnow: "الآن",
      Save: `حفظ`,
      //dashborder弹窗
      WhyUpgrade: "لماذا ترقية؟",
      upgradeTitle:
        "قم بالترقية للوصول إلى لوحة البيانات الكاملة، مما يوجه تكرارات استراتيجية المبيعات المستقبلية.",
      UpgradeNow: "ترقية الآن",
    },
  },
};
