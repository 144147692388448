var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-layout-header",
    { class: [_vm.headerTheme, "admin-header", _vm.noshadown] },
    [
      _c(
        "div",
        { class: ["admin-header-wide", _vm.layout, _vm.pageWidth] },
        [
          _vm.isMobile || _vm.layout === "head"
            ? _c(
                "router-link",
                {
                  class: ["logo", _vm.isMobile ? null : "pc", _vm.headerTheme],
                  attrs: { to: "/" },
                },
                [
                  _c("svg-icon", {
                    staticClass: "logosvg",
                    attrs: { iconClass: "logo" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showToolbar && !_vm.haveAccount
            ? _c(
                "div",
                {
                  staticClass: "freeTips",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.skipPay },
                },
                [
                  _c("a-icon", {
                    staticStyle: {
                      "font-size": "12px",
                      color: "#ffa200",
                      "margin-right": "5px",
                    },
                    attrs: { type: "exclamation-circle" },
                  }),
                  _c("span", { class: [_vm.lang] }, [
                    _vm._v(
                      _vm._s(_vm.$t("freeTrail").replace("**", _vm.havedays))
                    ),
                  ]),
                  _c("a-icon", {
                    staticStyle: {
                      "font-size": "12px",
                      color: "#ffa200",
                      "margin-left": "5px",
                    },
                    attrs: { type: "right" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showToolbar && _vm.haveAccount
            ? _c(
                "div",
                { staticClass: "freeTips", staticStyle: { cursor: "pointer" } },
                [
                  _c("a-icon", {
                    staticStyle: {
                      "font-size": "12px",
                      color: "#ffa200",
                      "margin-right": "5px",
                    },
                    attrs: { type: "exclamation-circle" },
                  }),
                  _c("span", {
                    class: [_vm.lang],
                    domProps: { innerHTML: _vm._s(_vm.haveAccountTips) },
                  }),
                  _c(
                    "span",
                    { staticClass: "goUpgrade", on: { click: _vm.skipPay } },
                    [_vm._v(_vm._s(_vm.$t("UpgradeNow")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.layout !== "side" && !_vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "admin-header-menu",
                  style: `width: ${_vm.menuWidth};`,
                },
                [
                  _c("i-menu", {
                    staticClass: "head-menu",
                    class: [_vm.lang],
                    attrs: {
                      theme: _vm.headerTheme,
                      mode: "horizontal",
                      options: _vm.menuData,
                    },
                    on: { select: _vm.onSelect },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { class: ["admin-header-right", _vm.headerTheme] },
            [
              _c(
                "a-dropdown",
                { staticClass: "lang header-item" },
                [
                  _c(
                    "div",
                    [
                      _c("a-icon", { attrs: { type: "global" } }),
                      _vm._v(" " + _vm._s(_vm.langAlias)),
                    ],
                    1
                  ),
                  _c(
                    "a-menu",
                    {
                      attrs: {
                        slot: "overlay",
                        "selected-keys": [
                          _vm.localLang ? _vm.localLang : _vm.lang,
                        ],
                      },
                      on: { click: (val) => _vm.setClang(val) },
                      slot: "overlay",
                    },
                    _vm._l(_vm.langList, function (lang) {
                      return _c("a-menu-item", { key: lang.key }, [
                        _vm._v(_vm._s(lang.name)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("header-avatar", { staticClass: "header-item" }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }