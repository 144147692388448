module.exports = {
  messages: {
    CN: {
      NewDialogue: "新对话",
      RecentDialogue: "历史对话",
      Hello: "你好",
      helpYou: "我将尽全力去协助你！",
      askMeQs: "你可以问我全球HR雇佣的政策，比如：",
      askMeTask: "你也可以让我帮你执行以下任务",
      FinishCeneration: "结束生成",
      flowTsk:
        "你现在处于生成文件流程，点击下方快捷按钮向文件中添加新字段，或者点击“结束生成”按钮回到问答流程。",
      enterQs: "在此输入你的问题",
      QaProcess: "你现在处于问答流程",
      sendMail: "发送至邮箱",
      moreClick: "更多（点击展示更多）",
      thinking: "thinking",
      checkContentTips: "请检查模板内容，不能有未替换的变量哦~",
      inputMaile: "请输入邮箱",
      send: "发送",
    },

    US: {
      NewDialogue: "New Dialogue",
      RecentDialogue: "Recent Dialogue",
      Hello: "Hello",
      helpYou: "I will do my best to assist you!",
      askMeQs: "You can ask me about global HR employment policies, such as:",
      askMeTask: "You can also ask me to help you with the following tasks",
      FinishCeneration: "Finish Ceneration",
      flowTsk:
        ' You are now in the generation process. Click the button below to add details to the offer, or click "Finish Generation" toreturn to the Q&A process.',
      enterQs: "Enter your question here",
      QaProcess: "You are now in the Q&A process.",
      sendMail: "Send to email",
      moreClick: "More (click to show more)",
      thinking: "thinking",
      checkContentTips:
        "The template content must be checked to ensure that there are no unresolved variables.",
      inputMaile: "Please enter your email address",
      send: "Send",
    },
    AR: {
      NewDialogue: "New Dialogue",
      RecentDialogue: "Recent Dialogue",
      Hello: "Hello",
      helpYou: "I will do my best to assist you!",
      askMeQs: "You can ask me about global HR employment policies, such as:",
      askMeTask: "You can also ask me to help you with the following tasks",
      FinishCeneration: "Finish Ceneration",
      flowTsk:
        ' You are now in the generation process. Click the button below to add details to the offer, or click "Finish Generation" toreturn to the Q&A process.',
      enterQs: "Enter your question here",
      QaProcess: "You are now in the Q&A process.",
      sendMail: "Send to email",
      moreClick: "More (click to show more)",
      thinking: "thinking",
      checkContentTips:
        "The template content must be checked to ensure that there are no unresolved variables.",
      inputMaile: "Please enter your email address",
      send: "Send",
    },
  },
};
