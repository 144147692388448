var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "wrap-form" },
      [
        _c(
          "a-form",
          { staticClass: "form", attrs: { form: _vm.form } },
          [
            _c(
              "div",
              {
                staticClass: "tit m50",
                class: [_vm.lang],
                staticStyle: { width: "500px" },
              },
              [
                _c("svg-icon", {
                  staticClass: "tit-icon",
                  attrs: { iconClass: "bind" },
                }),
                _c("span", { class: [_vm.lang] }, [
                  _vm._v(_vm._s(_vm.$t("bindLinkAcc"))),
                ]),
                _c(
                  "a-popover",
                  {
                    staticStyle: { "z-index": "98" },
                    attrs: {
                      placement:
                        _vm.lang == "AR" ? "bottomRight" : "bottomLeft",
                      trigger: "click",
                    },
                    model: {
                      value: _vm.visiable,
                      callback: function ($$v) {
                        _vm.visiable = $$v
                      },
                      expression: "visiable",
                    },
                  },
                  [
                    _c("template", { slot: "content" }, [
                      _c("div", { staticClass: "popbox" }, [
                        _c(
                          "p",
                          {
                            staticClass: "main-tit",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("bindLinkAccTipTitle1")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ptit",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v(_vm._s(_vm.$t("bindLinkAccTipcon1"))),
                            ]),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "main-tit main-tit2",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("bindLinkAccTipTitle2")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ptit",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "txt-red",
                                class: [_vm.lang],
                                style: {
                                  "text-align":
                                    _vm.lang == "AR" ? "right" : "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bindLinkAccTipcon2")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "txt-red",
                                class: [_vm.lang],
                                style: {
                                  "text-align":
                                    _vm.lang == "AR" ? "right" : "left",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bindLinkAccTipcon3")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn-box",
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "btn-txt",
                                class: [_vm.lang],
                                on: { click: _vm.goLink },
                              },
                              [_vm._v(_vm._s(_vm.$t("bindLinkAccTipBtn")))]
                            ),
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v(_vm._s(_vm.$t("bindLinkAccTipBtnTxt"))),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("a-icon", {
                      staticStyle: {
                        "font-size": "14px",
                        color: "#0e756a",
                        cursor: "pointer",
                        "margin-left": "6px",
                      },
                      attrs: { type: "question-circle" },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            this.firstReg && _vm.new_linkedin_id
              ? _c("div", { staticClass: "showInfo" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "div",
                        { staticClass: "lc", class: [_vm.lang] },
                        [
                          _c("svg-icon", {
                            staticClass: "l-icon",
                            attrs: { iconClass: "email" },
                          }),
                          _c("span", { staticClass: "t1", class: [_vm.lang] }, [
                            _vm._v(" " + _vm._s(_vm.$t("Account")) + "： "),
                          ]),
                          _c("span", { staticClass: "t2" }, [
                            _vm._v(_vm._s(_vm.formData.linkedin_email)),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "div",
                        { staticClass: "lc" },
                        [
                          _c("svg-icon", {
                            staticClass: "l-icon",
                            attrs: { iconClass: "timezone" },
                          }),
                          _c("span", { staticClass: "t1", class: [_vm.lang] }, [
                            _vm._v(" " + _vm._s(_vm.$t("TimeZone")) + "： "),
                          ]),
                          _c("span", { staticClass: "t2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getTimez(_vm.formData.timezone_config_id)
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "rc" },
                        [
                          _c("svg-icon", {
                            staticClass: "r-icon",
                            attrs: { iconClass: "right" },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("Connected")) + " "),
                        ],
                        1
                      ),
                    ]),
                    _c("li", { staticStyle: { "margin-bottom": "0" } }, [
                      _c(
                        "div",
                        { staticClass: "lc" },
                        [
                          _c("svg-icon", {
                            staticClass: "l-icon",
                            attrs: { iconClass: "global" },
                          }),
                          _c("span", { staticClass: "t1", class: [_vm.lang] }, [
                            _vm._v(" " + _vm._s(_vm.$t("Proxy")) + "： "),
                          ]),
                          _c("span", { staticClass: "t2", class: [_vm.lang] }, [
                            _vm._v(
                              _vm._s(
                                _vm.getcountry(_vm.formData.country_config_id)
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            !_vm.firstReg && !_vm.$route.query.edit
              ? _c(
                  "div",
                  { staticClass: "wrap-link-list" },
                  [
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c(
                          "a-radio-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "link_id",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: _vm.$t("inputLinkAccount"),
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n              'link_id',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('inputLinkAccount'),\n                  },\n                ],\n              },\n            ]",
                              },
                            ],
                            on: { change: _vm.selectedRadio },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.linkList, function (item, index) {
                                return _c("li", { key: index }, [
                                  _c(
                                    "label",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text" },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "icon",
                                            attrs: { iconClass: "email" },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(item.linkedin_email) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("a-radio", {
                                        attrs: { value: item.linkedin_id },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.firstReg && !_vm.isClickNext
              ? [
                  _c("a-form-item", { class: [_vm.lang] }, [
                    _c(
                      "div",
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "linkedin_email",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        validator: function (
                                          rule,
                                          value,
                                          callback
                                        ) {
                                          let reg =
                                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                          if (
                                            !value ||
                                            !value.trim("") ||
                                            !reg.test(value)
                                          ) {
                                            callback(_vm.$t("rightAccount"))
                                            return
                                          }
                                          callback()
                                        },
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                'linkedin_email',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      validator: function (rule, value, callback) {\n                        let reg =\n                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/;\n                        if (!value || !value.trim('') || !reg.test(value)) {\n                          callback($t('rightAccount'));\n                          return;\n                        }\n                        callback();\n                      },\n                    },\n                  ],\n                },\n              ]",
                              },
                            ],
                            staticClass: "input",
                            staticStyle: { height: "40px" },
                            attrs: {
                              size: "large",
                              placeholder: _vm.$t("linkEmal"),
                            },
                            on: { change: _vm.changeHandler1 },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { slot: "prefix", iconClass: "email" },
                              slot: "prefix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "a-form-item",
                    { class: [_vm.lang] },
                    [
                      _c(
                        "a-input-password",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "linkedin_password",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: _vm.$t("inputPwd"),
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "[\n              'linkedin_password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('inputPwd'),\n                  },\n                ],\n              },\n            ]",
                            },
                          ],
                          staticClass: "input",
                          staticStyle: { height: "40px" },
                          attrs: {
                            size: "large",
                            type: "linkedin_password",
                            placeholder: _vm.$t("linkPwd"),
                          },
                          on: { change: _vm.changeHandler1 },
                        },
                        [
                          _c("svg-icon", {
                            attrs: { slot: "prefix", iconClass: "lock" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-form-item", { class: [_vm.lang] }, [
                    _c(
                      "div",
                      { staticClass: "wrap-select" },
                      [
                        _c("svg-icon", {
                          staticClass: "prefix",
                          attrs: { iconClass: "timezone" },
                        }),
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "timezone_config_id",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: _vm.$t("selectTimezone"),
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                'timezone_config_id',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('selectTimezone'),\n                    },\n                  ],\n                },\n              ]",
                              },
                            ],
                            staticClass: "input process-select",
                            attrs: {
                              size: "large",
                              placeholder: _vm.$t("timeZone"),
                            },
                          },
                          _vm._l(_vm.TimeZoneList, function (item, index) {
                            return _c(
                              "a-select-option",
                              {
                                key: index,
                                class: [_vm.lang],
                                attrs: { value: item.id },
                              },
                              [_vm._v(" " + _vm._s(item.show_name) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("a-form-item", { class: [_vm.lang] }, [
                    _c(
                      "div",
                      { staticClass: "wrap-select" },
                      [
                        _c("svg-icon", {
                          staticClass: "prefix",
                          attrs: { iconClass: "global" },
                        }),
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "country_config_id",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: _vm.$t("selectRegion"),
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                'country_config_id',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('selectRegion'),\n                    },\n                  ],\n                },\n              ]",
                              },
                            ],
                            staticClass: "input process-select",
                            attrs: {
                              size: "large",
                              "filter-option": _vm.filterOption,
                              showSearch: "",
                              placeholder: _vm.$t("SelectProxyRegion"),
                            },
                          },
                          _vm._l(_vm.locationList, function (item, index) {
                            return _c(
                              "a-select-option",
                              {
                                key: index,
                                class: [_vm.lang],
                                attrs: { value: item.id },
                              },
                              [_vm._v(" " + _vm._s(item.show_name) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "a-form-item",
                    {
                      style: {
                        "text-align": _vm.lang == "AR" ? "right" : "left",
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          class: [_vm.lang],
                          attrs: { size: "large", type: "primary" },
                          on: { click: _vm.formNextHandler },
                        },
                        [_vm._v(_vm._s(_vm.$t("NextStep")))]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm.processing
              ? [
                  _c("div", { staticClass: "processing" }, [
                    _c(
                      "div",
                      { staticClass: "cstep" },
                      [
                        _vm.processStep.step1 == 0
                          ? _c("div", { staticClass: "number" }, [_vm._v("1")])
                          : _vm._e(),
                        _vm.processStep.step1 == 1
                          ? _c("svg-icon", {
                              staticClass: "cicon",
                              attrs: { iconClass: "wrong" },
                            })
                          : _vm._e(),
                        _vm.processStep.step1 == 2
                          ? _c("svg-icon", {
                              staticClass: "cicon rotating",
                              attrs: { iconClass: "load" },
                            })
                          : _vm._e(),
                        _vm.processStep.step1 == 3
                          ? _c("svg-icon", {
                              staticClass: "cicon",
                              attrs: { iconClass: "right" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            class: {
                              wrong: _vm.processStep.step1 == 1,
                              right:
                                _vm.processStep.step1 == 2 ||
                                _vm.processStep.step1 == 3,
                            },
                          },
                          [
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v(_vm._s(_vm.$t("ObtainProxyIP"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "splitbox getProxy" }, [
                      _vm.processStep.step1 == 1
                        ? _c(
                            "div",
                            { staticClass: "asset" },
                            [
                              _c(
                                "a-form-item",
                                {
                                  class: [_vm.lang],
                                  staticStyle: {
                                    width: "316px",
                                    "margin-bottom": "0",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "wrap-select" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "prefix",
                                        attrs: { iconClass: "global" },
                                      }),
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "country_config_id",
                                                {
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        _vm.$t("selectRegion"),
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "[\n                      'country_config_id',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('selectRegion'),\n                          },\n                        ],\n                      },\n                    ]",
                                            },
                                          ],
                                          staticClass:
                                            "process-input process-select",
                                          attrs: {
                                            size: "large",
                                            "filter-option": _vm.filterOption,
                                            showSearch: "",
                                            placeholder:
                                              _vm.$t("SelectProxyRegion"),
                                          },
                                        },
                                        _vm._l(
                                          _vm.locationList,
                                          function (item, index) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: index,
                                                class: [_vm.lang],
                                                attrs: { value: item.id },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.show_name) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cstep" },
                      [
                        _vm.processStep.step2 == 0
                          ? _c("div", { staticClass: "number" }, [_vm._v("2")])
                          : _vm._e(),
                        _vm.processStep.step2 == 1
                          ? _c("svg-icon", {
                              staticClass: "cicon",
                              attrs: { iconClass: "wrong" },
                            })
                          : _vm._e(),
                        _vm.processStep.step2 == 2
                          ? _c("svg-icon", {
                              staticClass: "cicon rotating",
                              attrs: { iconClass: "load" },
                            })
                          : _vm._e(),
                        _vm.processStep.step2 == 3
                          ? _c("svg-icon", {
                              staticClass: "cicon",
                              attrs: { iconClass: "right" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            class: {
                              wrong: _vm.processStep.step2 == 1,
                              right:
                                _vm.processStep.step2 == 2 ||
                                _vm.processStep.step2 == 3,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("VerifyLink")) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "splitbox getProxy" }, [
                      _vm.processStep.step2 == 1
                        ? _c(
                            "div",
                            { staticClass: "asset" },
                            [
                              _c(
                                "a-form-item",
                                {
                                  class: [_vm.lang],
                                  staticStyle: { "margin-bottom": "20px" },
                                },
                                [
                                  _c(
                                    "a-input",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "linkedin_email",
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  validator: function (
                                                    rule,
                                                    value,
                                                    callback
                                                  ) {
                                                    let reg =
                                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                                    if (
                                                      !value ||
                                                      !value.trim("") ||
                                                      !reg.test(value)
                                                    ) {
                                                      callback(
                                                        _vm.$t("rightAccount")
                                                      )
                                                      return
                                                    }
                                                    callback()
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                          expression:
                                            "[\n                    'linkedin_email',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          validator: function (rule, value, callback) {\n                            let reg =\n                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/;\n                            if (\n                              !value ||\n                              !value.trim('') ||\n                              !reg.test(value)\n                            ) {\n                              callback($t('rightAccount'));\n                              return;\n                            }\n                            callback();\n                          },\n                        },\n                      ],\n                    },\n                  ]",
                                        },
                                      ],
                                      staticClass: "process-input",
                                      attrs: {
                                        size: "large",
                                        placeholder: _vm.$t("linkEmal"),
                                      },
                                      on: { change: _vm.changeHandler1 },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          slot: "prefix",
                                          iconClass: "email",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                { class: [_vm.lang] },
                                [
                                  _c(
                                    "a-input-password",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "linkedin_password",
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message: _vm.$t("inputPwd"),
                                                },
                                              ],
                                            },
                                          ],
                                          expression:
                                            "[\n                    'linkedin_password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: $t('inputPwd'),\n                        },\n                      ],\n                    },\n                  ]",
                                        },
                                      ],
                                      staticClass: "process-input",
                                      attrs: {
                                        size: "large",
                                        type: "linkedin_password",
                                        placeholder: _vm.$t("linkPwd"),
                                      },
                                      on: { change: _vm.changeHandler1 },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          slot: "prefix",
                                          iconClass: "lock",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cstep" },
                      [
                        _vm.processStep.step3 == 0
                          ? _c("div", { staticClass: "number" }, [_vm._v("3")])
                          : _vm._e(),
                        _vm.processStep.step3 == 1
                          ? _c("svg-icon", {
                              staticClass: "cicon",
                              attrs: { iconClass: "wrong" },
                            })
                          : _vm._e(),
                        _vm.processStep.step3 == 2
                          ? _c("svg-icon", {
                              staticClass: "cicon rotating",
                              attrs: { iconClass: "load" },
                            })
                          : _vm._e(),
                        _vm.processStep.step3 == 3
                          ? _c("svg-icon", {
                              staticClass: "cicon",
                              attrs: { iconClass: "right" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            class: {
                              wrong: _vm.processStep.step3 == 1,
                              right:
                                _vm.processStep.step3 == 2 ||
                                _vm.processStep.step3 == 3,
                            },
                          },
                          [
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v(_vm._s(_vm.$t("LinkedInAccountLink"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.processStep.step3 == 1
                      ? _c(
                          "div",
                          {
                            staticClass: "splitbox getProxy",
                            staticStyle: { "border-left": "#fff solid 1px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "asset" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tips" },
                                  [
                                    _c("a-icon", {
                                      staticStyle: {
                                        "margin-right": "12px",
                                        color: "#ffa200",
                                      },
                                      attrs: { type: "exclamation-circle" },
                                    }),
                                    _c("div", [
                                      _c("span", { class: [_vm.lang] }, [
                                        _vm._v(_vm._s(_vm.$t("bindTips"))),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-item",
                                  { class: [_vm.lang] },
                                  [
                                    _c("a-input", {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "fa_code",
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  validator: function (
                                                    rule,
                                                    value,
                                                    callback
                                                  ) {
                                                    if (
                                                      !value ||
                                                      !value.trim("")
                                                    ) {
                                                      callback(
                                                        _vm.$t("inputCode")
                                                      )
                                                      return
                                                    }
                                                    callback()
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                          expression:
                                            "[\n                    'fa_code',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          validator: function (rule, value, callback) {\n                            if (!value || !value.trim('')) {\n                              callback($t('inputCode'));\n                              return;\n                            }\n                            callback();\n                          },\n                        },\n                      ],\n                    },\n                  ]",
                                        },
                                      ],
                                      staticClass: "process-input",
                                      attrs: {
                                        size: "large",
                                        autocomplete: "off",
                                        placeholder: _vm.$t("inputCode"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div"),
                ]
              : _vm._e(),
            _c(
              "a-form-item",
              {
                staticStyle: { "margin-top": "30px" },
                style: { "text-align": _vm.lang == "AR" ? "right" : "left" },
              },
              [
                _vm.new_linkedin_id || !_vm.firstReg
                  ? _c(
                      "a-button",
                      {
                        class: [_vm.lang],
                        attrs: { size: "large", type: "primary" },
                        on: { click: _vm.nextHandler },
                      },
                      [_vm._v(_vm._s(_vm.$t("NextStep")))]
                    )
                  : _vm._e(),
                _vm.processing &&
                (_vm.processStep.step1 == 1 ||
                  _vm.processStep.step2 == 1 ||
                  _vm.processStep.step3 == 1)
                  ? _c(
                      "a-button",
                      {
                        class: [_vm.lang],
                        attrs: { size: "large", type: "primary" },
                        on: { click: _vm.saveHandler },
                      },
                      [_vm._v(_vm._s(_vm.$t("ContinueConnect")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }