var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-toggle-transition",
    {
      attrs: {
        disabled: _vm.animate.disabled,
        animate: _vm.animate.name,
        direction: _vm.animate.direction,
      },
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }