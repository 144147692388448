// import { mapState, mapMutations } from "vuex";
import { mapState, mapMutations, mapGetters } from "vuex";
import { debounce } from "@/utils/util";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  data() {
    return {
      TimeZoneList: [],
      locationList: [],
      tableScrollY: "", // 表格高度
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
      isCountDown: false,
      countDownTimer: null,
      serverTime: 0,
      //   form: this.$form.createForm(this),
    };
  },
  computed: {
    numLg() {
      return this.num.toString().length;
    },
    ...mapState("setting", ["weekMode", "lang", "user"]),
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    setTimeout(() => {
      this.resize();
      window.addEventListener("resize", debounce(this.resize, 300), false);
    }, 1000);
  },
  destroy() {
    if (this.isCountDown && this.countDownTimer) {
      clearInterval(this.countDownTimer);
    }
    if (this.serviceTimer) {
      clearInterval(this.serviceTimer);
    }
  },
  activated() {
    if (document.querySelector("#popContainer")) {
      document.querySelector("#popContainer").scrollTop = 0;
    }
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    getUserInfo() {
      return new Promise((resolve, reject) => {
        this.$axios("/user/info", {}, "post")
          .then((res) => {
            if (res.code == 200) {
              // this.setUser({
              //   ...res.data,
              //   hr_paid: 0,
              //   sales_paid: 0,
              //   free_info: {
              //     account: "tet1232@qq.com",
              //     bind_type: "",
              //     start_date: "2024-11-15 23:28:28",
              //     end_date: "2024-11-25 23:28:28",
              //   },
              // });
              this.setUser(res.data);
              resolve();
            } else {
              reject();
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    countDown(time, days) {
      // 设置倒计时的最终时间点（这里以2023年1月1日为例）
      var countDownDate = new Date(time).getTime();
      if (!this.isCountDown) {
        return;
      }

      // var cur = new Date().getTime();
      var cur = this.serverTime;
      var sevendays = days
        ? parseInt(days) * 24 * 60 * 60 * 1000
        : 10 * 24 * 60 * 60 * 1000;
      var d1 = (cur - countDownDate) % sevendays;
      var d2 = Math.floor((cur - countDownDate) / sevendays);

      countDownDate = countDownDate + d2 * sevendays + (sevendays - d1) + d1;

      // 每隔一秒更新倒计时
      this.countDownTimer = setInterval(() => {
        // 获取当前的时间
        // var now = new Date().getTime();
        var now = this.serverTime;
        console.log(now, this.serverTime);

        // 计算差值
        var distance = countDownDate - now;

        // 时间为负数时，表示倒计时结束
        if (distance <= 0) {
          clearInterval(this.countDownTimer);
          this.countDown(new Date().getTime());
          // document.getElementById("countdown").innerHTML = "倒计时结束";
          return;
        }

        // 获取剩余的天、小时、分钟和秒
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.days = days.toString().length == 1 ? "0" + days : days;
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.hours = hours.toString().length == 1 ? "0" + hours : hours;

        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.minutes = minutes.toString().length == 1 ? "0" + minutes : minutes;

        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.seconds = seconds.toString().length == 1 ? "0" + seconds : seconds;
        console.log(this.days, this.hours, this.minutes, this.seconds);
        // // 输出到页面
        // document.getElementById("countdown").innerHTML =
        //   days +
        //   " 天 " +
        //   hours +
        //   " 小时 " +
        //   minutes +
        //   " 分钟 " +
        //   seconds +
        //   " 秒 ";
      }, 1000);
    },
    ...mapMutations("setting", ["setDevice"]),
    getTimeZone() {
      let lang = "";
      if (this.$store.state.setting.lang == "US") {
        lang = "en";
      } else if (this.$store.state.setting.lang == "CN") {
        lang = "zh";
      }
      this.$axios("/public/timezone_config", { lang }, "post").then((res) => {
        if (res.code == 200) {
          this.TimeZoneList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getLocationList() {
      let lang = "";
      if (this.$store.state.setting.lang == "US") {
        lang = "en";
      } else if (this.$store.state.setting.lang == "CN") {
        lang = "zh";
      }
      this.$axios("/public/country_config", { lang }, "post").then((res) => {
        if (res.code == 200) {
          this.locationList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resize() {
      this.resizeAntTable();
      // this.resizeGrid();
    },
    resizeAntTable() {
      // table 自适应
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;

      const $el = document.querySelector(".table-content-wrapper");
      let tabs = document.querySelector(".list-tabs");
      let planGuide = document.querySelector(".planGuide");
      let footer = document.querySelector(".newapp-footer");
      this.contentHeight = tabs ? h - 132 - 60 + "px" : h - 132 + "px";
      if ($el && this.computedTable) {
        const $antTableEl = $el.querySelector(".ant-table-wrapper");
        console.log($antTableEl);
        // if ($antTableEl) {
        const $advEl = $el.querySelector(".advanced-wrapper");
        // console.log($antTableEl)
        const $antTableHeaderEl =
          $antTableEl.querySelector(".ant-table-header");
        const $antTableTheadEl = $antTableEl.querySelector(
          ".ant-table-body .ant-table-thead"
        );
        this.tableScrollY =
          h -
          112 -
          68 -
          80 -
          (planGuide ? 217 : 0) -
          (footer ? footer.clientHeight + 25 : 0) -
          ($antTableHeaderEl ? $antTableHeaderEl.clientHeight : 0) -
          ($antTableTheadEl ? $antTableTheadEl.clientHeight : 0) -
          ($advEl ? $advEl.clientHeight : 0);
        console.log("this.tableScrollY", this.tableScrollY);
        // }
      } else {
        this.tableScrollY = "";
      }
    },
    formatTimeZoneTime(date, fomat = "YYYY/MM/DD HH:mm:ss") {
      // 假设要转换的东八区时间是 "2023-04-01 12:00:00"
      const beijingTime = date;

      // 将东八区时间转换为用户所在时区的时间
      // 假设用户所在时区为 "America/New_York"
      //const userTimezone = "America/New_York";
      //const userTimezone = "Asia/Tokyo";
      //获取用户所在时区
      const userTimezone = dayjs.tz.guess();
      const userLocalTime = dayjs(beijingTime)
        .tz("Etc/GMT-8")
        .tz(userTimezone)
        .format(fomat);
      console.log(userLocalTime); // 输出用户所在时区的时间
      return userLocalTime;
    },

    getServiceTime() {
      return new Promise((resolve, reject) => {
        this.$axios("/public/current_time", {}, "post").then((res) => {
          if (res.code == 200) {
            this.serverTime = res.data.current_time;
            this.serviceTimer = setInterval(() => {
              this.serverTime += 1000;
            }, 1000);
            resolve(Date.now());
            //resolve(res.data.current_time);
          } else {
            reject();
          }
        });
      });
    },
  },
};
