var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-dropdown",
    [
      _c(
        "div",
        { staticClass: "header-avatar", staticStyle: { cursor: "pointer" } },
        [
          _c("a-avatar", {
            staticClass: "avatar",
            attrs: { size: "small", shape: "circle", src: _vm.user.head_img },
          }),
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.user.user_name)),
          ]),
        ],
        1
      ),
      _c(
        "a-menu",
        {
          class: ["avatar-menu"],
          staticStyle: { "z-index": "99999" },
          attrs: { slot: "overlay" },
          slot: "overlay",
        },
        [
          _c("a-menu-divider"),
          _c(
            "a-menu-item",
            { on: { click: _vm.logout } },
            [
              _c("a-icon", {
                staticStyle: { "margin-right": "8px" },
                attrs: { type: "poweroff" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.$t("LogOut")))]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }