var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newapp-footer" },
    [
      _vm.showPagination
        ? _c("a-pagination", {
            attrs: {
              size: "large",
              pageSizeOptions: _vm.pageSizeOptions,
              current: _vm.current,
              defaultPageSize: _vm.defaultPageSize,
              total: _vm.total,
              showSizeChanger: "",
              showQuickJumper: "",
            },
            on: {
              change: _vm.onPageChange,
              showSizeChange: _vm.onPageSizeChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }