var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("a-spin", { attrs: { size: "large", spinning: _vm.loading } }, [
        _c("div", { staticClass: "dashbordData" }, [
          _c("ul", [
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("TotalNumber"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.customer_find
                        ? _vm.dashBorderData.customer_find
                        : 0
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(" " + _vm._s(_vm.$t("ConnectionsRequest")) + " "),
              ]),
              _c("div", { staticClass: "data incluedRate" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.connections_request
                        ? _vm.dashBorderData.connections_request
                        : 0
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("ConnectionsMade"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.connections_made
                        ? _vm.dashBorderData.connections_made
                        : 0
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("Acceptedrate"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.accept_rate
                        ? _vm.dashBorderData.accept_rate
                        : 0
                    ) + "%"
                  ),
                ]),
              ]),
            ]),
            _c("li", { staticStyle: { "border-right": "none" } }, [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("NumberOfGreeters"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.greeting_count
                        ? _vm.dashBorderData.greeting_count
                        : 0
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("ul", { staticStyle: { "margin-top": "26px" } }, [
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("Replies"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.replies
                        ? _vm.dashBorderData.replies
                        : 0
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("Repliesrate"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.replies_rate
                        ? _vm.dashBorderData.replies_rate
                        : 0
                    ) + "%"
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("Message"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.message
                        ? _vm.dashBorderData.message
                        : 0
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("LeadsGenerated"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.leads ? _vm.dashBorderData.leads : 0
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", { staticStyle: { "border-right": "none" } }, [
              _c("div", { staticClass: "tit", class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("ConversionRate"))),
              ]),
              _c("div", { staticClass: "data" }, [
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(
                      _vm.dashBorderData.rate ? _vm.dashBorderData.rate : 0
                    ) + "%"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "title" },
          [
            _c("svg-icon", { attrs: { iconClass: "hot" } }),
            _c("span", { class: [_vm.lang] }, [
              _vm._v(_vm._s(_vm.$t("Hotconversataion"))),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "hotList",
            class: { noDataWrap: _vm.hotData.length == 0 ? true : false },
          },
          [
            _vm.hotData.length > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.hotData, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.skipDialog(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "user" }, [
                          item.head_img
                            ? _c("img", {
                                staticStyle: { cursor: "pointer" },
                                attrs: { src: item.head_img },
                                on: {
                                  click: function ($event) {
                                    return _vm.skipDialog(item)
                                  },
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/img/avat.png"),
                                },
                              }),
                          _c("span", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.customer_name)),
                          ]),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              class: [_vm.lang],
                              style: {
                                "min-width":
                                  _vm.lang == "US"
                                    ? "66px"
                                    : _vm.lang == "CN"
                                    ? "33px"
                                    : "33px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Company")) + ":")]
                          ),
                          _c("span", { staticClass: "val" }, [
                            _vm._v(_vm._s(item.company ? item.company : "--")),
                          ]),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c(
                            "span",
                            { staticClass: "label", class: [_vm.lang] },
                            [_vm._v(_vm._s(_vm.$t("Conversation")) + ":")]
                          ),
                        ]),
                        _c("div", { staticClass: "message" }, [
                          _vm._v(" " + _vm._s(item.content) + " "),
                        ]),
                        _c("div", { staticClass: "info m15" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              class: [_vm.lang],
                              style: {
                                "min-width":
                                  _vm.lang == "US"
                                    ? "66px"
                                    : _vm.lang == "CN"
                                    ? "33px"
                                    : "33px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("campaign")) + ":")]
                          ),
                          _c("span", { staticClass: "val" }, [
                            _vm._v(_vm._s(item.campaign_name)),
                          ]),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              class: [_vm.lang],
                              style: {
                                "min-width":
                                  _vm.lang == "US"
                                    ? "105px"
                                    : _vm.lang == "CN"
                                    ? "60px"
                                    : "80px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("LinkedIn")) + ":")]
                          ),
                          _c("span", { staticClass: "val" }, [
                            _vm._v(_vm._s(item.linkedin_email)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c("NoData", {
                  attrs: { lang: _vm.lang, text: _vm.$t("noHotData") },
                }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wrap-table" },
          [
            _c("div", { staticClass: "title title2" }, [
              _c(
                "div",
                [
                  _c("svg-icon", { attrs: { iconClass: "active" } }),
                  _c("span", { class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("RecentCampaign"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "campaignsDetail", on: { click: _vm.goMore } },
                [
                  _c("span", { class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("ViewAllCampaigns"))),
                  ]),
                  _c("a-icon", { staticClass: "ir", attrs: { type: "right" } }),
                ],
                1
              ),
            ]),
            _c("a-table", {
              class: { arTable: _vm.lang == "AR" ? true : false },
              attrs: {
                scroll: {
                  x: 1120,
                },
                locale: { emptyText: _vm.$t("noCamp") },
                size: "middle",
                columns: _vm.columns,
                rowKey: (record, index) => index,
                "data-source": _vm.capinData,
                pagination: false,
                customRow: (record) => {
                  return {
                    on: {
                      click: () => {
                        _vm.goDetail(record)
                      },
                    },
                  }
                },
                loading: _vm.loading,
                "row-class-name": (_record, index) =>
                  index % 2 !== 1 ? "table-bg" : null,
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function (text, record) {
                    return [
                      _c("div", { staticClass: "wrap-bar" }, [
                        _c(
                          "div",
                          {
                            staticClass: "statusbox",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            record.status == "1"
                              ? _c("span", { class: [_vm.lang] }, [
                                  _vm._v(_vm._s(_vm.$t("Active"))),
                                ])
                              : _vm._e(),
                            record.status == "0"
                              ? _c("span", { class: [_vm.lang] }, [
                                  _vm._v(_vm._s(_vm.$t("Paused"))),
                                ])
                              : _vm._e(),
                            record.status == "1"
                              ? _c("img", {
                                  staticClass: "swing",
                                  attrs: { src: require("@/assets/img/s.png") },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("a-switch", {
                              attrs: { size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeRecord(record)
                                },
                              },
                              model: {
                                value: record.status,
                                callback: function ($$v) {
                                  _vm.$set(record, "status", $$v)
                                },
                                expression: "record.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "action",
                  fn: function (text, record) {
                    return [
                      _c(
                        "a-dropdown",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "overlay",
                                fn: function () {
                                  return [
                                    _c(
                                      "a-menu",
                                      [
                                        _c(
                                          "a-menu-item",
                                          {
                                            key: "1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goDetail(record)
                                              },
                                            },
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "edit" },
                                            }),
                                            _c("span", { class: [_vm.lang] }, [
                                              _vm._v(_vm._s(_vm.$t("Details"))),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-menu-item",
                                          {
                                            key: "2",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyHandler(record)
                                              },
                                            },
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "copy" },
                                            }),
                                            _c("span", { class: [_vm.lang] }, [
                                              _vm._v(_vm._s(_vm.$t("Copy"))),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-menu-item",
                                          {
                                            key: "3",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteHandler(record)
                                              },
                                            },
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "delete" },
                                            }),
                                            _c("span", { class: [_vm.lang] }, [
                                              _vm._v(_vm._s(_vm.$t("Delete"))),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { padding: "0 8px" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("span", { class: [_vm.lang] }, [
                                _vm._v(_vm._s(_vm.$t("Action"))),
                              ]),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }