import { render, staticRenderFns } from "./Contextmenu.vue?vue&type=template&id=113a23ce&scoped=true"
import script from "./Contextmenu.vue?vue&type=script&lang=js"
export * from "./Contextmenu.vue?vue&type=script&lang=js"
import style0 from "./Contextmenu.vue?vue&type=style&index=0&id=113a23ce&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113a23ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/saas_fe_dev_pay/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('113a23ce')) {
      api.createRecord('113a23ce', component.options)
    } else {
      api.reload('113a23ce', component.options)
    }
    module.hot.accept("./Contextmenu.vue?vue&type=template&id=113a23ce&scoped=true", function () {
      api.rerender('113a23ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/menu/Contextmenu.vue"
export default component.exports