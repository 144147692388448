var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      [
        _c(
          "a-select",
          {
            staticClass: "select",
            class: [_vm.lang],
            staticStyle: { width: "440px", "margin-right": "5px" },
            attrs: { size: "large", placeholder: _vm.$t("inputLinkAccount") },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.linkedin_email,
              callback: function ($$v) {
                _vm.linkedin_email = $$v
              },
              expression: "linkedin_email",
            },
          },
          [
            _c("a-select-option", { class: [_vm.lang], attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm.$t("inputLinkAccount"))),
            ]),
            _vm._l(_vm.linkList, function (item, index) {
              return _c(
                "a-select-option",
                {
                  key: index,
                  class: [_vm.lang],
                  attrs: { value: item.linkedin_id },
                },
                [_vm._v(" " + _vm._s(item.linkedin_email) + " ")]
              )
            }),
          ],
          2
        ),
        _c(
          "a-button",
          {
            class: [_vm.lang],
            attrs: { type: "primary", size: "large" },
            on: { click: _vm.createCampaign },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Create")) + " ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "wrap-table", staticStyle: { "min-height": "650px" } },
      [
        _c("ul", { staticClass: "nav" }, [
          _c(
            "li",
            {
              staticClass: "left",
              class: { active: _vm.leftActive },
              on: {
                click: function ($event) {
                  return _vm.tabHandler("left")
                },
              },
            },
            [
              _c("span", { class: [_vm.lang] }, [
                _vm._v(_vm._s(_vm.$t("Active"))),
              ]),
            ]
          ),
          _c(
            "li",
            {
              staticClass: "right",
              class: { active: _vm.rightActive },
              on: {
                click: function ($event) {
                  return _vm.tabHandler("right")
                },
              },
            },
            [
              _c("span", { class: [_vm.lang] }, [
                _vm._v(" " + _vm._s(_vm.$t("Paused")) + " "),
              ]),
            ]
          ),
        ]),
        _c("a-table", {
          staticClass: "custom-scrollbar",
          class: { arTable: _vm.lang == "AR" ? true : false },
          attrs: {
            scroll: {
              x: 1120,
            },
            size: "middle",
            columns: _vm.columns,
            rowKey: (record, index) => index,
            "data-source": _vm.tableData,
            pagination: false,
            loading: _vm.loading,
            locale: { emptyText: _vm.$t("noCamp") },
            "row-class-name": (_record, index) =>
              index % 2 !== 1 ? "table-bg" : null,
            customRow: (record) => {
              return {
                on: {
                  click: () => _vm.goDetail(record),
                },
              }
            },
          },
          scopedSlots: _vm._u([
            {
              key: "status",
              fn: function (text, record) {
                return [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c("a-switch", {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                          change: function ($event) {
                            return _vm.onChangeRecord(record)
                          },
                        },
                        model: {
                          value: record.status,
                          callback: function ($$v) {
                            _vm.$set(record, "status", $$v)
                          },
                          expression: "record.status",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "action",
              fn: function (text, record) {
                return [
                  _c(
                    "a-dropdown",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "overlay",
                            fn: function () {
                              return [
                                _c(
                                  "a-menu",
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(record)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "edit" },
                                        }),
                                        _c("span", { class: [_vm.lang] }, [
                                          _vm._v(_vm._s(_vm.$t("Details"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyHandler(record)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "copy" },
                                        }),
                                        _c("span", { class: [_vm.lang] }, [
                                          _vm._v(_vm._s(_vm.$t("Copy"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "3",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteHandler(record)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "delete" },
                                        }),
                                        _c("span", { class: [_vm.lang] }, [
                                          _vm._v(_vm._s(_vm.$t("Delete"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { padding: "0 8px" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c("span", { class: [_vm.lang] }, [
                            _vm._v(_vm._s(_vm.$t("Action"))),
                          ]),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
        _c(
          "div",
          [
            _c("app-footer", {
              attrs: {
                current: _vm.page,
                pageSize: _vm.pageSize,
                total: _vm.total,
              },
              on: {
                onPageChange: _vm.onPageChange,
                onPageSizeChange: _vm.onPageSizeChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }