var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("exception-page", {
    style: `min-height: ${_vm.minHeight}`,
    attrs: { "home-route": "/demo", type: "500" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }