var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-layout-sider",
    {
      class: ["side-menu", "beauty-scroll", _vm.isMobile ? null : "shadow"],
      attrs: {
        theme: _vm.sideTheme,
        width: "254px",
        collapsible: _vm.collapsible,
        trigger: null,
      },
      model: {
        value: _vm.collapsed,
        callback: function ($$v) {
          _vm.collapsed = $$v
        },
        expression: "collapsed",
      },
    },
    [
      _c(
        "div",
        { class: ["logo", _vm.theme], attrs: { id: "logo" } },
        [
          _c(
            "router-link",
            { attrs: { to: "/sales/salesReps/home" } },
            [
              _c("svg-icon", {
                staticClass: "logo",
                staticStyle: {
                  width: "218px",
                  height: "31px",
                  background: "none",
                  "padding-left": "0",
                  "margin-left": "-16px",
                  "margin-top": "15px",
                },
                attrs: { iconClass: "logo" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("i-menu", {
        staticClass: "menu",
        attrs: {
          theme: _vm.theme,
          collapsed: _vm.collapsed,
          options: _vm.menuData,
        },
        on: { select: _vm.onSelect },
      }),
      !_vm.showBtn
        ? _c(
            "a-button",
            {
              staticClass: "addBtn",
              class: [_vm.lang],
              attrs: { type: "primary" },
              on: { click: _vm.addHandler },
            },
            [_vm._v(_vm._s(_vm.$t("AddLinkedInaccount")))]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "QR-code-box", staticStyle: { "padding-top": "20px" } },
        [
          _c("img", {
            attrs: {
              src: "https://u-img.strategybrain.ca/images/static/linkedIn.jpg",
              alt: "",
            },
          }),
          _c("div", { staticClass: "code-txt" }, [
            _vm._v("Follow the LinkedIn company page."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }