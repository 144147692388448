<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <template v-if="user.sales_paid == 1">
        <a-form layout="inline" :form="form" class="wrap-form">
          <a-form-item class="form-item">
            <div class="wrap-input">
              <span class="span" :class="[lang]">{{ $t("date") }}</span>
              <a-range-picker
                @change="handleSelectChange"
                size="large"
                style="width: 340px"
                format="YYYY/MM/DD"
                valueFormat="YYYY/MM/DD"
                v-decorator="['date']"
                ><a-icon
                  style="color: #0e756a"
                  slot="suffixIcon"
                  type="calendar"
              /></a-range-picker>
            </div>
          </a-form-item>
          <a-form-item class="form-item">
            <div class="wrap-input">
              <span class="span" :class="[lang]">{{
                $t("filterLinkedinAccount")
              }}</span>
              <a-select
                :class="[lang]"
                style="width: 360px"
                size="large"
                v-model="linkedin_id"
                placeholder="Select a option and change input text above"
                @change="handleSelectChange"
              >
                <a-select-option value="" :class="[lang]">
                  {{ $t("All") }}
                </a-select-option>
                <a-select-option
                  :class="[lang]"
                  v-for="(item, index) in linkList"
                  :key="index"
                  :value="item.linkedin_id"
                >
                  {{ item.linkedin_email }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-item>
        </a-form>
        <div class="dashbordData">
          <ul>
            <li>
              <div class="tit" :class="[lang]">{{ $t("TotalNumber") }}</div>
              <div class="data">
                <span class="number">{{
                  detailData.customer_find ? detailData.customer_find : 0
                }}</span>
              </div>
            </li>
            <li>
              <div class="tit" :class="[lang]">
                {{ $t("ConnectionsRequest") }}
              </div>
              <div class="data incluedRate">
                <span class="number">{{
                  detailData.connections_request
                    ? detailData.connections_request
                    : 0
                }}</span>
              </div>
            </li>
            <li>
              <div class="tit" :class="[lang]">{{ $t("ConnectionsMade") }}</div>
              <div class="data">
                <span class="number">{{
                  detailData.connections_made ? detailData.connections_made : 0
                }}</span>
              </div>
            </li>
            <li>
              <div class="tit" :class="[lang]">{{ $t("Acceptedrate") }}</div>
              <div class="data">
                <span class="number"
                  >{{
                    detailData.accept_rate ? detailData.accept_rate : 0
                  }}%</span
                >
              </div>
            </li>
            <li style="border-right: none">
              <div class="tit" :class="[lang]">
                {{ $t("NumberOfGreeters") }}
              </div>
              <div class="data">
                <span class="number">{{
                  detailData.greeting_count ? detailData.greeting_count : 0
                }}</span>
              </div>
            </li>
          </ul>
          <ul style="margin-top: 26px">
            <li>
              <div class="tit" :class="[lang]">{{ $t("Replies") }}</div>
              <div class="data">
                <span class="number">{{
                  detailData.replies ? detailData.replies : 0
                }}</span>
              </div>
            </li>
            <li>
              <div class="tit" :class="[lang]">{{ $t("Repliesrate") }}</div>
              <div class="data">
                <span class="number"
                  >{{
                    detailData.replies_rate ? detailData.replies_rate : 0
                  }}%</span
                >
              </div>
            </li>
            <li>
              <div class="tit" :class="[lang]">{{ $t("Message") }}</div>
              <div class="data">
                <span class="number">{{
                  detailData.message ? detailData.message : 0
                }}</span>
              </div>
            </li>
            <li>
              <div class="tit" :class="[lang]">{{ $t("LeadsGenerated") }}</div>
              <div class="data">
                <span class="number">{{
                  detailData.leads ? detailData.leads : 0
                }}</span>
              </div>
            </li>
            <li style="border-right: none">
              <div class="tit" :class="[lang]">{{ $t("ConversionRate") }}</div>
              <div class="data">
                <span class="number"
                  >{{ detailData.rate ? detailData.rate : 0 }}%</span
                >
              </div>
            </li>

            <!-- <li style="border-right: none">
            <div class="tit" :class="[lang]">{{ $t("ExistingFriends") }}</div>
            <div class="data">
              <span class="number">{{
                detailData.existing_friends ? detailData.existing_friends : 0
              }}</span>
            </div>
          </li>
          <li></li> -->
          </ul>
        </div>
        <div class="wrap-table">
          <div class="title">
            <svg-icon iconClass="active"></svg-icon
            ><span :class="[lang]">{{ $t("campaign") }}</span>
          </div>
          <div>
            <a-table
              :scroll="{
                x: 1120,
              }"
              :class="{ arTable: lang == 'AR' ? true : false }"
              size="middle"
              :columns="columns"
              :rowKey="(record, index) => index"
              :data-source="tableData"
              :pagination="false"
              :loading="loading"
              :row-class-name="
                (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
              "
            >
              <template slot="status" slot-scope="text, record" class="arial">
                <div v-show="record.status == 1" class="status active">
                  <i :class="{ rtl: lang == 'AR' ? true : false }"></i>
                  <span :class="lang">{{ $t("Active") }}</span>
                </div>
                <div v-show="record.status == 0" class="status paused">
                  <i :class="{ rtl: lang == 'AR' ? true : false }"></i>
                  <span :class="lang">{{ $t("Paused") }}</span>
                </div>
              </template>
            </a-table>
          </div>
          <div>
            <app-footer
              :current="page"
              :pageSize="pageSize"
              :total="total"
              @onPageChange="onPageChange"
              @onPageSizeChange="onPageSizeChange"
            ></app-footer>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="nopay">
          <div class="wrapText"></div>
          <div class="textDes">
            <div class="tit" :class="[lang]">{{ $t("WhyUpgrade") }}</div>
            <p :style="{ paddingTop: lang == 'CN' ? '57px' : '42px' }">
              {{ $t("upgradeTitle") }}
            </p>
          </div>
          <ul>
            <li style="margin-left: 0px">
              <div class="time">{{ days }}</div>
              <div class="flag">{{ $t("day") }}</div>
            </li>
            <li>
              <div class="time">{{ hours }}</div>
              <div class="flag">{{ $t("Hrs") }}</div>
            </li>
            <li>
              <div class="time">{{ minutes }}</div>
              <div class="flag">{{ $t("Mins") }}</div>
              <div class="split">:</div>
            </li>
            <li>
              <div class="time">{{ seconds }}</div>
              <div class="flag">{{ $t("Secs") }}</div>
              <div class="split">:</div>
            </li>
          </ul>
          <div class="btn">
            <span @click="skipPay" :class="[lang]">{{ $t("UpgradeNow") }}</span>
          </div>
        </div>
      </template>
    </div>
  </a-spin>
</template>

<script>
import BaseComponent from "@/components/base.component";
export default {
  data() {
    return {
      linkedin_id: "",
      //havePay: true,
      loading: false,
      form: this.$form.createForm(this, { name: "bordeForm" }),
      tableData: [],
      detailData: {},
      linkList: [],
      isCountDown: false,
    };
  },
  mixins: [BaseComponent],
  i18n: require("./i18n"),
  computed: {
    columns: {
      get() {
        let that = this;
        return [
          {
            title: that.$t("Name"),
            width: 200,
            dataIndex: "campaign_name",
            align: "center",
            fixed: "left",
            ellipsis: true,
          },
          {
            title: that.$t("Status"),
            width: 160,
            dataIndex: "status",
            align: "center",
            fixed: "left",
            scopedSlots: {
              customRender: "status",
            },
          },
          {
            title: that.$t("LinkedinlD"),
            width: 200,
            dataIndex: "linkedin_email",
            align: "center",
            ellipsis: true,
          },
          {
            title: that.$t("Days"),
            width: 120,
            dataIndex: "days",
            align: "center",
          },
          {
            title: that.$t("TotalNumber"),
            width: that.lang == "US" ? 260 : that.lang == "AR" ? 200 : 120,
            dataIndex: "customer_find",
            align: "center",
          },
          {
            title: that.$t("ConnectionsRequest"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "connections_request",
            align: "center",
          },
          {
            title: that.$t("ConnectionsMade"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "connections_made",
            align: "center",
          },

          {
            title: that.$t("Acceptedrate"),
            width: 120,
            dataIndex: "accept_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
          },
          {
            title: that.$t("NumberOfGreeters"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "greeting_count",
            align: "center",
          },

          {
            title: that.$t("Replies"),
            width: 120,
            dataIndex: "replies",
            align: "center",
          },
          {
            title: that.$t("Repliesrate"),
            width: that.lang == "US" ? 160 : that.lang == "AR" ? 200 : 120,
            dataIndex: "replies_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
          },
          {
            title: that.$t("Message"),
            width: 120,
            dataIndex: "message",
            align: "center",
          },
          {
            title: that.$t("LeadsGenerated"),
            width: that.lang == "US" ? 120 : that.lang == "AR" ? 200 : 120,
            dataIndex: "leads",
            align: "center",
          },
          {
            title: that.$t("ConversionRate"),
            width: 120,
            dataIndex: "rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
          },
          // {
          //   title: that.$t("ConversionRate"),
          //   width: 120,
          //   dataIndex: "ConversionRate",
          //   align: "center",
          // },
          // {
          //   title: that.$t("Action"),
          //   align: "center",
          //   fixed: "right",
          //   width: 120,
          //   scopedSlots: {
          //     customRender: "action",
          //   },
          // },
        ];
      },
    },
    // havePay:{
    //   get(){
    //     let {sales_paid} = this.user
    //   }
    // }
  },
  created() {
    this.initData();
    this.isCountDown = this.user.sales_paid == 0 ? true : false;
    if (this.isCountDown) {
      this.loading = true;
      let { start_date, end_date } = (this.user && this.user.free_info) || {};
      let start = new Date(start_date).getTime();
      let end = new Date(end_date).getTime();
      let days = Math.ceil((end - start) / (24 * 60 * 60 * 1000));
      this.getServiceTime()
        .then(() => {
          this.countDown(this.user.create_date, days);
          this.loading = false;
        })
        .catech(() => {
          this.loading = false;
        });
    }
  },
  activated() {
    // this.countDown(this.user.create_date);
  },
  methods: {
    skipPay() {
      this.$router.push({
        path: "/setting/userSetting/plan",
        query: {
          // 有账户时可以在付完费直接绑定
          upgrade_free: this.user.free_info.account ? 1 : 0,
          campaign_type: 1,
        },
      });
    },
    initData() {
      console.log(this.user);
      if (this.user.sales_paid == 1) {
        this.form.resetFields();
        this.form.resetFile;
        this.getData();
        this.getLinkList();
      }
    },
    getLinkList() {
      this.$axios(
        "/linkedin/list",
        { campaign_type: 1, status: 1 },
        "post"
      ).then((res) => {
        if (res.code == 200) {
          this.linkList = res.data;
          if (res.data.length == 0) {
            this.linkedin_email = "new";
          } else {
            this.linkedin_email = "selectedDaata";
          }
        }
      });
    },
    handleSelectChange(val) {
      this.page = 1;
      this.pageSize = 10;
      setTimeout(() => {
        this.getData();
      }, 300);
      console.log(val);
    },
    skipDialog() {
      this.$router.push({ path: "/sales/salesReps/dialogue" });
    },
    onChangeRecord(record) {
      console.log(record, arguments);
    },
    handleMenuClick(val) {
      console.log(val, arguments);
    },
    getData() {
      let params = this.form.getFieldsValue();

      let { date } = params;
      let [start_time, end_time] = date || [];
      let postData = {
        linkedin_id: this.linkedin_id ? parseInt(this.linkedin_id) : null,
        start_time,
        end_time,
        page: this.page,
        page_size: this.pageSize,
      };
      this.loading = true;
      this.$axios("/sales/sales_dashboard", postData, "post")
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            let { campaign_list, total_num, page_size } = res.data || {};
            this.detailData = res.data;
            this.tableData = campaign_list;
            // this.data = res.data;
            this.total = total_num;
            this.pageCount = Math.ceil(total_num / page_size);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 35px 30px 30px 30px;
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .wrap-form {
    margin-bottom: 30px;
    .wrap-input {
      display: flex;
      align-items: center;
      margin-right: 60px;
      .span {
        margin-right: 14px;
      }
    }
  }
  .dashbordData {
    background: #fff;
    padding: 26px 0;

    ul {
      display: flex;
    }
    li {
      height: 64px;
      list-style: none;
      flex: 1;
      text-align: center;
      border-right: solid 1px #e5e5e5;
      .tit {
        font-family: Arial, Arial;
        font-weight: 400;
        font-size: 14px;
        color: #80829f;
      }
      .data {
        margin-top: 7px;
        height: 41px;
        span {
          font-family: Arial, Arial;
          font-weight: bold;
          display: inline-block;
          font-size: 36px;
          height: 36px;
          line-height: 36px;
          color: #0e756a;
        }
      }
      .incluedRate {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        p {
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: 12px;
          color: #0094ff;
          background: #e5f4ff;
          border-radius: 2px 2px 2px 2px;
          padding: 1px 5px;
          margin-left: 7px;
          margin-bottom: 6px;
          text-align: left;
        }
      }
    }
  }
  .title {
    padding: 20px 0 14px 0;
    span {
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .hotList {
    border-radius: 4px;
    background: #fff;
    min-height: 292px;
    ul {
      display: flex;
      li {
        flex: 1;
        padding: 22px;
        width: 20%;
        border: #fff 1px solid;
        .user {
          display: flex;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #ccc;
          }
          .name {
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 16px;
            color: #000000;
            margin-left: 14px;
          }
        }
        .info {
          margin-top: 20px;
          .label {
            font-family: Arial, Arial;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
          }
          .val {
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 13px;
          }
        }
        .m15 {
          margin-top: 15px;
        }
        .message {
          background: #f3f3f3;
          border-radius: 4px;
          padding: 11px;
          line-height: 24px;
          margin-top: 6px;
        }
      }
      li:hover {
        box-shadow: 0px 0px 14px 1px rgba(14, 117, 106, 0.3);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0e756a;
      }
    }
  }
  .wrap-table {
    padding: 0px 20px 12px 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;
    min-height: 485px;
    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
      }
      .rtl {
        margin-right: 0px;
        margin-left: 5px;
      }
    }
    .active {
      color: #0e756a;
      i {
        background: #0e756a;
      }
    }
    .paused {
      color: #cc1d1d;
      i {
        background: #cc1d1d;
      }
    }
  }
  /deep/.ant-table-row-hover {
    background: rgba(238, 248, 247, 1) !important;
  }
  /deep/.ant-btn {
    background: #0c6158;
    color: #fff;
  }
  .nopay {
    width: 875px;
    height: 869px;
    background: url(../../../assets/img/planPaybg1.png) no-repeat;
    margin: 0 auto;
    .wrapText {
      padding-top: 420px;
    }
    .textDes {
      width: 636px;
      height: 140px;
      margin: 0 auto;
      background: url(../../../assets/img/textBg.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      .tit {
        width: 292px;
        height: 42px;
        background: url(../../../assets/img/tit.png) no-repeat;
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      p {
        // width: 538px;
        height: 58px;
        margin: 0 auto;
        padding-top: 42px;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        color: #92580c;
        line-height: 30px;
        margin-bottom: 0;
      }
    }

    ul {
      width: 370px;
      margin: 0 auto;
    }
    ul {
      display: flex;
      margin-top: 12px;

      li {
        display: inline-block;
        width: 70px;
        height: 79px;
        margin-left: 30px;
        border-radius: 7px;
        background: #fff;
        text-align: center;
        position: relative;
        .time {
          margin-top: 10px;
          font-weight: bold;
          font-size: 36px;
          color: #ff7700;
          height: 41px;
          line-height: 41px;
        }
        .flag {
          width: 100%;
          font-weight: bold;
          margin-top: 4px;
          font-size: 12px;
          color: #5d3c10;
          height: 14px;
          line-height: 14px;
          text-align: center;
        }
        .split {
          height: 79px;
          line-height: 79px;
          color: #92580c;
          font-weight: bold;
          font-size: 36px;
          position: absolute;
          left: -20px;
          top: 0;
        }
      }
    }
    .btn {
      width: 408px;
      height: 66px;
      background: linear-gradient(85deg, #ffac00 0%, #f85760 100%);
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.16),
        inset 0px 3px 6px 1px rgba(255, 255, 255, 0.57);
      border-radius: 100px 100px 100px 100px;
      border: 4px solid #ffffff;
      text-align: center;
      line-height: 66px;
      margin: 0 auto;
      margin-top: 38px;
      cursor: pointer;
      span {
        font-weight: 900;
        font-size: 24px;
        color: #ffffff;
        line-height: 0px;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
  @media screen and (max-width: 1680px) {
    .nopay {
      scale: 0.7;
      margin-top: -135px;
    }
  }
  .form-item {
    display: inline-block;
  }
}
</style>
