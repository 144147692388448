import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=6db86b6f&scoped=true"
import script from "./dashboard.vue?vue&type=script&lang=js"
export * from "./dashboard.vue?vue&type=script&lang=js"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=6db86b6f&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db86b6f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/saas_fe_dev_pay/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6db86b6f')) {
      api.createRecord('6db86b6f', component.options)
    } else {
      api.reload('6db86b6f', component.options)
    }
    module.hot.accept("./dashboard.vue?vue&type=template&id=6db86b6f&scoped=true", function () {
      api.rerender('6db86b6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/hr/recruiter/dashboard.vue"
export default component.exports