module.exports = {
  messages: {
    CN: {
      ChooseYourCustomerSearchMethod: "候选人查找方式",
      InputBasicInformation: "职位信息录入",
      CampaignsSettings: "任务设置",
      Pleaseenterthecampaignname: "请输入任务名称",
      SaveandContinue: "保存并继续",
      back: "返回",
      SearchMethodSelection: "搜索方式选择",
      ConnectYourLinkedInAccount: "绑定领英账号",
      ConfigureLinkedInProxy: "代理设置",
      SelectfromYourConnectedContacts: "一度人脉：从你的好友中选择",
      SearchByUrl: "领英URL搜索：通过领英搜索URL寻找目标客户",
      bindLinkAcc: "绑定领英账号",
      linkEmal: "邮箱",
      linkPwd: "密码",
      timeZone: "时区",
      ConfigureLinkedInProx: "代理设置",
      ManualConfiguration: "人工配置",
      SelectProxyRegion: "代理区域",
      Connect: "连接",
      EnterLinkedInSearchURL: "请输入领英搜索URL",
      CompanyInformation: `公司介绍`,
      CompanyInformationCont: `基础信息：StrategyBrain是一家致力于通过人工智能技术提高中小企业人力资源部门员工工作能力，推动中小企业改善人力资源成本，提高中小企业市场竞争力的创新公司。
官方网站：www.strategybrain.ca
地址：StrategyBrain位于加拿大多伦多。
公司介绍：StrategyBrain致力于用最前沿的AI科技来解决实际问题，降低社会的不平等，推动社会进步。这种文化认同和社会责任感为公司在实现其使命、愿景和价值观的过程中提供了坚实的基础。`,
      PositionName: "职位名称",
      PositionNamePlaceholder: `请输入职位名称`,
      ReportTo: '汇报对象',
      ReportToPlaceholder: `请输入该职位的汇报对象`,
      jobResponsibilities:"岗位职责",
      jobResponsibilitiesPlaceholder: `请输入该职位的具体职责`,
      jobRequirements:"岗位要求",
      jobRequirementsPlaceholder: `请输入该岗位的要求
经验要求：
教育背景：
技能要求：`,
      jobRequirementsErr: '请输入该岗位的要求',
      WorkLocation: '工作地点',
      WorkLocationPlaceholder: '请输入该职位的工作地点',
      wayOfWorking:"工作方式",
      wayOfWorkingErr: '请选择工作方式',
      wayOfWorkingLabel: '现场办公',
      wayOfWorkingLabel1: '远程办公',
      wayOfWorkingLabel2: '混合办公',
      wayOfWorkingLabel3: '自由职业',
      wayOfWorkingLabel4: '轮班制',
      wayOfWorkingLabel5: '兼职',
      salaryRange: '薪资范围',
      salaryRangeSelectPlaceholder: '请选择货币类型',
      minSalary: '最低薪资',
      maxSalary: '最高薪资',
      salaryRangeErr: '最高薪资不能低于最低薪资',
      welfareTreatment: '福利待遇',
      welfareTreatmentPlaceholder: '请输入福利待遇',

      ContactInformation: "联系方式",
      ContactInformationCont: `邮箱：
电话：`,
      addMore: "添加更多信息",
      CustomContent: "自定义内容",
      inputCustomContent: "请输入自定义内容",
      BriefSummary: "简短总结",
      BriefSummaryIntro: `简短总结能够帮助strategyBrain更好的理解你的公司和产品，请确保包含所有的关键点`,
      SetConversationLimits: "对话限制",
      SetConversationLimitsCont:
        "对话限制可以告诉AI不能跟用户进行哪些方面的对话",
      sendLimit: "每日发送添加好友请求数",
      dianzan: "给用户的第一条post点赞",
      capaginName: "任务名称",

      inputLinkAccount: "请选择LinkedIn账号",
      inputContent: "请输入内容",
      rightAccount: "请输入正确的邮箱领英账号",
      inputPwd: "请输入密码",
      selectTimezone: "请选择时区",
      selectRegion: "请选择代理区域",
      selectUrl: "请输入正确的LinkedIn URL",
      editTitle: "请编辑自定义标题",
      shortDes: "请输入简短总结",
      dialogLimit: "请输入对话限制",
      All: "所有的",
      bindTips:
        "请准备好确认您LinkedIn账户的PIN码。该PIN码将由LinkedIn发送到LinkedIn账户的邮箱，具体取决于安全设置，也可能通过短信发送。",
      inputCode: "请输入验证码",
      confirmDel: "确认要删除此任务",
      NextStep: "下一步",

      Account: "账号",
      TimeZone: "时区",
      Proxy: "代理",
      Connected: "已连接",
      ObtainProxyIP: "获取代理IP",
      VerifyLink: "校验LinkedIn账号密码",
      LinkedInAccountLink: "LinkedIn账号链接",
      ContinueConnect: "继续链接",
      selectHistory: "可以选择历史记录",
      findUrl: "如何找到LinkedIn URL",
      loginAccount: "登录您的LinkedIn账号",
      Enterkeywords: "在搜索框中输入关键词，点击enter或者see all results",
      SelectThePeople:
        "选择people筛选，同时在Filter中设置更多搜索条件，点击show results",
      copyURL: "复制导航栏中的URL地址，到此输入框中",
      ReferenceDocument: "参考文档",
      conversationRestrictions: "请输入对话限制，例如讲笑话",
      Save: "保存",
      SelectThePeopleRed:
        '请注意：在使用Filter时，请进行connections的选择，如果您想让机器人给已有好友发送消息，则勾选上1st。如果不想让机器人给已有好友发送消息，请取消选中，只选择2nd和3rd+。"',
      bindLinkAccTipTitle1: "如何绑定LinkedIn账号？",
      bindLinkAccTipcon1:
        "输入您正确的LinkedIn账号，密码，并选择时区和代理，点击开始连接，系统会开始进行LinkedIn账号的校验。如遇中断，请根据提示进行操作。仍无法绑定成功，可联系我们的客户支持。",
      bindLinkAccTipTitle2: "是否支持所有类型的LinkedIn账号？",
      bindLinkAccTipcon2:
        "不是。在绑定LinkedIn账号的时候，请先校验下您的账号是否支持添加好友，若您的账号暂时无法添加好友，创建任务后机器人将无法执行该任务。",
      bindLinkAccTipcon3:
        "您可以先提升LinkedIn的活跃度，例如完善个人资料，发布分享内容，积极与他人互动等。解锁添加好友的权限后再进行尝试。",
      bindLinkAccTipBtn: "点击",
      bindLinkAccTipBtnTxt: "查看详情文档",
      step3TipTitle: "添加好友限制",
      step3TipCon:
        "  为了防止触发LinkedIn账号风控，请尽量控制每个账号每日添加好友的上限，",
      step3TipCon1:
        "普通账号每日最好不要超过20人，navigator账号每日最好不要超过35人。",
      step3TipTitle2: "账号工作时间",
      step3TipCon2:
        "每个LinkedIn账号会根据您绑定账号时选择的代理区域，按照当地的工作时间实行8小时工作制。账号会在工作时间内执行搜索，添加好友，与好友对话等事项，超出工作时间将暂停工作，直至第二天工作时间重新开启。",
      Confirm: "确认",
      Cancel: "取消",
      TheAccountIsBeingBound: "账号正在绑定中，确认要离开么?",
      emailResume: "是否需要向邮箱发发送简历",
      Yes: "是",
      No: "否",
      emailResumeYesTip: "选择是，则机器人要求用户必须提供联系方式，并向上面填写的邮箱发送简历",
      emailResumeNoTip: "选择否，则机器人要求用户必须提供联系方式，不强制要求向邮箱发送简历，可接收线上简历",
      enterEmail: "请输入邮箱"
    },
    US: {
      ChooseYourCustomerSearchMethod: "Choose Your Candidate Search Method",
      InputBasicInformation:
        "Input Basic Position Information Before Launching Campaigns",
      CampaignsSettings: "Campaigns Settings",
      Pleaseenterthecampaignname: "Please enter the campaign name",
      SaveandContinue: "Save and Continue",
      back: "Back",
      SearchMethodSelection: "Search Method Selection",
      ConnectYourLinkedInAccount: "Connect Your LinkedIn Account",
      ConfigureLinkedInProxy: "Configure LinkedIn Proxy",
      SelectfromYourConnectedContacts: "Select from Your Connected Contacts",
      SearchByUrl: "LinkedIn URL Search: Find Target Customers via URL",
      bindLinkAcc: "Connect Your LinkedIn Account",
      linkEmal: "LinkedIn Email Address",
      linkPwd: "LinkedIn Password",
      timeZone: "Select Your Time Zone",
      ConfigureLinkedInProx: "Configure LinkedIn Proxy",
      ManualConfiguration: "Manual Configuration",
      SelectProxyRegion: "Select Proxy Region",
      Connect: "Connect",
      EnterLinkedInSearchURL: "Enter LinkedIn Search URL",
      CompanyInformation: `Company Information`,
      CompanyInformationCont: `Basic Information: StrategyBrain is an innovative company dedicated to enhancing the capabilities of HR departments in SMEs through artificial intelligence, reducing HR costs, and boosting the competitiveness of SMEs in the market.
Official Website: www.strategybrain.ca
Address: StrategyBrain is located in Toronto, Canada.
Company Introduction: StrategyBrain is committed to using cutting-edge AI technology to solve real-world problems, reduce social inequalities, and foster social progress. Our culture of identification and sense of social responsibility solidly underpins our mission, vision, and values.`,
      PositionName: "Job Title",
      PositionNamePlaceholder: `Please enter the job title`,
      ReportTo: 'Reporting Line',
      ReportToPlaceholder: `Please specify the reporting line for this position.`,
      jobResponsibilities:"Job Responsibilities",
      jobResponsibilitiesPlaceholder: `Please enter the specific responsibilities for this position.`,
      jobRequirements:"Job Requirements",
      jobRequirementsPlaceholder: `Please specify the requirements for this position.
• Experience Requirements:
• Educational Background:
• Skill Requirements:`,
      jobRequirementsErr: 'Please specify the requirements for this position.',
      WorkLocation: 'Work Location',
      WorkLocationPlaceholder: 'Please specify the work location for this position.',
      wayOfWorking:"Work Mode",
      wayOfWorkingErr: 'Please select a work mode',
      wayOfWorkingLabel: 'Onsite',
      wayOfWorkingLabel1: 'Remote',
      wayOfWorkingLabel2: 'Hybrid',
      wayOfWorkingLabel3: 'Freelance',
      wayOfWorkingLabel4: 'Shift Work',
      wayOfWorkingLabel5: 'Part-Time',
      salaryRange: 'Salary Range',
      salaryRangeSelectPlaceholder: 'Select Currency Type',
      minSalary: 'Minimum Salary',
      maxSalary: 'Maximum Salary',
      salaryRangeErr: 'The maximum salary cannot be lower than the minimum salary.',
      welfareTreatment: 'Compensation and Benefits',
      welfareTreatmentPlaceholder: 'Please specify the benefits for this position.',

      ContactInformation: "Contact Information",
      ContactInformationCont: `Email：
Phone：`,
      addMore: "Add More Information",
      CustomContent: "Custom Content",
      inputCustomContent: "Enter Custom Content",
      BriefSummary: "Brief Summary",
      BriefSummaryIntro: `A brief summary will help StrategyBrain better understand your company and products. Please ensure all key points are included.`,
      SetConversationLimits: "Set Conversation Limits",
      SetConversationLimitsCont:
        "Dialogue restrictions inform the AI about topics that are off-limits in conversations with users.",
      sendLimit: "Set Daily Connection Request Limit",
      dianzan:
        "Automatically Like the First Post When Connecting with Potential Customers",
      capaginName: "Campaign Name",
      inputLinkAccount: "Please select your LinkedIn account",
      inputContent: "Please enter the content",
      rightAccount: "Please enter a valid LinkedIn email account",
      inputPwd: "Please enter the password",
      selectTimezone: "Please select a time zone",
      selectRegion: "Please select a proxy region",
      selectUrl: "Please enter a valid LinkedIn URL",
      editTitle: "Please edit the custom title",
      shortDes: "Please input your short description",
      dialogLimit: "Please input conversation limit",
      All: "All them",
      bindTips: `Be ready to confirm the PIN for your LinkedIn account due
                    to 2-factor authorization. It will be sent by LinkedIn to
                    the email address of the LinkedIn account. Depending on the
                    security settings it may also be sent via SMS.`,
      inputCode: "Please input code",
      confirmDel: "Are you sure you want to delete this task",
      NextStep: "Next Step",

      Account: "Account",
      TimeZone: "Time Zone",
      Proxy: "Proxy",
      Connected: "Connected",
      ObtainProxyIP: "Obtain Proxy IP",
      VerifyLink: "Verify LinkedIn Account Credentials",
      LinkedInAccountLink: "LinkedIn Account Link",
      ContinueConnect: "Continue Connect",
      selectHistory: "You can select history records",
      findUrl: "How to find the LinkedIn URL",
      loginAccount: "Log in to your LinkedIn account",
      Enterkeywords:
        'Enter keywords in the search box, then click Enter or "See all results"',
      SelectThePeople:
        'Select the "People" filter, set additional search criteria in the filters, and click "Show results"',
      copyURL:
        "Copy the URL address from the navigation bar into this input box",
      ReferenceDocument: "Reference Document",
      conversationRestrictions:
        "Please enter conversation restrictions, such as telling jokes.",
      Save: "Save",
      SelectThePeopleRed: `Important Note: When using the Filter, please check your connections. To send messages to your existing connections, check "1st." If you don't want messages sent to your connections, uncheck this. Then, select only "2nd" and "3rd+" degree connections.`,
      bindLinkAccTipTitle1: "How to Link Your LinkedIn Account?",
      bindLinkAccTipcon1: `Enter your LinkedIn username and password. Then, select your time zone and proxy settings. Finally, click "Connect." The system will begin verifying your LinkedIn account. If the system interrupts the connection, please follow the on-screen instructions. If you still can't link your account, please contact support.`,
      bindLinkAccTipTitle2: "Are all types of LinkedIn accounts supported?",
      bindLinkAccTipcon2:
        "No. Before linking your LinkedIn account, please verify it can send connection requests. If your account can't add connections, the automation tool can't do related campaigns.",
      bindLinkAccTipcon3:
        "You can first increase your LinkedIn activity level by: Completing your profile. Posting and sharing content. Participating in interactions with others. Please try linking your account again after you can send connection requests.",
      bindLinkAccTipBtn: "Click here ",
      bindLinkAccTipBtnTxt: "to view the detailed documentation.",
      step3TipTitle: "Connection Request Limits",
      step3TipCon:
        "  To avoid triggering LinkedIn's security, please limit daily connection requests for each account.",
      step3TipCon1:
        "We recommend that users do not exceed 20 requests per day for regular accounts.We recommend that users do not exceed 35 requests per day for navigator accounts.",
      step3TipTitle2: "Account Operating Hours",
      step3TipCon2:
        "Each LinkedIn account runs on an 8-hour workday schedule. The selected proxy region determines the local time. The account will search, send connection requests, and chat during these hours. Operations will pause outside of working hours. They will resume the next business day when working hours begin again.",
      Confirm: "Confirm",
      Cancel: "Cancel",
      TheAccountIsBeingBound:
        "The account is being bound. Are you sure you want to leave?",
      emailResume: "Do you need to send your resume to the email?",
      Yes: 'Yes',
			No: 'No',
      emailResumeYesTip: "If you choose ‘Yes’, the bot will require the user to provide contact information and send the resume to the specified email address.",
      emailResumeNoTip: "If you choose ‘No’, the bot will require the user to provide contact information but will not enforce sending the resume to the email; online resumes are acceptable.",
      enterEmail: "Please enter an Email",
    },
    AR: {
      ChooseYourCustomerSearchMethod: "اختر طريقة البحث عن المرشحين",
      InputBasicInformation: "أدخل معلومات الوظيفة الأساسية قبل بدء الحملات",
      CampaignsSettings: "إعدادات الحملة",
      Pleaseenterthecampaignname: "يرجى إدخال اسم الحملة",
      SaveandContinue: "احفظ وتابع",
      back: "العودة",
      SearchMethodSelection: "طريقة اختيار البحث",
      ConnectYourLinkedInAccount: "ربط حساب لينكد إن الخاص بك",
      ConfigureLinkedInProxy: "تكوين الوكيل لينكد إن",
      SelectfromYourConnectedContacts:
        "اختر من جهات الاتصال المتصلة التي تعرفها",
      SearchByUrl: "البحث عن العملاء المستهدفين عبر URL على LinkedIn",
      bindLinkAcc: "LinkedIn أدخل بيانات اعتماد حسابك على ",
      linkEmal: " LinkedIn عنوان البريد الإلكتروني على ",
      linkPwd: "LinkedIn كلمة المرور على ",
      timeZone: "اختر منطقتك الزمنية",
      ConfigureLinkedInProx: "تكوين الوكيل لينكد إن",
      ManualConfiguration: "التكوين اليدوي",
      SelectProxyRegion: "اختر منطقة البروكسي",
      Connect: "اتصال",
      EnterLinkedInSearchURL: "LinkedInأدخل رابط بحث ",
      CompanyInformation: `معلومات الشركة`,
      CompanyInformationCont: `معلومات أساسية: StrategyBrain هي شركة مبتكرة مكرسة لتعزيز قدرات أقسام الموارد البشرية في الشركات الصغيرة والمتوسطة من خلال الذكاء الاصطناعي، وتقليل تكاليف الموارد البشرية، وزيادة تنافسية الشركات الصغيرة والمتوسطة في السوق.
الموقع الرسمي: www.strategybrain.ca
العنوان: تقع StrategyBrain في تورونتو، كندا.
مقدمة الشركة: StrategyBrain ملتزمة باستخدام أحدث تكنولوجيا الذكاء الاصطناعي لحل المشكلات الواقعية، وتقليل عدم المساواة الاجتماعية، وتعزيز التقدم الاجتماعي. هوية الثقافة وإحساس المسؤولية الاجتماعية يوفران أساسًا صلبًا للشركة في تحقيق مهمتها ورؤيتها وقيمها.`,
      PositionName: "المسمى الوظيفي",
      PositionNamePlaceholder: `يرجى إدخال المسمى الوظيفي`,
      ReportTo: 'خط التقرير',
      ReportToPlaceholder: `يرجى تحديد خط التقرير لهذا المنصب`,
      jobResponsibilities:"مسؤوليات الوظيفة",
      jobResponsibilitiesPlaceholder: `يرجى إدخال المسؤوليات المحددة لهذا المنصب`,
      jobRequirements:"متطلبات الوظيفة",
      jobRequirementsPlaceholder: `يرجى تحديد متطلبات هذا المنصب.
• متطلبات الخبرة
• الخلفية التعليمية
• متطلبات المهارات：`,
      jobRequirementsErr: "يرجى تحديد متطلبات هذا المنصب.",
      WorkLocation: 'موقع العمل',
      WorkLocationPlaceholder: 'يرجى تحديد موقع العمل لهذا المنصب.',
      wayOfWorking:"وضع العمل",
      wayOfWorkingErr: 'يرجى اختيار وضع العمل',
      wayOfWorkingLabel: 'العمل في الموقع',
      wayOfWorkingLabel1: 'العمل عن بُعد',
      wayOfWorkingLabel2: 'العمل الهجين',
      wayOfWorkingLabel3: 'العمل الحر',
      wayOfWorkingLabel4: 'العمل بنظام المناوبة',
      wayOfWorkingLabel5: 'العمل الجزئي',
      salaryRange: ' نطاق الراتب',
      salaryRangeSelectPlaceholder: 'يرجى اختيار نوع العملة',
      minSalary: 'الحد الأدنى للراتب',
      maxSalary: 'الحد الأقصى للراتب',
      salaryRangeErr: 'الحد الأقصى للراتب لا يمكن أن يكون أقل من الحد الأدنى للراتب.',
      welfareTreatment: 'التعويضات والمزايا',
      welfareTreatmentPlaceholder: 'يرجى تحديد المزايا لهذا المنصب.',

      ContactInformation: "معلومات الاتصال",
      ContactInformationCont: `البريد الإلكتروني:
الهاتف:`,
      addMore: "أضف المزيد من المعلومات",
      CustomContent: "محتوى مخصص",
      inputCustomContent: "الرجاء إدخال المحتوى المخصص",
      BriefSummary: "ملخص موجز",
      BriefSummaryIntro: `سيساعد الملخص المختصر StrategyBrain على فهم شركتك ومنتجاتك بشكل أفضل. الرجاء التأكد من تضمين جميع النقاط الرئيسية.`,
      SetConversationLimits: "تحديدات المحادثة",
      SetConversationLimitsCont:
        "تُعلم قيود الحوار الذكاء الاصطناعي بالمواضيع التي يُحظر مناقشتها مع المستخدمين.",
      sendLimit: "ضع حدًا يوميًا لطلبات الاتصال",
      dianzan:
        "قم تلقائيًا بالإعجاب بالمنشور الأول عند الاتصال بالعملاء المحتملين",
      capaginName: "اسم الحملة",

      inputLinkAccount: "يرجى اختيار حسابك على LinkedIn",
      inputContent: "يرجى إدخال المحتوى",
      rightAccount: "يرجى إدخال بريد إلكتروني صالح لحساب LinkedIn",
      inputPwd: "يرجى إدخال كلمة المرور",
      selectTimezone: "يرجى اختيار المنطقة الزمنية",
      selectRegion: "يرجى اختيار منطقة الوكيل",
      selectUrl: "يرجى إدخال رابط LinkedIn صالح",
      editTitle: "يرجى تعديل العنوان المخصص",
      shortDes: "يرجى إدخال الوصف القصير",
      dialogLimit: "يرجى إدخال حد المحادثة",
      All: "الجميع",
      bindTips:
        "كن مستعدًا لتأكيد رمز PIN الخاص بحسابك على LinkedIn بسبب التحقق بخطوتين. سيتم إرساله بواسطة LinkedIn إلى عنوان البريد الإلكتروني لحسابك على LinkedIn. بناءً على إعدادات الأمان، قد يتم إرساله أيضًا عبر الرسائل النصية القصيرة (SMS).",
      inputCode: "يرجى إدخال الرمز",
      confirmDel: "هل أنت متأكد أنك تريد حذف هذه المهمة؟",
      NextStep: "التالي",

      Account: "الحساب",
      TimeZone: "المنطقة الزمنية",
      Proxy: "الوكيل",
      Connected: "متصل",
      ObtainProxyIP: "الحصول على عنوان IP للوكيل",
      VerifyLink: "التحقق من كلمة مرور حساب LinkedIn",
      LinkedInAccountLink: "رابط حساب LinkedIn",
      ContinueConnect: "متابعة الربط",
      selectHistory: "يتم تحميل البيانات",
      findUrl: " كيفية العثور على رابط LinkedIn",
      loginAccount: " قم بتسجيل الدخول إلى حساب LinkedIn الخاص بك",
      Enterkeywords: ` أدخل الكلمات الرئيسية في مربع البحث، ثم اضغط على Enter أو "عرض كل النتائج"`,
      SelectThePeople: `- اختر فلتر "الأشخاص"، وقم بتعيين المزيد من معايير البحث في الفلاتر، ثم انقر على "عرض النتائج"`,
      copyURL: ` انسخ عنوان URL من شريط التنقل إلى مربع الإدخال هذا`,
      ReferenceDocument: `- الوثيقة المرجعية`,
      conversationRestrictions: `يرجى إدخال قيود المحادثة، مثل سرد النكات.`,
      Save: `حفظ`,
      SelectThePeopleRed: `ملاحظة مهمة: عند استخدام الفلتر، يرجى التحقق من اتصالاتك. لإرسال رسائل إلى اتصالاتك الحالية، اختر "الدرجة الأولى". إذا كنت لا تريد إرسال رسائل إلى اتصالاتك، قم بإلغاء تحديد هذا الخيار. بعد ذلك، اختر فقط اتصالات "الدرجة الثانية" و"الدرجة الثالثة+`,
      bindLinkAccTipTitle1: "كيف تربط حسابك على LinkedIn؟",
      bindLinkAccTipcon1: `أدخل اسم المستخدم وكلمة المرور لحسابك على LinkedIn. ثم اختر منطقتك الزمنية وإعدادات البروكسي. أخيرًا، انقر على ""اتصال"". سيبدأ النظام في التحقق من حساب LinkedIn الخاص بك. إذا قام النظام بمقاطعة الاتصال، يرجى اتباع التعليمات التي تظهر على الشاشة. إذا لم تتمكن من ربط حسابك بعد ذلك، يرجى الاتصال بالدعم الفني.`,
      bindLinkAccTipTitle2: "هل جميع أنواع حسابات LinkedIn مدعومة؟",
      bindLinkAccTipcon2:
        "لا. قبل ربط حسابك على LinkedIn، يُرجى التحقق من أنه يمكنه إرسال طلبات اتصال. إذا كان حسابك لا يمكنه إضافة اتصالات، فلن يتمكن أداة الأتمتة من تنفيذ الحملات المتعلقة بذلك.",
      bindLinkAccTipcon3: `يمكنك أولاً زيادة مستوى نشاطك على LinkedIn من خلال:
إكمال ملفك الشخصي.
نشر ومشاركة المحتوى
المشاركة في التفاعلات مع الآخرين.
يرجى محاولة ربط حسابك مرة أخرى بعد أن تتمكن من إرسال طلبات اتصال.`,
      bindLinkAccTipBtn: "انقر",
      bindLinkAccTipBtnTxt: " هنا لعرض الوثائق التفصيلية.",
      step3TipTitle: "حدود طلبات الاتصال",
      step3TipCon:
        "لتجنب تفعيل أمان LinkedIn، يرجى تحديد عدد طلبات الاتصال اليومية لكل حساب.",
      step3TipCon1: `نوصي بعدم تجاوز 20 طلبًا يوميًا للحسابات العادية.
نوصي بعدم تجاوز 35 طلبًا يوميًا لحسابات النافيغيتور.`,
      step3TipTitle2: "ساعات عمل الحساب",
      step3TipCon2:
        "يعمل كل حساب LinkedIn وفق جدول عمل يومي مدته 8 ساعات. تحدد المنطقة الزمنية المختارة حسب إعدادات البروكسي التوقيت المحلي. سيقوم الحساب بالبحث وإرسال طلبات الاتصال والدردشة خلال هذه الساعات. ستتوقف العمليات خارج ساعات العمل، وستستأنف في يوم العمل التالي عندما تبدأ ساعات العمل مرة أخرى.",
      Confirm: "تأكيد",
      Cancel: "إلغاء",
      TheAccountIsBeingBound:
        "يتم ربط الحساب حالياً. هل أنت متأكد أنك تريد المغادرة؟",
      emailResume: "هل تحتاج إلى إرسال السيرة الذاتية إلى البريد الإلكتروني؟",
      Yes: 'نعم',
			No: 'لا',
      emailResumeYesTip: "إذا اخترت “نعم”، سيطلب الروبوت من المستخدم تقديم معلومات الاتصال وإرسال السيرة الذاتية إلى البريد الإلكتروني المحدد.",
      emailResumeNoTip: "إذا اخترت “لا”، سيطلب الروبوت من المستخدم تقديم معلومات الاتصال، لكنه لن يفرض إرسال السيرة الذاتية إلى البريد الإلكتروني؛ السيرة الذاتية الإلكترونية مقبولة.",
      enterEmail: "أدخل البريد الإلكتروني"
    },
  },
};
