<template>
  <a-layout-sider
    :theme="sideTheme"
    :class="['side-menu', 'beauty-scroll', isMobile ? null : 'shadow']"
    width="254px"
    :collapsible="collapsible"
    v-model="collapsed"
    :trigger="null"
  >
    <div :class="['logo', theme]" id="logo">
      <router-link to="/sales/salesReps/home">
        <!-- <img class="logo" src="@/assets/img/logo.png" /> -->
        <svg-icon
          iconClass="logo"
          class="logo"
          style="
            width: 218px;
            height: 31px;
            background: none;
            padding-left: 0;
            margin-left: -16px;
            margin-top: 15px;
          "
        ></svg-icon>
        <!-- <h1>{{ systemName }}</h1> -->
      </router-link>
    </div>
    <i-menu
      :theme="theme"
      :collapsed="collapsed"
      :options="menuData"
      @select="onSelect"
      class="menu"
    />
    <a-button
      class="addBtn"
      @click="addHandler"
      :class="[lang]"
      type="primary"
      v-if="!showBtn"
      >{{ $t("AddLinkedInaccount") }}</a-button
    >
    <!-- <a-button
      class="addBtn"
      @click="addHandler"
      :class="[lang]"
      type="primary"
      v-if="!showBtn"
      >{{ $t("AddLinkedInaccount") }}</a-button
    > -->
    <!-- 侧边栏二维码 -->
    <div class="QR-code-box" style="padding-top: 20px">
      <img
        src="https://u-img.strategybrain.ca/images/static/linkedIn.jpg"
        alt=""
      />
      <div class="code-txt">Follow the LinkedIn company page.</div>
      <!-- <img
        src="https://u-img.strategybrain.ca/images/static/whatsapp.jpg"
        alt=""
      />
      <div class="code-txt">Join the WhatsApp discussion group.</div> -->
    </div>
  </a-layout-sider>
</template>

<script>
import IMenu from "./menu";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  name: "SideMenu",
  components: { IMenu },
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: false,
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuData: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: "dark",
    },
  },
  i18n: {
    messages: {
      CN: {
        AddLinkedInaccount: "添加LinkedIn账号",
      },

      US: {
        AddLinkedInaccount: "Add LinkedIn account",
      },
      AR: {
        AddLinkedInaccount: "إضافة حساب LinkedIn",
      },
    },
  },
  computed: {
    sideTheme() {
      return this.theme == "light" ? this.theme : "dark";
    },
    ...mapState("setting", ["isMobile", "systemName"]),
    showBtn() {
      if (this.user && this.user.paid == 1) {
        return false;
      }
      if (this.user && this.user.free_info) {
        let { end_date } = this.user.free_info;
        // let start = new Date(start_date).getTime();
        let end = new Date(end_date).getTime();
        if (Date.now() > end) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    onSelect(obj) {
      this.$emit("menuSelect", obj);
    },
    addHandler() {
      this.$router.push({
        path: "/setting/userSetting/plan",
        // 现在只有sales ，hr 上线了此行代码去掉
        // query: { campaign_type: 1 },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "index";
#logo {
  background-color: #373e4b !important;
  img {
    background-color: #373e4b !important;
  }
}
/deep/.ant-layout-sider {
  background-color: #333a48;
}
/deep/.ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title {
  background: #3b4353 !important;
  border-left: transparent solid 4px;
}
/deep/.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title {
  background: #3b4353 !important;
  border-left: #0e756a solid 4px;
}
/deep/.menu-item-title {
  background: #3b4353 !important;
  border-left: transparent solid 4px;
  width: 100%;
  display: block;
  &.ant-menu-item-selected {
    background: #3b4353 !important;
    border-left: #0e756a solid 4px !important;
    a {
      color: #fefefe;
    }
  }
}
.addBtn {
  width: 210px;
  height: 48px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  // position: fixed;
  // bottom: 25px;
  left: 20px;
  z-index: 1;
}
/deep/.ant-layout-sider-children {
  padding-bottom: 380px;
  min-height: 100%;
  height: auto;
  position: relative;
}
.QR-code-box {
  position: absolute;
  bottom: 188px;
  text-align: center;
  img {
    width: 100px;
    height: auto;
  }
  .code-txt {
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 10px 20px;
  }
}
</style>
