<template>
  <div class="home">
    <a-spin size="large" :spinning="loading">
      <div class="dashbordData">
        <ul>
          <li>
            <div class="tit" :class="[lang]">{{ $t("TotalNumber") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.customer_find ? dashBorderData.customer_find : 0
              }}</span>
            </div>
          </li>
          <li>
            <div class="tit" :class="[lang]">
              {{ $t("ConnectionsRequest") }}
            </div>
            <div class="data incluedRate">
              <span class="number">{{
                dashBorderData.connections_request
                  ? dashBorderData.connections_request
                  : 0
              }}</span>
            </div>
          </li>
          <li>
            <div class="tit" :class="[lang]">{{ $t("ConnectionsMade") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.connections_made
                  ? dashBorderData.connections_made
                  : 0
              }}</span>
            </div>
          </li>
          <li>
            <div class="tit" :class="[lang]">{{ $t("Acceptedrate") }}</div>
            <div class="data">
              <span class="number"
                >{{
                  dashBorderData.accept_rate ? dashBorderData.accept_rate : 0
                }}%</span
              >
            </div>
          </li>
          <li style="border-right: none">
            <div class="tit" :class="[lang]">{{ $t("NumberOfGreeters") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.greeting_count
                  ? dashBorderData.greeting_count
                  : 0
              }}</span>
            </div>
          </li>
        </ul>
        <ul style="margin-top: 26px">
          <li>
            <div class="tit" :class="[lang]">{{ $t("Replies") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.replies ? dashBorderData.replies : 0
              }}</span>
            </div>
          </li>
          <li>
            <div class="tit" :class="[lang]">{{ $t("Repliesrate") }}</div>
            <div class="data">
              <span class="number"
                >{{
                  dashBorderData.replies_rate ? dashBorderData.replies_rate : 0
                }}%</span
              >
            </div>
          </li>
          <li>
            <div class="tit" :class="[lang]">{{ $t("Message") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.message ? dashBorderData.message : 0
              }}</span>
            </div>
          </li>
          <li>
            <div class="tit" :class="[lang]">{{ $t("LeadsGenerated") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.leads ? dashBorderData.leads : 0
              }}</span>
            </div>
          </li>
          <li style="border-right: none">
            <div class="tit" :class="[lang]">{{ $t("ConversionRate") }}</div>
            <div class="data">
              <span class="number"
                >{{ dashBorderData.rate ? dashBorderData.rate : 0 }}%</span
              >
            </div>
          </li>

          <!-- <li style="border-right: none">
            <div class="tit" :class="[lang]">{{ $t("ExistingFriends") }}</div>
            <div class="data">
              <span class="number">{{
                dashBorderData.existing_friends
                  ? dashBorderData.existing_friends
                  : 0
              }}</span>
            </div>
          </li>
          <li></li> -->
        </ul>
      </div>
      <div class="title">
        <svg-icon iconClass="hot"></svg-icon
        ><span :class="[lang]">{{ $t("Hotconversataion") }}</span>
      </div>
      <div
        class="hotList"
        :class="{ noDataWrap: hotData.length == 0 ? true : false }"
      >
        <ul v-if="hotData.length > 0">
          <li
            @click="skipDialog(item)"
            v-for="(item, index) in hotData"
            :key="index"
          >
            <div class="user">
              <img
                v-if="item.head_img"
                style="cursor: pointer"
                @click="skipDialog(item)"
                :src="item.head_img"
              />
              <img v-else src="@/assets/img/avat.png" />
              <span class="name">{{ item.customer_name }}</span>
            </div>
            <div class="info">
              <span
                class="label"
                :class="[lang]"
                :style="{
                  'min-width':
                    lang == 'US' ? '66px' : lang == 'CN' ? '33px' : '33px',
                }"
                >{{ $t("Company") }}:</span
              >
              <span class="val">{{ item.company ? item.company : "--" }}</span>
            </div>
            <div class="info">
              <span class="label" :class="[lang]"
                >{{ $t("Conversation") }}:</span
              >
            </div>
            <div class="message">
              {{ item.content }}
            </div>
            <div class="info m15">
              <span
                class="label"
                :style="{
                  'min-width':
                    lang == 'US' ? '66px' : lang == 'CN' ? '33px' : '33px',
                }"
                :class="[lang]"
                >{{ $t("campaign") }}:</span
              >
              <span class="val">{{ item.campaign_name }}</span>
            </div>
            <div class="info">
              <span
                class="label"
                :style="{
                  'min-width':
                    lang == 'US' ? '105px' : lang == 'CN' ? '60px' : '80px',
                }"
                :class="[lang]"
                >{{ $t("LinkedIn") }}:</span
              >
              <span class="val">{{ item.linkedin_email }}</span>
            </div>
          </li>
        </ul>
        <NoData :lang="lang" :text="$t('noHotData')" v-else />
      </div>
      <div class="wrap-table">
        <div class="title title2">
          <div>
            <svg-icon iconClass="active"></svg-icon
            ><span :class="[lang]">{{ $t("RecentCampaign") }}</span>
          </div>
          <div class="campaignsDetail" @click="goMore">
            <span :class="[lang]">{{ $t("ViewAllCampaigns") }}</span
            ><a-icon class="ir" type="right" />
          </div>
        </div>
        <a-table
          :class="{ arTable: lang == 'AR' ? true : false }"
          :scroll="{
            x: 1120,
          }"
          :locale="{ emptyText: $t('noCamp') }"
          size="middle"
          :columns="columns"
          :rowKey="(record, index) => record.id"
          :data-source="capinData"
          :pagination="false"
          class="capinTable"
          :customRow="
            (record) => {
              return {
                on: {
                  click: () => {
                    goDetail(record);
                  },
                },
              };
            }
          "
          :loading="loading"
          :row-class-name="
            (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
          "
        >
          <template slot="status" slot-scope="text, record" class="arial">
            <div class="wrap-bar">
              <div class="statusbox" @click.stop="">
                <span v-if="record.status == '1'" :class="[lang]">{{
                  $t("Active")
                }}</span>
                <span v-if="record.status == '0'" :class="[lang]">{{
                  $t("Paused")
                }}</span>
                <img
                  v-if="record.status == '1'"
                  src="@/assets/img/s.png"
                  class="swing"
                />
                <!-- <svg-icon iconClass="sactive"></svg-icon> -->
              </div>
              <div @click.stop="">
                <a-switch
                  size="small"
                  v-model="record.status"
                  @change="onChangeRecord(record)"
                />
              </div>
              <!-- <div class="bar">21212</div> -->
            </div>
          </template>

          <template slot="action" slot-scope="text, record" class="arial">
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1" @click="goDetail(record)">
                    <a-icon type="edit" />
                    <span :class="[lang]">{{ $t("Details") }}</span>
                  </a-menu-item>
                  <a-menu-item key="2" @click="copyHandler(record)">
                    <a-icon type="copy" />
                    <span :class="[lang]">{{ $t("Copy") }}</span>
                  </a-menu-item>
                  <a-menu-item key="3" @click="deleteHandler(record)">
                    <a-icon type="delete" />
                    <span :class="[lang]">{{ $t("Delete") }}</span>
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button style="padding: 0 8px" @click.stop="">
                <span :class="[lang]">{{ $t("Action") }}</span>
                <a-icon type="down" />
              </a-button>
            </a-dropdown>
          </template>

          <template slot="campaign_name" slot-scope="text, record">
            {{ record.campaign_name }}
            <CampaignTableTip
              :record="record.action_log"
              v-if="record && record.action_log"
            />
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import CampaignTableTip from './component/campaignTableTip.vue'
export default {
  components: {
    CampaignTableTip
  },
  data() {
    return {
      loading: false,
      dashBorderData: {},
      capinData: [],
      hotData: [],
    };
  },
  i18n: require("./i18n"),
  computed: {
    columns: {
      get() {
        let that = this;
        return [
          {
            title: that.$t("Name"),
            width: 200,
            dataIndex: "campaign_name",
            align: "left",
            ellipsis: true,
            // fixed: "left",
            scopedSlots: {
              customRender: "campaign_name",
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Status"),
            width: 160,
            dataIndex: "status",
            align: "center",
            // fixed: "left",
            scopedSlots: {
              customRender: "status",
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("LinkedinlD"),
            width: 200,
            dataIndex: "linkedin_email",
            ellipsis: true,
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Days"),
            width: 120,
            dataIndex: "days",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("TotalNumber"),
            width: that.lang == "US" ? 260 : that.lang == "AR" ? 200 : 120,
            dataIndex: "customer_find",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("ConnectionsRequest"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "connections_request",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("ConnectionsMade"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "connections_made",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },

          {
            title: that.$t("Acceptedrate"),
            width: 120,
            dataIndex: "accept_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("NumberOfGreeters"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "greeting_count",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },

          {
            title: that.$t("Replies"),
            width: 120,
            dataIndex: "replies",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Repliesrate"),
            width: that.lang == "US" ? 140 : that.lang == "AR" ? 200 : 120,
            dataIndex: "replies_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Message"),
            width: 120,
            dataIndex: "message",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("LeadsGenerated"),
            width: that.lang == "US" ? 120 : that.lang == "AR" ? 200 : 120,
            dataIndex: "leads",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("ConversionRate"),
            width: 120,
            dataIndex: "rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },

          {
            title: that.$t("Action"),
            align: "center",
            fixed: "right",
            width: 120,
            scopedSlots: {
              customRender: "action",
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
        ];
      },
    },
  },
  activated() {
    this.initData();
  },
  created() {},
  methods: {
    initData() {
      this.loading = true;
      Promise.all([
        this.getBordeData(),
        this.getCampinData(),
        this.getHotData(),
      ]).then(() => {
        this.loading = false;
      });
    },
    deleteHandler(record) {
      let that = this;
      this.$confirm({
        title: that.$t("confirmDel") + "?",
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          that
            .$axios(
              "/recruiter/campaign_delete",
              { campaign_id: record.id },
              "post"
            )
            .then((res) => {
              if (res.code == 200) {
                that.getCampinData();
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    copyHandler(record) {
      let { id } = record;
      this.$router.push({
        path: "/hr/recruiter/createCampaign",
        query: { campaign_id: id, edit: 1 },
      });
    },
    // 热门对话
    getHotData() {
      return new Promise((resolve) => {
        this.$axios("/recruiter/hot_conversatin", {}, "post").then((res) => {
          if (res.code == 200) {
            this.hotData = res.data;
          }
          resolve();
        });
      });
    },
    // 获取最近五个任务信息
    getCampinData() {
      return new Promise((resove) => {
        this.$axios("/recruiter/recent_campaign", {}, "post").then((res) => {
          if (res.code == 200) {
            let arr = [];
            let data = res.data || []
            data.forEach((item) => {
              item.status = item.status == 0 ? false : true;
              arr.push(item);
            });
            this.capinData = arr;
            // console.log(this.$router.history, this.$route.from);
            if (arr.length == 0 && this.$route.query.type !== 'tab') {
              this.$router.replace({ path: "/hr/recruiter/campaign" });
            }
          }
          resove();
        });
      });
    },
    // 获取数据看板数据
    getBordeData() {
      return new Promise((resove) => {
        this.$axios("/recruiter/home_dashboard", {}, "post").then((res) => {
          if (res.code == 200) {
            this.dashBorderData = res.data;
          }
          resove();
        });
      });
    },
    goMore() {
      this.$router.push({ path: "/hr/recruiter/campaign" });
    },
    goDetail(record) {
      let { id, status } = record;
      this.$router.push({
        path: "/hr/recruiter/campaignDetail",
        query: { campaign_id: id, customer_status: status ? 1 : 0 },
      });
    },
    skipDialog(obj) {
      let { campaign_id, customer_id } = obj;
      this.$router.push({
        path: "/hr/recruiter/dialogue",
        query: { campaign_id, customer_id },
      });
    },
    // 修改列表数据状态
    onChangeRecord(record) {
      let { id, status } = record;
      this.$axios(
        "/recruiter/campaign_change_status",
        { campaign_id: id, change_status: status ? 1 : 0 },
        "post"
      ).then((res) => {
        let { code, data } = res;
        if (code == 200) {
          if (data.result == 1 && this.tableData.length == 1 && this.page > 1) {
            this.getCampinData();
          } else {
            record.status = !status;
            this.$warning({
              content: this.$t("statusChangeTip"),
              okText: this.$t("goSet"),
            });
          }
        } else {
          record.status = !status;
        }
      });
    },
    handleMenuClick(val) {
      console.log(val, arguments);
    },
    getCustomCell (record) {
      if (record.action_log && record.action_log.action_log && record.action_log.action_log.length > 0) {
        return {
          style: {
            'padding-bottom': '66px',
          }
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 20px 30px;
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .dashbordData {
    // height: 116px;
    background: #fff;
    padding-top: 26px;
    padding-bottom: 26px;
    ul {
      display: flex;
    }
    li {
      height: 64px;
      list-style: none;
      flex: 1;
      text-align: center;
      border-right: solid 1px #e5e5e5;
      .tit {
        font-family: Arial, Arial;
        font-weight: 400;
        font-size: 14px;
        color: #80829f;
      }
      .data {
        margin-top: 7px;
        height: 41px;
        .number {
          font-family: Arial, Arial;
          font-weight: bold;
          display: inline-block;
          font-size: 36px;
          height: 36px;
          line-height: 36px;
          color: #0e756a;
        }
      }
      .incluedRate {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        p {
          font-family: Arial, Arial;
          font-weight: 400;
          font-size: 12px;
          color: #0094ff;
          background: #e5f4ff;
          border-radius: 2px 2px 2px 2px;
          padding: 1px 5px;
          margin-left: 7px;
          margin-bottom: 6px;
          text-align: left;
        }
      }
    }
  }
  .title {
    padding: 20px 0 14px 0;
    span {
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .title2 {
    display: flex;
    justify-content: space-between;
    .campaignsDetail {
      font-weight: 400;
      font-size: 14px;
      color: #0e756a;
      cursor: pointer;
      .ir {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .hotList {
    border-radius: 4px;
    background: #fff;
    min-height: 292px;
    ul {
      display: flex;
      li {
        width: 20%;
        padding: 22px;
        width: 20%;
        border: #fff 1px solid;
        .user {
          display: flex;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #ccc;
          }
          .name {
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 16px;
            color: #000000;
            margin-left: 14px;
          }
        }
        .info {
          margin-top: 20px;
          display: flex;
          .label {
            font-family: Arial, Arial;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            display: inline-block;
            min-width: 64px;
          }
          .val {
            font-family: Arial, Arial;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            max-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: normal;
          }
        }
        .m15 {
          margin-top: 15px;
        }
        .message {
          background: #f3f3f3;
          border-radius: 4px;
          padding: 11px;
          line-height: 24px;
          margin-top: 6px;
          height: 88px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }
      li:hover {
        box-shadow: 0px 0px 14px 1px rgba(14, 117, 106, 0.3);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0e756a;
      }
    }
  }
  .wrap-table {
    padding: 0px 20px 12px 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;
  }
  /deep/.ant-table-row-hover {
    background: rgba(238, 248, 247, 1) !important;
  }
  /deep/.ant-btn {
    background: #0c6158;
    color: #fff;
  }
  .noDataWrap {
    position: relative;
    /deep/.noData {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .capinTable {
    // /deep/ td.ant-table-row-expand-icon-cell {
    //   display: none;
    // }
    /deep/ tr.ant-table-row {
      position: relative;
      // td {
      //   padding-bottom: 66px !important;
      // }
      // .tableCampaignNameClass {
      //   padding-bottom: 12px !important;
      // }
    }
  }
}
.wrap-bar {
  position: relative;
  .bar {
    position: absolute;
    border-radius: 4px;
    background: rgba(105, 142, 138, 0.7);
    width: 200px;
  }
}
/deep/.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-left: 36px;
}
/deep/.ant-modal-confirm-content {
  margin-left: 36px;
}

@keyframes swing {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px);
  }
  30% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  70% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.swing {
  animation: swing 1s infinite alternate;
}
/deep/.ant-table-row {
  cursor: pointer;
}
</style>
