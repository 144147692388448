var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "discount-list" }, [
      _c("ul", [
        _c("li", [
          _c("div", { staticClass: "left" }, [
            _c(
              "div",
              { staticClass: "name" },
              [
                _vm._v(" 专属折扣码 "),
                _c(
                  "a-popover",
                  {
                    staticStyle: { "z-index": "98" },
                    attrs: { placement: "bottomLeft", trigger: "click" },
                    model: {
                      value: _vm.visiable,
                      callback: function ($$v) {
                        _vm.visiable = $$v
                      },
                      expression: "visiable",
                    },
                  },
                  [
                    _c("template", { slot: "content" }, [
                      _c("div", { staticClass: "popbox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptit",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "num",
                              class: { rtl: _vm.lang == "AR" ? true : false },
                            }),
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v("折扣码是否有使用次数?"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            " 是的，每个折扣码都有使用次数的限制，您可以在返佣管理列表折扣码基础信息处中查看剩余的次数。 "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ptit mtop",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "num",
                              class: { rtl: _vm.lang == "AR" ? true : false },
                            }),
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v("折扣码是否有有效期??"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            " 是的，折扣码是有有效期的，您可以在返佣管理列表折扣码基础信息处查看有效期。 "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ptit mtop",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "num",
                              class: { rtl: _vm.lang == "AR" ? true : false },
                            }),
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v("折扣码抵扣规则?"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            " 折扣码仅可用于首次支付时抵扣，后期续费时不再享受折扣码的折扣。 "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ptit mtop",
                            class: [_vm.lang],
                            style: {
                              "text-align": _vm.lang == "AR" ? "right" : "left",
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "num",
                              class: { rtl: _vm.lang == "AR" ? true : false },
                            }),
                            _c("span", { class: [_vm.lang] }, [
                              _vm._v("返佣规则?"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            " 1.用户通过您的折扣码完成支付后，会进入到您的返佣列表。 "
                          ),
                        ]),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            " 2.用户选择按月订阅，则佣金会在用户支付满30天后进行一次性发放，若30天内用户退款，则佣金不会发放。 "
                          ),
                        ]),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            " 3.用户选择按年订阅，则会在用户支付满30天后进行第一次发放，后续每满30天进行一次发放，直至所有的佣金发放完毕若中间用户退款，则后续佣金不再发放。 "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "400",
                              "font-size": "14px",
                              color: "#0e756a",
                              "line-height": "30px",
                              "margin-top": "34px",
                            },
                          },
                          [_vm._v(" 以上解释权归strategybrain所有 ")]
                        ),
                      ]),
                    ]),
                    _c("svg-icon", {
                      staticClass: "icon",
                      attrs: { iconClass: "help" },
                      on: {
                        click: function ($event) {
                          _vm.visiable = true
                        },
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "code" }, [_vm._v("HD78IJ90")]),
            _c("div", { staticClass: "expirseTime" }, [
              _c("span", { class: [_vm.lang] }, [_vm._v("会员有效期:")]),
              _c("span", { staticClass: "time" }, [_vm._v("2024-12-30")]),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "wrap-table" },
      [
        _c("div", { staticClass: "title title2" }, [
          _c(
            "div",
            { staticClass: "tit" },
            [
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { iconClass: "more2" },
              }),
              _c("span", [_vm._v("返佣管理")]),
            ],
            1
          ),
        ]),
        _c("a-table", {
          attrs: {
            scroll: {
              x: 1120,
            },
            locale: { emptyText: _vm.$t("noCamp") },
            size: "middle",
            columns: _vm.columns,
            rowKey: (record, index) => index,
            "data-source": _vm.capinData,
            pagination: false,
            customRow: (record) => {
              return {
                on: {
                  click: () => _vm.goDetail(record),
                },
              }
            },
            loading: _vm.loading,
            "row-class-name": (_record, index) =>
              index % 2 !== 1 ? "table-bg" : null,
          },
          scopedSlots: _vm._u([
            {
              key: "status",
              fn: function (text, record) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "statusbox",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      record.status == "1"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Active")))])
                        : _vm._e(),
                      record.status == "0"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("Paused")))])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c("a-switch", {
                        attrs: { size: "small" },
                        on: {
                          change: function ($event) {
                            return _vm.onChangeRecord(record)
                          },
                        },
                        model: {
                          value: record.status,
                          callback: function ($$v) {
                            _vm.$set(record, "status", $$v)
                          },
                          expression: "record.status",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "action",
              fn: function (text, record) {
                return [
                  _c(
                    "a-dropdown",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "overlay",
                            fn: function () {
                              return [
                                _c(
                                  "a-menu",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleMenuClick.bind(record)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDetail(record)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "edit" },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("Detail")) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyHandler(record)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "copy" },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("Copy")) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "3",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteHandler(record)
                                          },
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "delete" },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("Delete")) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { padding: "0 8px" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Action")) + " "),
                          _c("a-icon", { attrs: { type: "down" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("dl", [
        _c("dd", [
          _c("div", { staticClass: "tit" }, [_vm._v("折扣")]),
          _c("div", { staticClass: "numbers" }, [
            _c("span", [_vm._v("8.5")]),
            _c("span", { staticClass: "dis" }, [_vm._v("折")]),
          ]),
        ]),
        _c("dd", { staticClass: "line" }, [
          _c("div", { staticClass: "tit" }, [_vm._v("总次数")]),
          _c("div", { staticClass: "numbers" }, [_c("span", [_vm._v("30")])]),
        ]),
        _c("dd", [
          _c("div", { staticClass: "tit" }, [_vm._v("剩余次数")]),
          _c("div", { staticClass: "numbers" }, [_c("span", [_vm._v("26")])]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }