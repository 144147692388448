<template>
  <a-spin size="large" :spinning="loading">
    <common-layout>
      <div class="main-login">
        <div class="left">
          <div class="left-wrap">
            <div class="header">
              <svg-icon
                iconClass="login"
                class="logo"
                style="width: 309px; height: 44px; background: none"
              ></svg-icon>
            </div>
            <div class="left-img">
              <a-carousel autoplay dotsClass="dots">
                <div><img src="@/assets/img/login1.png" /></div>
                <div><img src="@/assets/img/login2.png" /></div>
                <div><img src="@/assets/img/login3.png" /></div>
                <div><img src="@/assets/img/login4.png" /></div>
                <div><img src="@/assets/img/login5.png" /></div>
              </a-carousel>
            </div>
          </div>
        </div>
        <div class="right">
          <div v-if="!loinFail" class="login">
            <div class="title" :class="[lang]">{{ $t("StartTrial") }}</div>
            <div class="subTitle" :class="[lang]">
              {{ $t("try10DaysFree") }}
            </div>
            <div class="auth">
              <ul>
                <li @click="author('google-oauth2')">
                  <svg-icon iconClass="google"></svg-icon>
                  <span :class="[lang]">{{ $t("SigninwithGoogle") }}</span>
                </li>
                <li @click="author('linkedin')">
                  <svg-icon iconClass="linkin"></svg-icon>
                  <span :class="[lang]">{{ $t("SigninwithLinkedIn") }}</span>
                </li>
                <li @click="author('facebook')">
                  <svg-icon iconClass="facebook"></svg-icon>
                  <span :class="[lang]">facebook</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="fail" v-if="loinFail">
            <div class="error">
              <svg-icon class="loginError" iconClass="loginError"></svg-icon>
            </div>
            <div class="login-text" :class="[lang]">
              {{ $t("Loginfailed") }}
            </div>
            <div class="eror-code" v-if="loginInfo.msg" :class="[lang]">
              {{ $t("Errorcode") }}：{{ loginInfo.msg }}
            </div>
            <div class="eror-code" v-if="showError" :class="[lang]">
              {{ $t("authorization") }}
            </div>
            <div class="retry" :class="[lang]">{{ $t("Youcantry") }}</div>
            <div class="btn relogin" :class="[lang]" @click="reLogin">
              <svg-icon class="icon" iconClass="restart-line"></svg-icon
              >{{ $t("Logagain") }}
            </div>
            <div class="btn concatus">
              <a
                href="https://tawk.to/chat/67626c9aaf5bfec1dbddbfc4/1ifc7gf7c"
                target="_blank"
                :class="[lang]"
                ><svg-icon class="icon" iconClass="concat"></svg-icon
                >{{ $t("Emailus") }}</a
              >
            </div>
            <div class="wrap-code">
              <div class="qrcode">
                <img
                  src="https://u-img.strategybrain.ca/images/static/linkedIn.jpg"
                />
                <div class="text">
                  <span>Linkedln</span>
                </div>
              </div>
              <div class="qrcode">
                <img
                  src="https://u-img.strategybrain.ca/images/static/whatsapp.jpg"
                />
                <div class="text">
                  <span>Whats App</span>
                </div>
              </div>
            </div>
            <div class="qus" :class="[lang]">{{ $t("problem") }}</div>
          </div>
        </div>
      </div>
      <div class="mask" v-show="visiable">
        <div class="dialog">
          <div class="loading">
            <img src="@/assets/img/login_loading.gif" />"
          </div>
          <div class="text" :class="[lang]">{{ $t("loadingSpace") }}..</div>
          <div class="slide-wrap">
            <a-carousel autoplay dotsClass="dots">
              <div class="dtext" :class="[lang]">
                {{ $t("Afterlogging") }}
              </div>
              <div class="dtext" :class="[lang]">
                {{ $t("Whencreating") }}
              </div>
              <div class="dtext" :class="[lang]">
                {{ $t("Youcancreate") }}
              </div>
              <div class="dtext" :class="[lang]">
                {{ $t("Settingsearch") }}
              </div>
              <div class="dtext" :class="[lang]">
                {{ $t("Themore") }}
              </div>
              <div class="dtext" :class="[lang]">
                {{ $t("Enteras") }}
              </div>
              <div class="dtext" :class="[lang]">
                {{ $t("ForLinkedIn") }}
              </div>
            </a-carousel>
          </div>
        </div>
      </div>
    </common-layout>
  </a-spin>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import { login } from "@/services/user";
import { setAuthorization } from "@/utils/request";
// import { loadRoutes } from "@/utils/routerUtil";
import { mapMutations } from "vuex";
// import { useAuth0 } from "@auth0/auth0-vue";
// const auth0 = useAuth0();
import { sendData } from "@/utils/burying-point";

export default {
  name: "Login",
  components: { CommonLayout },
  data() {
    return {
      loginInfo: {},
      loinFail: false,
      visiable: false,
      logging: false,
      isAuthenticated: false,
      isLoading: false,
      error: "",
      loading: false,
      form: this.$form.createForm(this),
      showPage: false,
      showError: false,
      timer: null,
    };
  },

  computed: {
    systemName() {
      return this.$store.state.setting.systemName;
    },
  },
  i18n: require("./i18n"),
  created() {},
  mounted() {
    this.timer = setInterval(() => {
      if (window.Tawk_API && window.Tawk_API.shutdown) {
        window.Tawk_API && window.Tawk_API.shutdown();
        clearInterval(this.timer);
      }
    }, 200);

    // 数据上报
    sendData({
      event_name: "vist_login",
    });
    if (localStorage.getItem("token")) {
      this.$router.push("/sales/salesReps");
      return;
    }
    if (location.hash.indexOf("access_denied") > -1) {
      // 授权失败
      sendData({
        event_name: "loginskip_cancel",
      });
      this.showError = true;
      this.loinFail = true;
      return;
    } else {
      this.showError = false;
    }
    if (location.hash.indexOf("access_token") != -1) {
      // 授权成功
      this.visiable = true;
      sendData({
        event_name: "loginskip_accecpt",
      });
      this.$axios(
        "/user/login",
        {
          auth0_token: location.hash.split("=")[1].split("&")[0],
        },
        "post"
      ).then((res) => {
        if (res.code == "200") {
          localStorage.setItem("token", res.data.access_token);
          setAuthorization({
            token: res.data.access_token,
            expireAt: new Date("2099/12/30 23:59:59"),
          });
          this.$axios(
            "/user/info",
            {
              auth0_token: location.hash.split("=")[1].split("&")[0],
            },
            "post"
          ).then((res2) => {
            if (res2.code == 200) {
              this.setUser({
                ...res2.data,
                // hr_paid: 0,
                // sales_paid: 0,
                // free_info: {
                //   account: "",
                //   bind_type: "",
                //   start_date: "2024/10/31 23:59",
                //   end_date: "2024/11/21 23:59",
                // },
              });
              localStorage.setItem("showGuide", res.data.is_new ? 1 : 0);
              let { hrEndCapaignDate, saleEndCapaignDate } = res2.data;
              if (!hrEndCapaignDate && !saleEndCapaignDate) {
                this.$router.push("/sales/salesReps/campaign", {
                  isNew: res.data.is_new == false ? 0 : 1,
                });
              } else if (saleEndCapaignDate && hrEndCapaignDate) {
                if (
                  new Date(saleEndCapaignDate).getTime() >
                  new Date(hrEndCapaignDate).getTime()
                ) {
                  this.$router.push("/sales/salesReps/home");
                } else {
                  this.$router.push("/sales/salesReps/home");
                }
              } else {
                this.$router.push("/sales/salesReps/home");
              }
            }
          });
        } else {
          sessionStorage.removeItem("token");
          this.loginInfo = res || {};
          this.loinFail = true;
          // this.$message.error(res.msg);
        }
      });
    }
  },
  methods: {
    reLogin() {
      this.loinFail = false;
    },
    reg() {
      this.webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          email: "412699784@qq.com",
          password: "123456",
          username: "johndoe",
          given_name: "John",
          family_name: "Doe",
          name: "John Doe",
          nickname: "johnny",
          picture: "http://example.org/jdoe.png",
          user_metadata: { plan: "silver", team_id: "a111" },
        },
        function (err) {
          if (err) return alert("Something went wrong: " + err.message);
          return alert("success signup without login!");
        }
      );
    },
    author(op) {
      this.loading = true;
      let event_name = "login_button_google";
      switch (op) {
        case "google-oauth2":
          event_name = "login_button_google";
          break;
        case "facebook":
          event_name = "login_button_facebook";
          break;
        case "linkedin":
          event_name = "login_button_linkedin";
          break;
      }
      sendData({
        event_name,
      });
      this.webAuth.authorize(
        {
          responseType: "token",
          connection: op,
          // redirectUri: "http://localhost:8080/login",
          redirectUri: process.env.VUE_APP_SKIP_BASE_URL,
          // authorizationParams: {
          //   redirect_uri: "http://localhost:8080/login",
          // },
        },
        (err, authResult) => {
          console.log(err, authResult);
          this.loading = false;
          //do something
        }
      );
    },
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const name = this.form.getFieldValue("name");
          const password = this.form.getFieldValue("password");
          login(name, password).then(this.afterLogin);
        }
      });
    },
    afterLogin(res) {
      this.logging = false;
      const loginRes = res;
      if (loginRes.code >= 0) {
        const { user, permissions, roles } = loginRes.data;
        this.setUser(user);
        this.setPermissions(permissions);
        this.setRoles(roles);
        setAuthorization({
          token: loginRes.data.token,
          expireAt: new Date(loginRes.data.expireAt),
        });
        this.$router.push("/sales/salesReps");
        this.$message.success(loginRes.message, 3);
        // 获取路由配置
        // getRoutesConfig().then((result) => {
        //   ;
        //   const routesConfig = result.data.data;
        //   console.log("routesConfig", routesConfig);
        //   alert(3);
        //   ;
        //   loadRoutes(routesConfig);
        //   alert(333);
        //   ;
        //   //this.$router.push("/sales/salesReps");
        //   this.$message.success(loginRes.message, 3);
        // });
      } else {
        this.error = loginRes.message;
      }
    },
    onClose() {
      this.error = false;
    },
  },
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog {
    width: 746px;
    height: 546px;
    background: #ffffff;
    border-radius: 21px 21px 21px 21px;
    .loading {
      text-align: center;
      padding-top: 90px;
    }
    .text {
      text-align: center;
      padding-top: 27px;
      font-weight: bold;
      font-size: 36px;
      color: #000000;
      padding-bottom: 27px;
    }
    .slide-wrap {
      width: 704px !important;
      margin: 0 auto;
      height: 42px;
      .dtext {
        text-align: center;
      }
      /deep/.ant-carousel {
        width: 704px !important;
        height: 42px;
      }
      .ant-carousel .slick-slide {
        text-align: center;
        height: 160px;
        line-height: 160px;
        background: #364d79;
        overflow: hidden;
      }

      .ant-carousel .slick-slide h3 {
        color: #fff;
      }
      /deep/ .ant-carousel .slick-dots-bottom {
        bottom: -12px !important;
      }
      /deep/ .ant-carousel .slick-dots li button {
        width: 9px;
        height: 9px;
        background: #b8d1ce;
        border-radius: 50%;
        opacity: 1;
      }
      /deep/ .ant-carousel .slick-dots .slick-active button {
        width: 29px;
        height: 9px;
        background: #0e756a;
        border-radius: 4px;
      }
    }
  }
  @media screen and (max-width: 1680px) {
    .dialog {
      transform: scale(0.8);
    }
  }
}
/deep/.content {
  padding: 0 !important;
}
.common-layout {
  .main-login {
    height: 100vh;
    display: flex;
    .left,
    .right {
      flex: 1;
      height: 100vh;
    }
    .right {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .left {
      background: url(../../assets/img/left_login_bg.png) no-repeat;
      background-size: 100% 100%;
      .left-wrap {
        .header {
          position: fixed;
          top: 40px;
          left: 40px;
        }
        .left-img {
          width: 100%;
          height: 100vh;
          position: relative;
        }
        .ant-carousel {
          width: 584px;
          height: 403px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 584px;
            height: 403px;
          }
        }
        @media screen and (max-width: 1680px) {
          .ant-carousel {
            width: 467px;
            height: 322px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 467px;
              height: 322px;
            }
          }
        }
        .ant-carousel .slick-slide {
          text-align: center;
          height: 160px;
          line-height: 160px;
          background: #364d79;
          overflow: hidden;
        }

        .ant-carousel .slick-slide h3 {
          color: #fff;
        }
        /deep/ .ant-carousel .slick-dots-bottom {
          bottom: -12px !important;
        }
        /deep/ .ant-carousel .slick-dots li button {
          width: 9px;
          height: 9px;
          background: #b8d1ce;
          border-radius: 50%;
          opacity: 1;
        }
        /deep/ .ant-carousel .slick-dots .slick-active button {
          width: 29px;
          height: 9px;
          background: #0e756a;
          border-radius: 4px;
        }
      }
    }
  }
  .top {
    text-align: center;
    .header {
      height: 68px;
      line-height: 44px;
      a {
        text-decoration: none;
      }
      .logo {
        width: auto;
        height: 44px;
        vertical-align: top;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
      .subTitle {
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    .title {
      font-family: Arial, Arial;
      font-weight: bold;
      font-size: 32px;
      color: #0e756a;
      text-align: center;
    }
    .subTitle {
      margin-top: 26px;
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-align: center;
    }
    .auth {
      ul,
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 496px;
      }
      ul {
        width: 360px;
        margin: 0 auto;
      }
      li {
        cursor: pointer;
        margin-top: 23px;
        width: 360px;
        height: 50px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d9d9d9;
        line-height: 50px;
        text-align: center;
        span {
          display: inline-block;
          margin-left: 12px;
        }
      }
    }
    .forget {
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 12px;
      color: #0e756a;
      cursor: pointer;
      margin-top: 30px;
      text-align: center;
    }
    width: 100%;

    // background: #ffffff;
    // box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
    // border-radius: 21px 21px 21px 21px;
    // margin: 0 auto;
    padding: 30px 0;

    // @media screen and (max-width: 576px) {
    //   padding: 60px 0;
    // }
    // @media screen and (max-width: 320px) {
    //   .captcha-button {
    //     font-size: 14px;
    //   }
    // }
    .wrap-login {
      width: 360px;
      margin: 0 auto;
      margin-top: 42px;
    }
    .icon {
      font-size: 24px;
      color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
  }
  .noHave {
    font-family: Arial, Arial;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-top: 34px;
  }
  .forget,
  .reg {
    color: #0e756a;
    text-decoration: underline;
    text-align: center;
  }
}
.fail {
  .error {
    text-align: center;
    .loginError {
      width: 38px;
      height: 34px;
    }
  }
  .login-text {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 13px;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
  }
  .eror-code {
    font-weight: bold;
    font-size: 16px;
    color: #999999;
    text-align: center;
  }
  .retry {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-top: 65px;
    text-align: center;
  }
  .btn {
    width: 410px;
    height: 66px;
    line-height: 66px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 2px solid #0e756a;
    margin-top: 27px;
    text-align: center;
    cursor: pointer;
    .icon {
      margin-right: 10px;
    }
  }
  .relogin {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    line-height: 66px;
    text-align: center;
    background: #0e756a;
  }
  .concatus {
    font-weight: bold;
    font-size: 20px;
    color: #0e756a;
  }

  .wrap-code {
    width: 362px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .qrcode {
      width: 144px;
    }
    img {
      display: block;
      width: 122px;
      margin: 0 auto;
      margin-top: 56px;
    }
    .text {
      margin-top: 11px;
      text-align: center;
      width: 144px;
      font-weight: bold;
      font-size: 14px;
      color: #000000;
      width: 144px;
      height: 31px;
      line-height: 31px;
      background: #eff0f0;
    }
  }
  .qus {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    margin-top: 32px;
    text-align: center;
  }
}
@media screen and (max-width: 1680px) {
  .fail {
    position: relative;
    transform: scale(0.7);
  }
}
</style>
