import { hasAuthority } from "@/utils/authority-utils";
import { loginIgnore } from "@/router/index";
import { checkAuthorization } from "@/utils/request";
import NProgress from "nprogress";
import { $axios } from "@/utils/request";
import dayjs from "dayjs";
NProgress.configure({ showSpinner: false });

/**
 * 进度条开始
 * @param to
 * @param form
 * @param next
 */
const progressStart = (to, from, next) => {
  // start progress bar
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  next();
};

/**
 * 登录守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
const loginGuard = (to, from, next, options) => {
  const { message } = options;
  if (to.meta && to.meta.disabled) {
    next(false);
    return;
  }
  if (!loginIgnore.includes(to) && !checkAuthorization()) {
    const lang = localStorage.getItem("lang");
    let msg =
      lang == "CN"
        ? "登录已失效，请重新登录"
        : lang == "AR"
        ? "تسجيل الدخول غير صالح ، يرجى تسجيل الدخول مرة أخرى"
        : "Login has expired, please log in again";

    message.warning(msg);
    next({ path: "/login" });
  } else {
    next();
  }
};

/**
 * 权限守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */
const authorityGuard = async (to, from, next, options) => {
  const { store, message } = options;
  const permissions = store.getters["account/permissions"];
  const roles = store.getters["account/roles"];
  const user = store.getters["account/user"];
  let { end_date, bind_type, account } = (user && user.free_info) || {};
  let end = end_date ? new Date(end_date).getTime() : 0;
  const { sales_paid, hr_paid } = user || {};
  //都没有付费且过期了
  const AllNoPayExpried = end && !sales_paid && !hr_paid && Date.now() > end;
  //过期都没有绑定
  const exprseAndNotPay = end && !account && Date.now() > end;
  if (
    to.path.indexOf("/sales/salesReps") > -1 ||
    to.path.indexOf("/hr/recruiter") > -1
  ) {
    let payNoBind = { sales: 0, hr: 0, salesPayBind: 0, hrPayBind: 0 };
    let salesPayTipsDate = localStorage.getItem("salesPayTipsDate");
    let hrPayTipsDate = localStorage.getItem("hrPayTipsDate");
    let tips = "你刚完成支付后还没有进行账号绑定，请尽快绑定Linkedin账号";
    const lang = localStorage.getItem("lang");
    if (lang == "US") {
      tips =
        "You’ve just completed your payment but haven’t linked your account yet. Please link your LinkedIn account as soon as possible.";
    } else if (lang == "AR") {
      tips =
        "لقد أنهيت للتو عملية الدفع، ولكنك لم تقم بربط حسابك بعد. يُرجى ربط حسابك على لينكدإن في أقرب وقت ممكن.";
    }
    // 判断是否有没有支付没绑定过的订单
    let res = await $axios("/payment/order_list", {}, "post");
    if (res.code == 200 && res.data && res.data.length > 0) {
      res.data.forEach((item) => {
        if (
          (item.campaign_type == 1 || item.campaign_type == 3) &&
          !item.linkedin_email &&
          item.amount !== 0 &&
          Date.now() < new Date(item.expiration_date).getTime()
        ) {
          // 支付了未绑定的sales
          payNoBind.sales++;
        }
        if (
          (item.campaign_type == 1 || item.campaign_type == 3) &&
          item.linkedin_email &&
          item.amount !== 0 &&
          Date.now() < new Date(item.expiration_date).getTime()
        ) {
          //支付过并且绑定的sales
          payNoBind.salesPayBind++;
        }

        if (
          (item.campaign_type == 2 || item.campaign_type == 3) &&
          !item.linkedin_email &&
          item.amount !== 0 &&
          Date.now() < new Date(item.expiration_date).getTime()
        ) {
          //支付过并且绑定的hr

          payNoBind.hr++;
        }

        if (
          (item.campaign_type == 2 || item.campaign_type == 3) &&
          item.linkedin_email &&
          item.amount !== 0 &&
          Date.now() < new Date(item.expiration_date).getTime()
        ) {
          // 支付了且绑定的hr
          payNoBind.hrPayBind++;
        }
      });
    }
    // console.log('=======', payNoBind.sales, payNoBind.salesPayBind , payNoBind.hr, payNoBind.hrPayBind )
    if (
      (payNoBind.sales > 0 &&
        payNoBind.salesPayBind == 0 &&
        to.path.indexOf("/sales/salesReps") > -1) ||
      (payNoBind.hr > 0 &&
        payNoBind.hrPayBind == 0 &&
        to.path.indexOf("/hr/recruiter") > -1)
    ) {
      message.warning(tips);
      next({
        path: "/setting/order",
      });
      return;
    } else if (
      (salesPayTipsDate !== dayjs(Date.now()).format("YYYY-MM-DD") &&
        payNoBind.sales > 0 &&
        to.path.indexOf("/sales/salesReps") > -1) ||
      (hrPayTipsDate !== dayjs(Date.now()).format("YYYY-MM-DD") &&
        payNoBind.hr > 0 &&
        to.path.indexOf("/hr/recruiter") > -1)
    ) {
      if (payNoBind.sales > 0 && to.path.indexOf("/sales/salesReps") > -1) {
        localStorage.setItem(
          "salesPayTipsDate",
          dayjs(Date.now()).format("YYYY-MM-DD")
        );
      }
      if (payNoBind.hr > 0 && to.path.indexOf("/hr/recruiter") > -1) {
        localStorage.setItem(
          "hrPayTipsDate",
          dayjs(Date.now()).format("YYYY-MM-DD")
        );
      }
      message.warning(tips);
      next({
        path: "/setting/order",
      });
      return;
    }
  }

  //salse 绑定了过期了
  const salesBindedExpirse =
    end &&
    to.path.indexOf("/sales/salesReps") > -1 &&
    account &&
    Date.now() > end &&
    bind_type == 1;
  // hr 帮定了过期了
  const hrBindedExpirse =
    end &&
    to.path.indexOf("/hr/recruiter") > -1 &&
    account &&
    Date.now() > end &&
    bind_type == 2;
  // hr 绑定了，但没有过期，点击的是sales 且sales 没有付费过
  const skipSalesHrBindedNoExpirse =
    end &&
    to.path.indexOf("/sales/salesReps") > -1 &&
    account &&
    bind_type == 2 &&
    !sales_paid;
  // sales 绑定了 ，但没有过期，点击的是hr 且hr没有付费过
  const skipHrSlesBindedNoExpirse =
    end &&
    to.path.indexOf("/hr/recruiter") > -1 &&
    account &&
    bind_type == 1 &&
    !hr_paid;
  // 账号升级了，但是过期了hr_expiration:0 没过期 1过期了
  const sales_expiration = user && user.sales_expiration;
  const sales_expiration_path =
    sales_expiration == 1 &&
    to.path.indexOf("/sales/salesReps") > -1 &&
    (!user.free_info ||
      !(
        !(end && Date.now() > end) &&
        ((bind_type == 1 && sales_paid) || !account)
      ));
  const hr_expiration = user && user.hr_expiration;
  const hr_expiration_path =
    hr_expiration == 1 &&
    to.path.indexOf("/hr/recruiter") > -1 &&
    (!user.free_info ||
      !(
        !(end && Date.now() > end) &&
        ((bind_type == 2 && hr_paid) || !account)
      ));
  // console.log('hr_expiration_path----', hr_expiration_path,sales_expiration_path, AllNoPayExpried, exprseAndNotPay, salesBindedExpirse, hrBindedExpirse, skipSalesHrBindedNoExpirse, skipHrSlesBindedNoExpirse )

  // 判断是否是要阻止的页面
  const isStopPage =
    to.path.indexOf("/sales/salesReps") > -1 ||
    to.path.indexOf("/hr/recruiter") > -1;
  if (
    (to.path.indexOf("/sales/salesReps") > -1 && user.sales_expiration == 0) ||
    (to.path.indexOf("/hr/recruiter") > -1 && user.hr_expiration == 0)
  ) {
    next();
    return;
  }
  if (
    isStopPage &&
    (AllNoPayExpried ||
      exprseAndNotPay ||
      salesBindedExpirse ||
      hrBindedExpirse ||
      skipSalesHrBindedNoExpirse ||
      skipHrSlesBindedNoExpirse ||
      sales_expiration_path ||
      hr_expiration_path)
  ) {
    if (to.path == "/setting/userSetting/plan") {
      next();
    } else {
      next({
        path: "/setting/userSetting/plan",
        query: {
          expried:
            AllNoPayExpried ||
            exprseAndNotPay ||
            salesBindedExpirse ||
            hrBindedExpirse ||
            sales_expiration_path ||
            hr_expiration_path
              ? 1
              : 0,
          // 此处如果有hr 了再增加 || account && bind_type == 2 && to.path.indexOf("/hr/aihr") > -1
          upgrade_free:
            (account &&
              bind_type == 1 &&
              to.path.indexOf("/sales/salesReps") > -1) ||
            (bind_type == 2 && to.path.indexOf("/hr/recruiter") > -1)
              ? 1
              : 0,
          // 此处如果有hr 了再增加 ||    to.path.indexOf("/hr/aihr") > -1 ?2
          campaign_type:
            to.path.indexOf("/sales/salesReps") > -1
              ? 1
              : to.path.indexOf("/hr/recruiter") > -1
              ? 2
              : 0,
        },
      });
    }
  } else if (
    !hasAuthority(to, permissions, roles) ||
    (to.path.indexOf("/commissionList") > -1 && user.is_seed == 0)
  ) {
    message.warning(
      `Sorry, you do not have permission to access this page: ${to.fullPath}`
    );
    next({ path: "/403" });
    // NProgress.done()
  } else {
    next();
  }
};

/**
 * 混合导航模式下一级菜单跳转重定向
 * @param to
 * @param from
 * @param next
 * @param options
 * @returns {*}
 */
const redirectGuard = (to, from, next, options) => {
  const { store } = options;
  const getFirstChild = (routes) => {
    const route = routes[0];
    if (!route.children || route.children.length === 0) {
      return route;
    }
    return getFirstChild(route.children);
  };
  if (store.state.setting.layout === "mix") {
    const firstMenu = store.getters["setting/firstMenu"];
    if (firstMenu.find((item) => item.fullPath === to.fullPath)) {
      store.commit("setting/setActivatedFirst", to.fullPath);
      const subMenu = store.getters["setting/subMenu"];
      if (subMenu.length > 0) {
        const redirect = getFirstChild(subMenu);
        return next({ path: redirect.fullPath });
      }
    }
  }

  next();
};

/**
 * 进度条结束
 * @param to
 * @param form
 * @param options
 */
const progressDone = () => {
  // finish progress bar
  NProgress.done();
};
// 统计数据
let createMatomoScript = null;
const gooleStatistics = (to, from) => {
  if (to.path != "/login") {
    window.Tawk_API &&
      window.Tawk_API.showWidget &&
      window.Tawk_API.showWidget();
  }
  if (process.env.VUE_APP_PAY_MOD != "prod") {
    return;
  }
  var _paq = (window._paq = window._paq || []);
  _paq.push(["setCustomUrl", window.location.origin + to.fullPath]);
  if (from && from.fullPath) {
    _paq.push([
      "setReferrerUrl",
      window.location.origin + (from && from.fullPath),
    ]);
  }
  _paq.push(["trackPageView"]);
  // _paq.push(['enableLinkTracking']);
  // _paq.push(['alwaysUseSendBeacon']);
  _paq.push([
    "setTrackerUrl",
    "//www.strategybrain.ca/wp-content/plugins/matomo/app/matomo.php",
  ]);
  _paq.push(["setSiteId", "1"]);
  if (!createMatomoScript) {
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.async = true;
    g.src = "//www.strategybrain.ca/wp-content/uploads/matomo/matomo.js";
    s.parentNode.insertBefore(g, s);
    createMatomoScript = g;
  }
};

export default {
  beforeEach: [progressStart, loginGuard, authorityGuard, redirectGuard],
  afterEach: [progressDone, gooleStatistics],
};
