module.exports = {
  messages: {
    CN: {
      LogIn: "登录",
      SigninwithGoogle: "谷歌登录",
      SigninwithLinkedIn: "领英登录",
      EntertheFuture: "进入未来最智能的领英自动化工具",
      Dataisloading: "数据正在加载中",
      StartTrial: "开始免费试用",
      try10DaysFree: "无需注册，无需绑定信用卡，开始10天免费试用",
      loadingSpace: "正在加载你的工作空间",
      Afterlogging:
        "进入系统后，点击campaign下的创建按钮，创建你的第一个campaign",
      Whencreating:
        "创建第一个campaign时需要绑定您的LinkedIn账号，请注意：不要绑定全新账号，否则受到LinkedIn风控限制，无法执行任务",
      Youcancreate:
        " 一个LinkedIn下可以创建多个campaign，但是只能有一个在运行状态",
      Settingsearch:
        "搜索条件设置很关键，如果要从当前好友中聊，记得勾选上1ST，想要从全网找客户，则记得勾选2ND和3RD",
      Themore: "搜索条件设置的用户越精准，最终转化率会越高",
      Enteras:
        "请尽可能全面的录入公司，产品，案例，竞品等资料，资料越多，机器人沟通越精准，最后的转化率会越高",
      ForLinkedIn:
        "为了LinkedIn的账号安全，普通账号每日添加好友数量尽量不超过20个，navigator账号每日添加好友数量尽量不超过35个",
      Loginfailed: "登录失败",
      Errorcode: "错误代码",
      Youcantry: "你可以尝试以下方法",
      Logagain: "重新登录",
      Emailus: "联系我们",
      problem: "如果问题持续存在，请联系技术支持",
      authorization: "您已经取消了授权登录，请给予授权",
    },

    US: {
      LogIn: "Log In",
      SigninwithGoogle: "Sign in with Google",
      SigninwithLinkedIn: "Sign in with LinkedIn",
      EntertheFuture:
        "Please log in directly to test all features for free without needing to link a credit card.",
      Dataisloading: "Data is loading",
      StartTrial: "Start your free trial",
      try10DaysFree:
        "Try our platform for 10 days with no registration or credit card required",
      loadingSpace: "Loading your workspace",
      Afterlogging:
        "After logging in, click the “Create” button under “Campaign” to create your first campaign.",
      Whencreating:
        "When creating your first campaign, you need to link your LinkedIn account. Note: Do not link a brand-new account, as it may trigger LinkedIn’s risk control restrictions, preventing tasks from being executed.",
      Youcancreate:
        " You can create multiple campaigns under one LinkedIn account, but only one can be active at a time.",
      Settingsearch:
        "Setting search criteria is crucial. If you want to connect with current friends, check “1ST.” To find clients across the network, check “2ND” and “3RD.”",
      Themore:
        "The more precise the search criteria, the higher the final conversion rate.",
      Enteras:
        "Enter as much information as possible about your company, products, cases, and competitors. The more data you provide, the more accurate the bot’s communication will be, and the higher the conversion rate.",
      ForLinkedIn:
        "For LinkedIn account safety, limit daily friend requests to 20 for regular accounts and 35 for Navigator accounts.",
      Loginfailed: "Login failed",
      Errorcode: "Error code",
      Youcantry: "You can try the following methods:",
      Logagain: "Log in again",
      Emailus: "Contact us",
      problem: "If the problem persists, please contact technical support.",
      authorization:
        "You have canceled the authorization login. Please grant authorization.",
    },
    AR: {
      LogIn: "تسجيل الدخول",
      SigninwithGoogle: "تسجيل الدخول بواسطة جوجل",
      SigninwithLinkedIn: "تسجيل الدخول بواسطة لينكد إن",
      EntertheFuture: "ادخل إلى مستقبل أتمتة لينكد إن الذكية",
      Dataisloading: "يتم تحميل البيانات",
      StartTrial: "ابدأ التجربة المجانية",
      try10DaysFree:
        "بدون تسجيل، وبدون ربط بطاقة ائتمان، ابدأ تجربتك المجانية لمدة 10 أيام",
      loadingSpace: "جاري تحميل مساحة العمل الخاصة بك",
      Afterlogging:
        "بعد تسجيل الدخول، انقر على زر “إنشاء” تحت قسم الحملات لإنشاء أول حملة خاصة بك",
      Whencreating:
        "عند إنشاء الحملة الأولى، يجب ربط حساب LinkedIn الخاص بك. ملاحظة: لا تقم بربط حساب جديد تمامًا لتجنب قيود LinkedIn التي قد تمنع تنفيذ المهام",
      Youcancreate:
        " يمكن إنشاء عدة حملات ضمن حساب LinkedIn واحد، ولكن يمكن تشغيل حملة واحدة فقط في نفس الوقت",
      Settingsearch: `إعداد شروط البحث أمر مهم جدًا: إذا كنت ترغب في التحدث مع الأصدقاء الحاليين، تأكد من اختيار “1ST”، وإذا كنت تريد البحث عن العملاء على مستوى الشبكة، اختر “2ND” و”3RD”`,
      Themore: "كلما كانت شروط البحث أكثر دقة، زادت نسبة التحويل النهائية",
      Enteras:
        "أدخل أكبر قدر ممكن من المعلومات عن الشركة، المنتج، الحالات، والمنافسين؛ فكلما كانت البيانات أكثر شمولاً، أصبح التواصل الآلي أكثر دقة وزادت نسبة التحويل النهائية",
      ForLinkedIn:
        "أدخل أكبر قدر ممكن من المعلومات عن الشركة، المنتج، الحالات، والمنافسين؛ فكلما كانت البيانات أكثر شمولاً، أصبح التواصل الآلي أكثر دقة وزادت نسبة التحويل النهائية",
      Loginfailed: "فشل تسجيل الدخول",
      Errorcode: "رمز الخطأ",
      Youcantry: "يمكنك تجربة التالي",
      Logagain: "تسجيل الدخول مرة أخرى",
      Emailus: "تواصل معنا",
      problem: "إذا استمرت المشكلة، يرجى التواصل مع الدعم الفني",
      authorization: "لقد ألغيت تسجيل الدخول بالتفويض. الرجاء منح التفويض.",
    },
  },
};
