module.exports = {
  messages: {
    CN: {
      home: { name: "首页" },
      sales: {
        name: "销售",
        salesReps: {
          name: "AI销售代表",
        },
        marketingExperts: {
          name: "AI市场专家",
        },
        order: {
          name: "订单信息",
        },
      },
      hr: {
        name: "人力资源",
        hrExpert: {
          name: "AI人力专家",
        },
        recruiter: {
          name: "AI招聘",
        },
      },
      setting: {
        name: "设置",
        userSetting: {
          name: "用户设置",
        },
        order: {
          name: "订单信息",
        },
      },
      commissionList: {
        name: "返佣列表",
      },
      exception: {
        name: "異常頁",
        404: { name: "404" },
        403: { name: "403" },
        500: { name: "500" },
      },
    },
    US: {
      home: { name: "home" },
      sales: {
        name: "Sales",
        salesReps: {
          name: "AI Sales Rep",
        },
        marketingExperts: {
          name: "AI Marketing",
        },
      },
      hr: {
        name: "Human Resources",
        hrExpert: {
          name: "AI HR Expert",
        },
        recruiter: {
          name: "AI Recruiter",
        },
      },
      setting: {
        name: "Settings",
        userSetting: {
          name: "User Settings",
        },
        order: {
          name: "Order Details",
        },
      },
      commissionList: {
        name: "Commission List",
      },
    },
    AR: {
      home: { name: "الصفحة الرئيسية" },
      sales: {
        name: "المبيعات",
        salesReps: {
          name: "ممثل المبيعات الذكي",
        },
        marketingExperts: {
          name: "الخبير التسويقي الذكي",
        },
      },
      hr: {
        name: "الموارد البشرية",
        hrExpert: {
          name: "الخبير الذكي في الموارد البشرية",
        },
        recruiter: {
          name: "مجند الذكاء الاصطناعي",
        },
      },
      setting: {
        name: "الإعدادات",
        userSetting: {
          name: "إعدادات المستخدم",
        },
        order: {
          name: "معلومات الطلب",
        },
      },
      commissionList: {
        name: "قائمة العمولات",
      },
    },
  },
};
