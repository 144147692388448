var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showMenu
        ? [
            _c("ul", { staticClass: "nav" }, [
              _c(
                "li",
                {
                  class: {
                    selected:
                      _vm.currentPath == "/hr/recruiter/home" ? true : false,
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      class: [_vm.lang],
                      attrs: { to: "/hr/recruiter/home?type=tab" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("home")))]
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  class: {
                    selected:
                      _vm.currentPath == "/hr/recruiter/campaign"
                        ? true
                        : false,
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      class: [_vm.lang],
                      attrs: { to: "/hr/recruiter/campaign" },
                    },
                    [_vm._v(_vm._s(_vm.$t("campaigns")))]
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  class: {
                    selected:
                      _vm.currentPath == "/hr/recruiter/dashboard"
                        ? true
                        : false,
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      class: [_vm.lang],
                      attrs: { to: "/hr/recruiter/dashboard" },
                    },
                    [_vm._v(_vm._s(_vm.$t("dashboard")))]
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  class: {
                    selected:
                      _vm.currentPath == "/hr/recruiter/linkin" ? true : false,
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      class: [_vm.lang],
                      attrs: { to: "/hr/recruiter/linkin" },
                    },
                    [_vm._v(_vm._s(_vm.$t("linkedinAccountSetting")))]
                  ),
                ],
                1
              ),
            ]),
          ]
        : [
            _c("p", { staticClass: "back" }, [
              _c(
                "span",
                { on: { click: _vm.goback } },
                [
                  _c("a-icon", { attrs: { type: "left" } }),
                  _c("span", { class: [_vm.lang] }, [
                    _vm._v(_vm._s(_vm.$t("back"))),
                  ]),
                ],
                1
              ),
            ]),
          ],
      _c(
        "keep-alive",
        [
          _vm.$route.meta.KeepAlive
            ? _c("router-view", {
                key: _vm.$route.path,
                attrs: {
                  exclude: ["createCampaign", "/hr/recruiter/createCampaign"],
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.$route.meta.KeepAlive ? _c("router-view") : _vm._e(),
      false
        ? _c(
            "div",
            { staticClass: "mask_bg" },
            [
              _c("a-icon", {
                staticStyle: {
                  color: "#fff",
                  position: "fixed",
                  right: "20px",
                  top: "20px",
                  cursor: "pointer",
                  "font-weight": "bold",
                  "font-size": "18px",
                },
                attrs: { type: "close" },
                on: { click: _vm.closeGuide },
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v("How to set up your first AI Sales Rep?"),
              ]),
              _c(
                "div",
                { staticClass: "caption", staticStyle: { cursor: "inherit" } },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("campaigns")) + " "),
                  ]),
                  _c("div", { staticClass: "des" }, [
                    _vm._v(
                      '1.First, click "Campaigns" to open the AI Sales task list.'
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "btn" }, [
                _c(
                  "span",
                  {
                    staticClass: "guideBtn",
                    class: [_vm.lang],
                    staticStyle: { cursor: "inherit" },
                    attrs: { type: "primary", size: "large" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Create")) + " ")]
                ),
                _c("span", { staticClass: "des" }, [
                  _vm._v('2.Next, click "Create" to set up your AI Sales Rep.'),
                ]),
              ]),
              _c("div", { staticClass: "guide-con" }, [
                _c("div", [_vm._v("While setting up your AI Sales Rep:")]),
                _c("div", [_vm._v("A. Add a LinkedIn account for AI to use.")]),
                _c("div", [
                  _vm._v(
                    "B. Provide product and company info for lead generation."
                  ),
                ]),
                _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                  _vm._v("C. Add LinkedIn search links for potential clients."),
                ]),
                _c("div", [
                  _vm._v("Complete these steps to start AI-powered sales."),
                ]),
                _c(
                  "div",
                  { staticClass: "con-click", on: { click: _vm.goPage } },
                  [_vm._v("Click to watch the demo video.")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }